import React from 'react';
import { Form, Field } from 'react-final-form'
import { PasswordInput } from "../../components/form"
import PropTypes from "prop-types"
import { required, composeValidators ,confirmPasswordVailid} from "../../components/form/Validations"
import {photos} from "../../constants/images"

function UpdatePasswordForm(props) {
    const { handleFormSubmit, setIsValid, disable, initialValue, error } = props


    return (
        <div className="add-form box">
            <Form
                onSubmit={handleFormSubmit}
                initialValues={initialValue}
            >
                {({ handleSubmit, valid, submitError, submitting, pristine }) => {
                    setIsValid(submitting || pristine)
                    return (
                        <form id="updatePasswordForm" onSubmit={handleSubmit}  >
                            <div className="row" >
                                <div className="col-md-6 col-lg-6 col-sm-12">
                                <div className="image animate__animated animate__fadeInUp animate__faster" >
                            <img src={photos.updatePassword} />
                            </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-sm-12 animate__animated animate__fadeInDown animate__faster">
                                    <div className="input-field" >
                                        <label>Current Password</label>
                                        <Field
                                            placeholder="Enter your Current Password"
                                            disable={disable}
                                            validate={composeValidators(required)} name="old_password" component={PasswordInput} />
                                    </div>
                                    <div className="input-field" >
                                        <label>New Password</label>
                                        <Field
                                            placeholder="Enter your New Password"
                                            disable={disable}
                                            validate={confirmPasswordVailid} name="password" component={PasswordInput} />
                                    </div>
                                    <div className="input-field" >
                                        <label>Confirm Password</label>
                                        <Field
                                            placeholder="Enter your New Password again"
                                            disable={disable}
                                            validate={confirmPasswordVailid} 
                                            name="confirmPassword" 
                                            component={PasswordInput} />
                                    </div>
                                </div>
                            </div>

                            {submitError && <p className="error-msg">{submitError}</p>}
                        </form>
                    )
                }}
            </Form>
        </div>
    );
}





UpdatePasswordForm.propTypes = {

    title: PropTypes.string,
    handleFormSubmit: PropTypes.func,
    initialValue: PropTypes.object,
    setIsValid: PropTypes.func,
    error: PropTypes.string
}


export default UpdatePasswordForm;
