import React from 'react';
import {Form,Field} from 'react-final-form'
import {Input,Dropdown} from "../../components/form"
import PropTypes from "prop-types"
import {HelpBlock,Button} from 'rsuite'
import {required,composeValidators} from "../../components/form/Validations"
import history from "../../helpers/history"
import {IoAddSharp} from 'react-icons/all'

function TopicForm(props){
    const {handleFormSubmit,setIsValid,disable,initialValue,error,subjectOptions}=props


    return (
        <div className="add-form box">
            <Form 
            onSubmit={handleFormSubmit}
            initialValues={initialValue}
            >
                {({handleSubmit,valid,submitError,submitting,pristine})=>{
                    console.log("Valid: ",valid)
                    setIsValid(submitting || pristine)
                    return(
                    <form id="addTopicForm"   onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className="col-6 col-md-6 col-lg-6">
                            <div className="input-field" >
                    <label>Subject</label>
                    <Field 
                    placeholder="Select Subject" 
                    options={subjectOptions||[]} 
                    labelKey='name'
                    valueKey='id'
                    cleanable={false}
                    name="subject" 
                    component={Dropdown}
                    validate={required}
                    />
                    <div className="help-block" >
                        <HelpBlock>Subject not available?</HelpBlock>
                        <Button appearance="subtle" 
                        onClick={()=>history.push({pathname:'/subject/add-subject',state:{location:'/topic/add-topic'}})}
                         size="sm">
                            <IoAddSharp size={22} /> Create Subject</Button>
                    </div>
                    </div>
                            </div>
                            <div className="col-6 col-md-6 col-lg-6">
                            <div className="input-field" >
                    <label>Topic Name</label>
                    <Field placeholder="Enter Topic Name" 
                    disable={disable}
                    validate={composeValidators(required)} name="name" component={Input} />
                    </div>
                            </div>
                        </div>
                   
                  
                   
                    {submitError&&<p className="error-msg">{submitError}</p>}
                </form>
                )}}
            </Form>
        </div>
    );
}





TopicForm.propTypes={
    
    title:PropTypes.string,
    handleFormSubmit:PropTypes.func,
    initialValue:PropTypes.object,
    setIsValid:PropTypes.func,
    error:PropTypes.string,
    subjectOptions:PropTypes.array
}


export default TopicForm;
