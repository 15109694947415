import React from 'react'
import {Profile} from '../../components'
import PropTypes from "prop-types"


export default function _Profile({name,phone,country,board,grade,amount,paymentMode,image}) {
    return (
       <Profile
        name={name}
        phone={phone}
        country={country}
        board={board}
        grade={grade}
        amount={amount}
        paymentMode={paymentMode}
        image={image}
       />
    )
}


_Profile.propTypes={
    name: PropTypes.string,
    phone: PropTypes.string,
    country: PropTypes.string,
    board: PropTypes.string,
    grade: PropTypes.string,
    amount: PropTypes.string,
    paymentMode: PropTypes.string,
    image:PropTypes.string
}