//@author:Himanshu Barmola
//Project: OpenDoor Sheets Admin Panel


// Customizing the Input field for Redux-Form

import React,{useState,useEffect} from "react"
import { ChromePicker } from "react-color"
import reactCSS from 'reactcss'
import {HelpBlock,InputGroup,Input} from 'rsuite'
export const ColorPicker = (props) => {
    const [displayColorPicker,setDisplayColorPicker]=useState(false);
  
    const styles = reactCSS({
        'default': {
          color: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background:`${props.input.value?.hex||props.input.value}`,
            // background: `rgba(${props.input.value?.rgb?.r||color.r }, ${props.input.value?.rgb?.g||color.g }, ${props.input.value?.rgb?.b||color.b }, ${props.input.value?.rgb?.a||color.a })`,
          },
          swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0px 2px 8px 0px rgba(99, 99, 99, 0.2) ',
            display: 'inline-block',
            cursor: 'pointer',
          },
          popover: {
            position: 'absolute',
            zIndex: '99',
            top:'80px',
          },
          cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          },
        },
      });


     function handleClick(){
         setDisplayColorPicker((prev)=>!prev)
     } 

     function handleClose(){
         setDisplayColorPicker(false)
     }

    return (
        <div className="input-field">
            <InputGroup inside className="rs-color-picker" >
               <InputGroup.Addon>
                <div style={ styles.swatch } onClick={handleClick}>
                <div style={ styles.color } />
                </div>
               </InputGroup.Addon> 
               <Input
               readOnly
               name={props.input.name}
               value={props.input.value?.hex||props.input.value}   
               className={props.meta.touched && (props.meta.error|| props.meta.submitError) != undefined ? `error-field ${props.class}` : props.class}
               />
            </InputGroup>
            {displayColorPicker&&
            <div style={styles.popover}>
                <div style={styles.cover} onClick={handleClose} />
                <ChromePicker
                {...props.input}
                // value={props.input.value}
                color={props.input.value} 
                name={props.input.name}
                disableAlpha
                 />
                
            </div>
            }
            {props.helpBlock&&<HelpBlock>{props.helpBlock}</HelpBlock>}
            {props.meta.touched&&(props.meta.error|| props.meta.submitError)&&<p className="error-msg" >{(props.meta.error|| props.meta.submitError)}</p>}
        </div>
    )
}



export default ColorPicker