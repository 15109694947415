import React from 'react';
import { Form, Field } from 'react-final-form'
import {InputIcon,Input,Dropdown,Radio } from "../../components/form"
import PropTypes from "prop-types"
import { required, composeValidators,email} from "../../components/form/Validations"
import {photos} from "../../constants/images"
import {BiUser,HiOutlineMail,BiMobileAlt} from 'react-icons/all'
import { useQuery } from 'react-query'
import { apiRequest,getErrors } from '../../helpers'
import * as API from "../../api/API"

function CreateAdminForm(props) {
    const { handleFormSubmit, setIsValid, disable, initialValue, error } = props
    const { data: countryList, isLoading: isCountryLoading } = useQuery(['fetchCountryList'], () => apiRequest('GET', API.COUNTRY, null, null),{
        refetchIntervalInBackground:false,
        refetchOnReconnect:false,
        refetchOnMount:false
    })

    return (
        <div className="add-form box">
            <Form
                onSubmit={handleFormSubmit}
                initialValues={initialValue}
            >
                {({ handleSubmit, valid, submitError, submitting, pristine }) => {
                    setIsValid(submitting || pristine)
                    return (
                        <form id="createAdminForm" onSubmit={handleSubmit}  >
                            <div className="row" >
                                <div className="col-md-5 col-lg-5 col-sm-12">
                                <div className="image animate__animated animate__fadeInUp animate__faster" >
                            <img src={photos.createAdmin} />
                            </div>
                                </div>
                                <div className="col-md-7 col-lg-7 col-sm-12 animate__animated animate__fadeInDown animate__faster">
                                    <div className="row" >
                                    <div className="col-md-6 col-lg-6 col-sm-12" >
                                    <div className="input-field" >
                                        <label>First Name</label>
                                        <Field
                                            placeholder="Enter your First Name"
                                            icon={BiUser}
                                            disable={disable}
                                            validate={composeValidators(required)} name="first_name" component={InputIcon} />
                                    </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-12" >
                                    <div className="input-field" >
                                        <label>Last Name</label>
                                        <Field
                                            placeholder="Enter your Last Name"
                                            icon={BiUser}
                                            disable={disable}
                                            validate={composeValidators(required)} name="last_name" component={InputIcon} />
                                    </div>
                                    </div>
                                    </div>

                                  
                                    <div className="row" >
                                    <div className="col-md-6 col-lg-6 col-sm-12" >
                                    <div className="input-field" >
                                        <label>Email</label>
                                        <Field
                                            placeholder="Enter your Email"
                                            icon={HiOutlineMail}
                                            disable={disable}
                                            validate={composeValidators(required,email)} name="email_id" component={InputIcon} />
                                    </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-12" >
                                    <div className="input-field" >
                                        <label>Mobile Number</label>
                                        <Field
                                            placeholder="Enter your Mobile Number"
                                            icon={BiMobileAlt}
                                            disable={disable}
                                            validate={composeValidators(required)} name="mobile_no" component={InputIcon} />
                                    </div>
                                    </div>
                                    </div>


                                    <div className="row" >
                                    <div className="col-md-6 col-lg-6 col-sm-12" >
                                    <div className="input-field" >
                                        <label>Country</label>
                                        <Field
                                            placeholder="Select your Country"
                                            icon={HiOutlineMail}
                                            disable={disable}
                                            valueKey="id"
                                            labelKey="name"
                                            options={countryList||[]}
                                            validate={composeValidators(required)} name="country" component={Dropdown} />
                                    </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-12" >
                                    <div className="input-field" >
                                        <label>Is Super Admin</label>
                                        <Field
                                            placeholder="Enter your Mobile Number"
                                            radioData={[
                                                {label:"Yes",value:true},
                                                {label:"No",value:false},
                                            ]}
                                            defaultValue={false}
                                            icon={BiMobileAlt}
                                            disable={disable}
                                            validate={composeValidators(required)} name="is_superadmin" component={Radio} />
                                    </div>
                                    </div>
                                    </div>
                                </div>
                            </div>

                            {submitError && <p className="error-msg">{submitError}</p>}
                        </form>
                    )
                }}
            </Form>
        </div>
    );
}





CreateAdminForm.propTypes = {

    title: PropTypes.string,
    handleFormSubmit: PropTypes.func,
    initialValue: PropTypes.object,
    setIsValid: PropTypes.func,
    error: PropTypes.string
}


export default CreateAdminForm;
