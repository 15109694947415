import React, { Fragment } from 'react'
import Sidebar from "./Sidebar/_Sidebar"
import {Header} from "../../components/"
export default function AuthLayout({ children }) {
    return (
        <Fragment>
            <Header />
            <Sidebar component={children} />
        </Fragment>
    )
}
