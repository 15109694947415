import React,{useCallback,Fragment,useState} from 'react'
import PropTypes from 'prop-types'
import {Table} from "../../container";
import {COL} from "../../columns/EvaluatedWorksheetsList"
import history from "../../helpers/history"
import * as API from "../../api/API"
import {useQuery} from 'react-query'
import {apiRequest} from "../../helpers"
import {updateTeachersWorksheet} from "../../redux/creators/teachers.creator"
import {connect} from 'react-redux'

import {Badge} from 'rsuite'

function _EvaluatedWorksheets({data,updateTeachersWorksheet,userSelectedRow,setUserSelectedRow,shareResults}) {
    const [tableData,setTableData]=useState(null)

    const {data:moduleData,isLoading}=useQuery(['fetchEvaluatedWorksheets'],()=>apiRequest('GET',`${API.STUDENT_MANAGEMENT}${data.id}/`,null,null),{
           refetchOnReconnect:false,
           refetchOnWindowFocus:false,
           onSuccess:(res)=>{
            let tempData=[];
             if(res.msg.evaluated_worksheets.length>0){
                 for(let key in res.msg.evaluated_worksheets){
                     let data={
                        ...res.msg.evaluated_worksheets[key],
                        isEvaluated:true
                     }
                     tempData.push(data)
                 }
                 updateTeachersWorksheet(tempData)  
                 setTableData(tempData)

             }
        }
    })

    const showExpandedRow=useCallback((value)=>{
        console.log("Expanded Value: ",value)
        return(
            <Fragment>
            {/* <div className="permission-subRow" > */}
               <td className="permission-subRow__box" >
                 <h4 className={`animate__animated animate__fadeInUp animate__faster`} >Boards</h4>
                <ul>
                    {value.boards.map((item,idx)=>{
                        return(
                            <li className={`animate__animated animate__fadeInUp animate__delay-${idx*800}ms`}  key={idx}><Badge content={item} style={{backgroundColor:"#e9ecef",color:"#8898aa"}} /> </li>
                        )
                    })}
                </ul>
               </td>
               <td className="permission-subRow__box" >
                 <h4 className={`animate__animated animate__fadeInUp animate__faster`} >Grades</h4>
                <ul>
                    {value.grades.map((item,idx)=>{
                        return(
                            <li className={`animate__animated animate__fadeInUp animate__delay-${idx*800}ms`} key={idx}><Badge content={item} style={{backgroundColor:"#e9ecef",color:"#8898aa"}} /> </li>
                        )
                    })}
                </ul>
               </td>
               <td className="permission-subRow__box" >
                 <h4 className={`animate__animated animate__fadeInUp animate__faster`} >Subjects</h4>
                <ul>
                    {value.subjects.map((item,idx)=>{
                        return(
                            <li className={`animate__animated animate__fadeInUp animate__delay-${idx*800}ms`} key={idx}><Badge content={item} style={{backgroundColor:"#e9ecef",color:"#8898aa"}} /> </li>
                        )
                    })}
                </ul>
               </td>
            {/* </div> */}
            </Fragment>
        )
    },[])

    return (
        <div>
            {tableData &&<Table
            columns={COL}
            columnOrder={['selection','worksheet_name','boards','subjects','grades','submitted_date','open']}
            explicitData={tableData}
            loading={isLoading}
            hideActionButton
            renderRowSubComponent={showExpandedRow}
            userSelectedRows={(item)=>setUserSelectedRow(item)}
            canRowSelect
            />}
        </div>
    )
}




_EvaluatedWorksheets.propTypes={
    data:PropTypes.object
}

const mapDispatchToProps=dispatch=>{
    return{
        updateTeachersWorksheet:(data)=>dispatch(updateTeachersWorksheet(data))
    }
}

export default connect(null,mapDispatchToProps)(_EvaluatedWorksheets)