import React, { useEffect, useState, Fragment } from 'react'
import { Sidenav, Nav, Dropdown } from "rsuite"
import { NestedMenu } from "../../../data/MenuItem"
import { TeacherMenu } from "../../../data/TeacherMenu"
import history from "../../../helpers/history"
import classname from 'classnames'
import {connect} from "react-redux"

function MenuItem({USER}) {
    const [path, setPath] = useState(null)
    const [activeIndex,setActiveIndex]=useState('')
    const {pathname}=history.location
    useEffect(() => {
        setPath(history.location.pathname)
    }, [history.location.pathname])

    useEffect(()=>{
        if(history.location.pathname){
            if(USER&&USER.USER_TYPE==="SuperAdmin"){
                for(let i in NestedMenu){
                    if(NestedMenu[i].menu.length>0){
                        for(let j in NestedMenu[i].menu){
                            if(NestedMenu[i].menu[j].path === pathname){
                                setActiveIndex(NestedMenu[i].menu[j].id)
                            }
                        }
                    }
                    if(NestedMenu[i].path === pathname){
                        setActiveIndex(NestedMenu[i].id)
                    }
                }
            }else if(USER&&USER.USER_TYPE==="Teacher"){
                for(let i in TeacherMenu){
                    if(TeacherMenu[i].menu.length>0){
                        for(let j in TeacherMenu[i].menu){
                            if(TeacherMenu[i].menu[j].path === pathname){
                                setActiveIndex(TeacherMenu[i].menu[j].id)
                            }
                        }
                    }
                    if(TeacherMenu[i].path === pathname){
                        setActiveIndex(TeacherMenu[i].id)
                    }
                }
            }
          
        }
    },[])

    function handleNavigation(item){
        if(item.path){
            setActiveIndex(item.id)
            setPath(item.path)
            history.push(item.path)
        }
    }



    return (
        <Fragment>
            <div className="animate__animated menu-item-box" style={{ marginTop: 15, height: "100%" }} >
                <Sidenav 
                appearance="subtle" defaultOpenKeys={['1', '2']} >
                    <Sidenav.Body>
                        {USER&&USER.USER_TYPE==="SuperAdmin"?
                        <Nav>
                            {NestedMenu.map((item, idx) => {
                                if(item.menu.length>0){
                                    return (
                                        <Dropdown key={idx} eventKey={item.id} className={item.id == activeIndex?'active-path':''} icon={item.icon} title={' '+item.title} onClick={() =>handleNavigation(item)}>
                                            {item.menu.length > 0 && item.menu.map((menuItem, midx) => {
                                                return (
                                                    <Dropdown.Item key={midx} eventKey={midx} className={menuItem.id == activeIndex?'active-path':''}  icon={menuItem.icon} onClick={() => handleNavigation(menuItem)}>
                                                        {" " +menuItem.title}
                                                    </Dropdown.Item>
    
                                                )
                                            })}
    
                                        </Dropdown>
                                    )
                                }else{
                                    return(
                                        <Nav.Item key={idx} className={item.id == activeIndex?'active-path':''} eventKey={item.id} icon={item.icon} onClick={() =>handleNavigation(item)}>
                                        {" "+item.title}
                                        </Nav.Item>
                                    )  
                                }             
                                      

                            })}
                        </Nav>:
                        USER.USER_TYPE==="Teacher"?
                        <Nav>
                        {TeacherMenu.map((item, idx) => {
                            if(item.menu.length>0){
                                return (
                                    <Dropdown key={idx} eventKey={item.id} className={item.id == activeIndex?'active-path':''} title={' '+item.title} onClick={() =>handleNavigation(item)}>
                                        {item.menu.length > 0 && item.menu.map((menuItem, midx) => {
                                            return (
                                                <Dropdown.Item key={midx} eventKey={midx} className={menuItem.id == activeIndex?'active-path':''}  icon={menuItem.icon} onClick={() => handleNavigation(menuItem)}>
                                                    {" " +menuItem.title}
                                                </Dropdown.Item>

                                            )
                                        })}

                                    </Dropdown>
                                )
                            }else{
                                return(
                                    <Nav.Item key={idx} className={item.id == activeIndex?'active-path':''} eventKey={item.id} icon={item.icon} onClick={() => history.push(item.path)}>
                                    {" "+item.title}
                                    </Nav.Item>
                                )  
                            }             
                                  

                        })}
                    </Nav>:<div/>}
                    </Sidenav.Body>
                </Sidenav>
            </div>
        </Fragment>
    )
}


const mapStateToProps=state=>{
    return{
        USER:state.AuthReducer.auth
    }
}


export default connect(mapStateToProps,null)(MenuItem)