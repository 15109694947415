
import moment from 'moment'
import {Button,Badge} from 'rsuite'
import {history} from "../helpers"
import {theme} from "../constants"
import {VscFilePdf} from "react-icons/all"
import * as API from "../api/API"
export const COL=[
    {
        Header:'Name',
        Footer:'Name',
        accessor:'student_name',
        Filter:'',

    },
    {
        Header:'Grade',
        Footer:'Grade',
        accessor:'grade',
        Filter:'',

    },
    {
        Header:'Board',
        Footer:'Board',
        accessor:'board',
        Filter:'',

    },
    {
        Header:'Evaluated By',
        Footer:'Evaluated By',
        accessor:'evaluated_by',
        Filter:'',

    },
    {
        Header:'Submitted Date',
        Footer:'Submitted Date',
        accessor:'submitted_date',
        Filter:'',
        Cell:({value})=>(
            <p>{value?moment(value).format('ll'):'--'}</p>
        )
    },
    {
        Header:'Evaluated PDF',
        Footer:'Evaluated PDF',
        accessor:'evaluated_pdf',
        Filter:'',
        Cell:({value})=>(
            <a href={value} target="_blank" >
               <VscFilePdf style={{color:theme.THEME_PRIMARY_COLOR}} size={24} />
            </a>
        )
    },
    {
        Header:'Evaluated Date',
        Footer:'Evaluted Date',
        accessor:'evaluated_date',
        Filter:'',
        Cell:({value})=>(
            <p>{value?moment(value).format('ll'):'--'}</p>
        )
    },
    {
        Header:'Status',
        Footer:'Status',
        accessor:'status_shared',
        Filter:'',
        Cell:({value})=>(
            <Badge content={!value?"Pending":"Shared"} style={{backgroundColor:!value?theme.INACTIVE_COLOR:theme.SUCCESS_COLOR}}  />
        )
    },


]