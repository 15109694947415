const THEME_PRIMARY_COLOR='#8364FF';
const THEME_SEONDARY_COLOR='#FFCF03';
const SUCCESS_COLOR="#3DA954";
const INACTIVE_COLOR="#8898aa";
const THEME_PENDING_COLOR="#F8B239"


export default {
    THEME_PRIMARY_COLOR,
    THEME_SEONDARY_COLOR,
    SUCCESS_COLOR,
    INACTIVE_COLOR,
    THEME_PENDING_COLOR
}