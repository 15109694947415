
import React from 'react'
import {SelectPicker} from "rsuite"
import * as _ from 'lodash';


function GlobalColoumnFilter({filterValue,setFilter,preFilteredRows,id,property,labelKey,valueKey,query}){
    const options = React.useMemo(()=>{
        return preFilteredRows
    },[preFilteredRows])

    const handleFilterParam=(param)=>{
        let prevData=filterValue;
        prevData={
            ...prevData,
            [query]:param[valueKey]
        }
        setFilter(prevData)
        console.log("Filter param: ",param)
        console.log("Prama data:",prevData)
    }

    const handleCleanFilter=(property)=>{
        let prevData=filterValue;
        prevData={
            [property]:""
        }
        console.log("After Deletion: ",prevData)
        setFilter(prevData)
    }

    return(
        <SelectPicker
        id={property}
        data={options||[]}
        placeholder={`Filter from ${labelKey}`}
        block
        labelKey={property}
        valueKey={valueKey}
        searchable={false}
        onSelect={(v,b)=>handleFilterParam(b)}
        onClean={()=>handleCleanFilter(property)}
        />
    )
}


export default GlobalColoumnFilter