
import * as trigger from "../creators/auth.creator"
import * as actionTypes from "../actionTypes"
import {history} from '../../helpers'


export const logoutUser=()=>(dispatch)=>{
    console.log("Logout user")
    return new Promise((resolve,reject)=>{
        dispatch(trigger.logoutUser())
        localStorage.clear()
        history.push('/login')
        resolve()
    })
}