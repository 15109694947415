import React, { useRef, useEffect, useState } from 'react'
import WebViewer from '@pdftron/pdfjs-express'
import PropTypes from 'prop-types'
import { history, apiRequest ,getErrors} from '../../helpers'
import * as API from '../../api/API'
import { photos } from '../../constants/images'
import { Dialog,Animation,CompanianHeader} from '../../components'
import {CommentsForm} from "../../container"
import { useQuery, useMutation } from 'react-query'
import animate from '../../data/success2.json'
import handLoading from '../../data/handLoading.json'
import * as _ from "lodash"
import * as action from "../../redux/creators/teachers.creator"
import {connect} from "react-redux"
import {IconButton,Icon,Button,Alert} from 'rsuite'
import Skeleton from 'react-loading-skeleton';
import ExpressUtils from '@pdftron/pdfjs-express-utils'
import axios from 'axios'
import { toast } from "react-toastify";


function PdfEditor({ worksheet,currentIndex,_nextWorksheet,_previousWorksheet,setCurrentWorksheetIndex,setCurrentWorksheet,currentWorksheet,auth,
  // setWorksheetId,setEvaluationID,evaluationId,worksheetId
 }) {
 

  const viewer = useRef(null)
  const [isOpen, setOpen] = useState(false)
  const [updatedFile, setUpdatedFile] = useState(null)
  const [documentLoading,setDocumentLoading]=useState(false)
  const [isGeneratingPdf,setIsGeneratingPdf]=useState(false)
  const [worksheetId, setWorksheetId] = useState(null)
  const [evaluationId,setEvaluationID]=useState(null)
  const [evaluatedWorksheet, setEvaluatedWorksheet] = useState(null)
  const [reEvaluatedWorksheet,setReEvaluatedWorksheet]=useState(null)
  const [initialValue, setInitialValue] = useState(null)
  const [sectionValues,setSectionValues]=useState(null)
  // const [isAssigningMarksSuccess,setIsAssignedMarksSuccess]=useState(false)
  const [evaluationStatus,setEvaluationStatus]=useState('idle')
  const { state } = history.location;
  // const [currentWorksheet,setCurrentWorksheet]=useState(null)
  const [instance, setInstance] = useState(null)

  const { data: worksheetData, isLoading: isWorksheeetLoading,isStale } = useQuery(['fetchWorksheetRevisionById',currentIndex], () => apiRequest('GET', `${API.WORKSHEET_REVISION}`, null, { revision_id: worksheetId }), {
    enabled:!!worksheetId,
    refetchIntervalInBackground:false,
    refetchOnMount:false,
    refetchOnWindowFocus:false,
    cacheTime:200,
    onSuccess: (data) => {
      // setWorksheetId(data.worksheet)
      console.log("Initial Value: ", data)
      let xdata=[]
      if(data.sections?.length>0){
      for(let i in data.sections){
        for(let j in data.sections[i].questions){
          if(data.sections[i].questions[j].subparts.length>0){
            for(let l in data.sections[i].questions[j].subparts){
              let subparts={
                subpart:data.sections[i].questions[j].subparts[l].id,
                description:null,
                marks:data.sections[i].questions[j].subparts[l].marks,
                subpart_name:data.sections[i].questions[j].subparts[l].name,
                section:data.sections[i].index,
                question_no:data.sections[i].questions[j].index,
              }
              xdata.push(subparts)
            }   
          }else{
            let questions={
              question:data.sections[i].questions[j].id,
              description:null,
              marks:data.sections[i].questions[j].marks,
              subpart_name:null,
              section:data.sections[i].index,
              question_no:data.sections[i].questions[j].index
            }
            xdata.push(questions)
          }     
        }
      }
    }
      if(!worksheet[currentIndex]?.isEvaluated){
        setInitialValue(xdata)
      }else{
        setSectionValues(xdata)
      }

      console.log("XDATA : ",xdata)
      console.log("Initial: ",initialValue)
      console.log("ReEv: ",reEvaluatedWorksheet)
    }
  })

  //uploading the pdf file to the server
  const {isLoading:isCommentLoading}=useQuery(['fetchEvaluatedSheet',currentIndex],()=>apiRequest('GET',`${API.EVALUATE_SHEET}${evaluationId}/`,null,null),{
    enabled:!!evaluationId && worksheet[currentIndex]?.status!== 'unmarked',
    refetchIntervalInBackground:false,
    refetchOnMount:false,
    refetchOnWindowFocus:false,
    onSuccess:(res)=>{
      console.log("Worksheet Changed initial value: ",res.comments_data)
      setReEvaluatedWorksheet(res)
      if(res.comments_data.length>0){
        setInitialValue(res.comments_data)
      }else{
        setInitialValue(sectionValues)
      }
    },
    onError:()=>{
      setReEvaluatedWorksheet(null)
      setInitialValue(sectionValues)
    }
  })
  const {mutateAsync: uploadPdf,isLoading:isUploadingPdf } = useMutation((data) => apiRequest('POST', API.EVALUATE_SHEET, data, null))

  //uploading the pdf file after re-evaluation
  const {mutateAsync: reUploadingPdf,isLoading:isReUploadingPdf } = useMutation((data) => apiRequest('POST', `${API.EVALUATE_SHEET}`, data, null),{
    
  })

  const {mutateAsync:assignMarks,isLoading:isAssigningMarksLoading}=useMutation((datas)=>apiRequest('POST',API.COMMENTS,datas,null))
  const {mutateAsync:reAssignMarks,isLoading:isReAssigningMarksLoading}=useMutation((datas)=>apiRequest('PATCH',API.COMMENTS,datas,null))


  function hideNavBar(){
    let header= document.getElementById('topbar')
    let contentBox = document.getElementById('content-id') 
    let sidebarBox = document.getElementById('sidebar-id') 
    contentBox.style.marginTop="0px"
    sidebarBox.style.marginTop="0px"
    header.style.visibility="hidden"
    header.style.top="-100px"
  }

  function showNavBar(){
    let header= document.getElementById('topbar')
    let contentBox = document.getElementById('content-id') 
    let sidebarBox = document.getElementById('sidebar-id') 
    header.style.visibility="visible"
    header.style.top="0px"
    contentBox.style.marginTop="4.5em"
    sidebarBox.style.marginTop="4.5em"
  }

  //Toggling Navbar
  useEffect(()=>{
    document.getElementById('pdf-container')
    .addEventListener('mouseenter',()=>hideNavBar())
    document.getElementById('pdf-container')
    .addEventListener('mouseleave',()=>showNavBar())
    if(state.pdf || state.data){
      // setCurrentWorksheet(state.data)
      setWorksheetId(state.data.worksheet_revision.id)
      setEvaluationID(state.data.evaluation_id || state.data.id)
      setCurrentWorksheetIndex(_.findIndex(worksheet,(item)=>{return item.id==state.data.id} ))
      console.log("Current Initial Index:",_.findIndex(worksheet,(item)=>{return item.id==state.data.id}))
      setCurrentWorksheet()
    }
  },[state])

  useEffect(()=>{
    if(instance && state?.data?.evaluated_xfdf_string && state?.data?.status === 'draft'){
        const {annotManager,docViewer}=instance;
        docViewer.on('documentLoaded', () => {
        annotManager.importAnnotations(state?.data?.evaluated_xfdf_string)
        .then((resXfdf)=>{
        }) 
        })                
    }
},[instance])



  useEffect(()=>{
    if(currentIndex && instance){
      setCurrentWorksheet()
    }
  },[currentIndex,instance])

  useEffect(()=>{
    if(evaluationStatus =='evaluate' || evaluationStatus=='draft'){
      handleFormSubmitButton()
    }
  },[evaluationStatus])


  function nextWorksheet(){
    _nextWorksheet()
    setWorksheetId(worksheet[currentIndex+1]?.worksheet_revision.id)
      setEvaluationID(worksheet[currentIndex+1].id)
      instance.loadDocument(worksheet[currentIndex+1].submitted_pdf)

    console.log("Current Index: ",currentIndex)
    console.log("Current Worksheet: ",worksheet[currentIndex])
  }

  

  
  function previousWorksheet(){
    _previousWorksheet()
    setWorksheetId(worksheet[currentIndex-1]?.worksheet_revision.id)
      setEvaluationID(worksheet[currentIndex-1].id)
      instance.loadDocument(worksheet[currentIndex-1].submitted_pdf)

    console.log("Current Index: ",currentIndex)
    console.log("Current Worksheet: ",worksheet[currentIndex])
  }


  function getFileNamefromPath(path){
    if(path){
      console.log('alkdf naaaame: ',path)
      let name = path.split('/');
      console.log('FIle naaaame: ',name[name.length-1])
      if(name[name.length-1].split('.')[1]!=='pdf')
      return `${name[name.length-1]}.pdf`
      else return name[name.length-1]
    }
   
  }

  const  convertToBlob=(url)=>{
    const httpsUrl=url;
    const name=`${httpsUrl.split('/')[url.split('/').length-1]}.pdf`
    console.log("File URL: ",httpsUrl)
    return fetch(httpsUrl)
  }

  useEffect(() => {
    console.log("State.ssssssss: ",state)
    if (state.pdf) {
      setDocumentLoading(true)
      console.log("PDF FILE: ",state.data)
      WebViewer({
        path: '/pdfjs-express/lib/public',
        css: '../../assets/scss/style.css',
        disabledElements:[
          'header',
          'toolsHeader',
          'documentContainer'
        ]
      },
        viewer.current,
      ).then((instance) => {
        setInstance(instance)
        const { docViewer, Annotations, setHeaderItems, annotManager,loadDocument } = instance;
        convertToBlob(state.pdf)
        .then(async(pdfFile)=>{
          pdfFile= await pdfFile.blob()

          console.log('FIlteeee type: ',pdfFile)
          loadDocument(pdfFile, { filename: getFileNamefromPath(state.pdf) });
          docViewer.on('documentLoaded', () => {
            instance.updateTool('AnnotationCreateFreeHand',{
              toolTip:10
          })
            const annotManagers = docViewer.getAnnotationManager();
            const rectangleAnnot = new Annotations.RectangleAnnotation();
            instance.enableElements([ 'header','toolsHeader','documentContainer' ])
            const displayPDf=document.getElementById('pdf-editor');
            const displayComments=document.getElementById('comments');
            const editor=document.getElementById('webviewer-1');
            editor.style.display="block"
            displayPDf.style.display="block"
            displayComments.style.display="block"
            setDocumentLoading(false)         
            rectangleAnnot.PageNumber = 1;
            rectangleAnnot.X = 100;
            rectangleAnnot.Y = 150;
            rectangleAnnot.Author = annotManagers.getCurrentUser();
            annotManagers.addAnnotation(rectangleAnnot);
            annotManagers.redrawAnnotation(rectangleAnnot);
            // if(state.data.evaluated_xfdf_string){
            //   instance.annotManager.importAnnotations(state.data.evaluated_xfdf_string)
            //   .then((importAnnot)=>{
            //     console.log('Importated Annotation: ',importAnnot)
            //   })
            // }
          });
        })
   


        setHeaderItems(header => {
          setDocumentLoading(false)
          header.delete(9);
          header.unshift({
            type: 'customElement',
            render: () => {
              const logo = document.createElement('img');
              logo.src = photos.logo;
              logo.style.width = '40px';
              logo.style.marginLeft = '10px';
              logo.style.cursor = 'pointer';
              logo.onclick = () => {
                window.open('https://www.opendoorsheet.qilinlab.com', '_blank');
              }
              return logo;
            }
          }, {
            type: 'spacer'
          });
        });

      })
    }
  }, [state])



  useEffect(()=>{
    console.log("Initialss Value: ",initialValue)
  },[initialValue])




  function handleFormSubmitButton(e) {
      document
      .getElementById('addMarksForm')
      .dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }


  function handelEvaluateSheet(status){
      setEvaluationStatus(status)
  }  





 async function onSubmit(value) {
    setIsGeneratingPdf(true)
    const xfdf = await instance.annotManager.exportAnnotations({});
    const pdfFile = new File([xfdf], state.pdf, {
      type: 'application/pdf',
      lastModified: new Date().getTime()
    })
    // console.log("Generated Blob: ", mergedBlob)
    console.log("Generated arr: ", pdfFile)
    console.log("Worksheeeeeet:",currentWorksheet)
    setUpdatedFile(pdfFile)
    //uploading it to the server
    let postData = new FormData()
    postData.append('submission', currentWorksheet?.submission ||currentWorksheet.id );
    postData.append('evaluated_xfdf',pdfFile )
    postData.append('submit',evaluationStatus=='draft'?'false':'true')
    postData.append('overall_comment',value?.overall_comment)
    if(currentWorksheet){
      if(!currentWorksheet.isEvaluated){
        uploadPdf(postData).then((res) => {
        setIsGeneratingPdf(false)
        console.log('Evaluated Worksheet:', res)
        setEvaluatedWorksheet(res.msg)
        if(res?.msg?.comments_data.length>0){
          setInitialValue(res?.msg?.comments_data)
        }
        uploadComments(value,res.msg.id)
        if(evaluationStatus == 'draft'){
          Alert.success('Draft Saved',3000)
          toast.error("Draft Saved", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose:5000,
          });
        } else{
          Alert.success('Worksheet has been re-uploaded Successfully!',3000)
        }
        setEvaluationStatus('idle')

      }).catch((err)=>{
        setIsGeneratingPdf(false)
        setEvaluationStatus('idle')
      })
    }else{
      reUploadingPdf(postData).then((res)=>{
        setIsGeneratingPdf(false)
        console.log('Re-aluated Worksheet:', res)
        setReEvaluatedWorksheet(res?.msg)
        if(res?.msg?.comments_data.length>0){
          setInitialValue(res?.msg?.comments_data)
        }
        // setWorksheetId(currentWorksheet.worksheet_revision.id)
        uploadComments(value,null)
        if(evaluationStatus == 'draft'){
          // Alert.success('Draft Saved',3000)
          toast.error("Draft Saved", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose:5000,
          });
        } else{
          Alert.success('Worksheet has uploaded Successfully!',3000)
          // toast.error("Worksheet has been re-uploaded Successfully!", {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose:5000,
          // });
        }
        setEvaluationStatus('idle')

      }).catch(err=>{
        setIsGeneratingPdf(false)
        setEvaluationStatus('idle')
      })
    }
    }

  }



  function uploadComments(value,id){
    console.log('Uploading Comments:...')
    let data={
      evaluation_id:id||reEvaluatedWorksheet?.id,
      comments:value.sections
    }
    if(!currentWorksheet.isEvaluated){
      return assignMarks(data).then((res)=>{
        // setIsAssignedMarksSuccess(true)
        if(evaluationStatus !== 'draft'){
          Alert.success('Worksheet has been Evaluated Successfully!',3000)
        }
      })
      .catch((err)=>{
        return{
          marks:getErrors(err,'marks')
        }
      })
    }  else{
      return reAssignMarks(data).then((res)=>{
        // setIsAssignedMarksSuccess(true)
        if(evaluationStatus !== 'draft'){
        Alert.success('Worksheet has been Re-Evaluated Successfully!',3000)
        }
      })
      .catch((err)=>{
        return{
          marks:getErrors(err,'marks')
        }
      })
    }
  }


  function marksUpdatationForm() {
    return (
      <CommentsForm
        worksheetData={worksheetData}
        onSubmit={onSubmit}
        onSubmitOverallComments={()=>{}}
        initialValue={initialValue}
        evaluationStatus={evaluationStatus}
        reEvaluatedWorksheet={reEvaluatedWorksheet}
        evaluatedWorksheet={evaluatedWorksheet}
        pdf={`${API.OPENDOOR_URL}${evaluatedWorksheet?.evaluated_pdf||reEvaluatedWorksheet?.evaluated_pdf}`}
      />
    )
  }


  function handleBack(){
    if(auth.USER_TYPE === 'Teacher'){
      history.push('/teacher-dashboard')
    }else{
      history.push({pathname:'/submitted-worksheets',state:{showTable:true}})
    }
  }


  return (
    <React.Fragment>
      <CompanianHeader
      title="Worksheet Evaluator"
      backFunction={handleBack}
      authorized={state?.readOnly !== 'yes' && initialValue }
      loading={(isAssigningMarksLoading || isReAssigningMarksLoading || isUploadingPdf || isReUploadingPdf || isGeneratingPdf) && evaluationStatus=='evaluate' }
      secondaryBtnLoading={(isAssigningMarksLoading || isReAssigningMarksLoading || isUploadingPdf || isReUploadingPdf || isGeneratingPdf) && evaluationStatus=='draft' }
      buttonName="Submit Evaluation"
      secondaryBtnName="Save as Draft"
      secondaryBtnAppearance="subtle"
      buttonOnClick={()=>handelEvaluateSheet('evaluate')}
      secondaryBtnFunc={()=>handelEvaluateSheet('draft')}
      
      />
    <div className="pdf-editor" id="pdf-container" >
      <div className="row" >
      <div className="col-md-5 col-lg-5 col-sm-12">
      <div className="comments-container" id="comments">
        {currentWorksheet &&(!isCommentLoading && !isWorksheeetLoading )&&
        <div className="student-details">
          <div className="pdf-editor__topper" >
          <div className="pdf-editor__topper__circle" >
           <div className="pdf-editor__topper__dot1" /> 
           <div className="pdf-editor__topper__dot2" /> 
           <div className="pdf-editor__topper__dot3" /> 
           </div>
          </div>
          <div className="student-details__container" >
          <div className="row" >
            <div className="col-md-6 col-lg-6 col-sm-12" >
            <div className="student-details__container__box" >
              <p className="heading" >{worksheet[currentIndex]?.student_name}</p>
              <p className="sub-heading">Student Name</p>
            </div>
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12" >
            <div className="student-details__container__box" >
              <p className="heading" >{worksheet[currentIndex]?.name ||worksheet[currentIndex]?.worksheet_name }</p>
              <p className="sub-heading">Worksheet Name</p>
            </div>
            </div>
          </div>
          </div>
       
        </div>}
        <div className="comments-container__comment-form" >
        {(!isCommentLoading && !isWorksheeetLoading )?marksUpdatationForm():
        <div>
          <Skeleton count={5}  />
        </div>
        }      
        </div>
        </div>
      </div>
      <div className="col-md-7 col-lg-7 col-sm-12">
    <div className="pdf-editor__wrapper" id="pdf-editor" >
         <div className="pdf-editor__topper">
           <div className="pdf-editor__topper__circle" >
           <div className="pdf-editor__topper__dot1" /> 
           <div className="pdf-editor__topper__dot2" /> 
           <div className="pdf-editor__topper__dot3" /> 
           </div>
            {!state?.noNav&&<div className="pdf-editor__topper__changeWorksheet" >
              <div className='pdf-editor__topper__changeWorksheet__box' onClick={previousWorksheet} >
                <IconButton
                disabled={currentIndex==0}
                size="sm" appearance="primary" placement="left" icon={<Icon icon="long-arrow-left" />}  >Previous Worksheet</IconButton>
              </div>
              <div className='pdf-editor__topper__changeWorksheet__box' onClick={nextWorksheet} >
                
                <IconButton 
                disabled={currentIndex == worksheet.length-1}
                size="sm" appearance="primary" placement="right" icon={<Icon icon="long-arrow-right" />}  >Next Worksheet</IconButton>
              </div>
            </div>}
           
         </div>
       <div className="webviewer" ref={viewer}>
       {(documentLoading)&&<Animation
            json={handLoading}
            height={450}
            width={450}
          />}
       </div>
      
        </div>
      </div>
      </div>

    </div>
    </React.Fragment>
  )
}



PdfEditor.propTypes = {
  pdf: PropTypes.string
}


const mapStateToProps=state=>{
  return{
    worksheet:state.TeachersReducer.worksheets,
    currentIndex:state.TeachersReducer.currentIndex,
    currentWorksheet:state.TeachersReducer.currentWorksheet,
    evaluationId:state.TeachersReducer.evaluationId,
    worksheetId:state.TeachersReducer.worksheetId,
    auth:state.AuthReducer.auth
  }
}

const mapDispatchToProps=dispatch=>{
  return{
    _nextWorksheet:()=>dispatch(action.nextWorksheet()),
    _previousWorksheet:()=>dispatch(action.previousWorksheet()),
    setCurrentWorksheetIndex:(index)=>dispatch(action.setCurrentWorksheetIndex(index)),
    setCurrentWorksheet:()=>dispatch(action.setCurrentWorksheet()),
    setEvaluationID:()=>dispatch(action.setEvaluationId()),
    setWorksheetId:()=>dispatch(action.setWorksheetId()),
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(PdfEditor)