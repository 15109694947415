
import React from 'react'
import {SelectPicker} from 'rsuite'

export default function ColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set()
      preFilteredRows.forEach(row => {
        options.add(row.values[id])
      })
      let data=[];
      let arr=[...options.values()];
      for(let key in arr){
        let obj={key,label:arr[key]}
        data.push(obj)
      } 
      return data
    }, [id, preFilteredRows])
  
    // Render a multi-select box
    return (
 <SelectPicker
 data={options||[]}
 placeholder={`Select`}
 block
 labelKey='label'
 valueKey="key"
 size="xs"
 searchable={false}
 onSelect={(v,b)=>setFilter(b.label)}
 onClean={(v)=>setFilter(undefined)}
 />
    )
  }