//@author:Himanshu Barmola
//Project: OpenDoor Sheets Admin Panel


// Customizing the Input field for Redux-Form

import React from "react"
import { CheckPicker,Icon,Checkbox } from "rsuite"
export const RenderMultiSelect = (props) => {
                const [allValue,setAllValue]=React.useState([])
                const [checkAll,setCheckAll]=React.useState(false)
                const allValues = props.options.map(item => item[props.valueKey]);
                const checkPickerClassName= `col-md-${props.selectAll?"8":"12"} col-sm-${props.selectAll?"8":"12"} col-lg-${props.selectAll?"8":"12"}`
     
        function handleSelectAll(value,checked){
            const nextValue = checked ? allValues : [];
            setAllValue(nextValue)
            setCheckAll(checked)
            props.input.onChange(nextValue)
        }

    return (
        <div className="input-field">
            <div className="row" >
                <div className={checkPickerClassName} >
                <CheckPicker
                {...props.input}
                data={props.options}
                placeholder={props.placeholder}
                value={allValue.length>0?allValue:props.input.value}
                name={props.input.name}
                disabled={props.disable}
                labelKey={props.labelKey}
                valueKey={props.valueKey}
                defaultValue={props.defaultValue}
                container={()=>props.container}

                cleanable={false}
                searchable={props.searchable}
                block="true"
                size="lg"
                className={props.meta.touched && (props.meta.error|| props.meta.submitError) != undefined ? "error-field p-0" : undefined}
                renderMenu={menu=>{
                    if(props.options.length===0){
                        return(
                            <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                            <Icon icon="spinner" spin /> {props.loadingMsg?props.loadingMsg:"Fetching.."}
                          </p>
                        )
                       
                    }
                    return menu;
                }}
                


            />
                </div>
                {props.selectAll&&<div className="col-md-4 col-sm-4 col-lg-4" >
                    <Checkbox onChange={handleSelectAll} checked={checkAll} >Select All</Checkbox>
                </div>}
            </div>
            {props.meta.touched&&(props.meta.error|| props.meta.submitError)&&<p className="error-msg" >{(props.meta.error|| props.meta.submitError)}</p>}
        </div>
    )
}



export default RenderMultiSelect