import React,{useEffect,useState} from 'react';
import {photos} from "../../constants/images"
import {Form,Field} from "react-final-form"
import {connect} from "react-redux"
import {InputIcon,PasswordInput} from "../../components/form"
import {HiOutlineMail,HiOutlineKey} from "react-icons/all"
import {Button} from "rsuite"
import {email,required,composeValidators,confirmPasswordVailid} from "../../components/form/Validations"
import {useMutation} from 'react-query'
import {history,apiRequest,getErrors} from "../../helpers"
import * as API from '../../api/API'
import * as action from '../../redux/creators/auth.creator'
import {FORM_ERROR} from 'final-form'
import jwt_decode from 'jwt-decode'
import setAuthToken from "../../helpers/setAuthToken"

function ResetPassword({dispatch}){
    const {isLoading,mutateAsync,data}=useMutation((data)=>apiRequest('POST',API.CONFIRM_PASSWORD,data,null))
    const [token,setToken]=useState(null)
    const [passwordSuccessMsg,setPasswordSuccessMsg]=useState(null)
    const handleResetPassword=(values)=>{
        let data={
            password:values.password,
            token
        }
        return mutateAsync(data)
        .then((res)=>{
            setPasswordSuccessMsg('Your password has been changed successfully!')
            // history.push('/login')
        })
        .catch((err)=>{
            return{
                [FORM_ERROR]:getErrors(err,'detail') || getErrors(err,'msg'),
            }
        })
    }

    useEffect(()=>{
     getToken(window.location.href)
    },[])



    function getToken(url){
        var urlString = new URL(url);
        var c=urlString.searchParams.get('token')
        setToken(c)
        console.log("Token",c)
        return c;
    }




    return (
        <div className="login-container" >
            <div className="login-container__form" >
                <div className="login-container__form__logo" >
                <img src={photos.logo} />
                <h3>Reset Password</h3>
                </div>
                <Form onSubmit={handleResetPassword} >
                 {({handleSubmit,submitting,pristine,submitError})=>{
                     return(
                    <form onSubmit={handleSubmit} >
                        
                        <div className="input-field" >
                        <label>New Password</label>
                        <Field  component={PasswordInput} name="password" placeholder="Enter your New Password"  icon={HiOutlineMail} validate={required}  />
                        </div>
                        <div className="input-field" >
                        <label>Confirm Password</label>
                        <Field  component={InputIcon} isPassword name="confirmPassword"  placeholder="Enter the Password again"  icon={HiOutlineKey} validate={confirmPasswordVailid}  />
                        </div>
                        {submitError&&
                        <div className="error-field">
                           <p className="heading">{submitError}</p> 
                        </div>}
                        {passwordSuccessMsg&&
                           <div className="success-msg">
                           <p className="heading">{passwordSuccessMsg}</p> 
                        </div> 
                        }
                        {!token&&
                        <div className="error-field">
                        <p className="heading">Please open a valid link from your registered email</p> 
                     </div>
                        }
                        {!passwordSuccessMsg&&<div className="button-field">
                        <Button appearance="primary" size="lg" type="submit" disabled={submitting || pristine} block loading={isLoading}   >Change Password</Button>
                        </div>
                    }
                    </form>
                     )
                 }}   
           
            </Form>
            {passwordSuccessMsg&&<div className="button-field">
                        <Button appearance="primary" size="lg" onClick={()=>history.push('/login')} type="submit"  block   >Go to Login</Button>
                        </div>
                    }
            <div className="login-container__form__caption" >
            <p>Resetting your password will override your previous password.</p>
            </div>
            </div>
        </div>
    );
}

const mapStateToProps=state=>{
    return {

    }
}

export default connect(mapStateToProps,null)(ResetPassword)
