/** @format */

import React, { useMemo, forwardRef, useEffect, Fragment } from "react";
import {
  useGlobalFilter,
  useTable,
  useSortBy,
  useColumnOrder,
  usePagination,
  useFilters,
  useExpanded,
  useRowSelect,
} from "react-table";
import { GlobalFilter, Pagination, GlobalColoumnFilter } from "./";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/all";
import PropTypes from "prop-types";
import { Button, Checkbox } from "rsuite";
import styled from "styled-components";
import { matchSorter } from "match-sorter";
import { MdExpandMore, MdExpandLess } from "react-icons/all";

export default function Table({
  data,
  columns: COLUMNS,
  isExpandable,
  columnOrder,
  actionButton: ActionButton,
  justifyContent,
  filterData,
  isPaginated,
  canRowSelect,
  userSelectedRows,
  searchPlaceholder,
  next,
  previous,
  pageCount,
  pageChange,
  filterProperty,
  setSearch,
  setFilterParam,
  filterValue,
  renderRowSubComponent,
  page:pageNumber
}) {
  // const data =useMemo(()=>DATA,[])
  const columns = useMemo(() => COLUMNS, []);

  const ActionDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${justifyContent ? justifyContent : "flex-start"};
  `;

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;
      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input
            type="checkbox"
            className="custom-checkbox__elm"
            ref={resolvedRef}
            {...rest}
          />
        </>
      );
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageOptions,
    prepareRow,
    selectedFlatRows,
    state,
    setGlobalFilter,
    setFilter,
    preGlobalFilteredRows,
    preFilteredRows,
    visibleColumns,
    setColumnOrder,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    {
      data,
      columns,
      filterTypes,
    },
    useGlobalFilter,
    useFilters,
    useColumnOrder,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      ActionButton &&
        hooks.allColumns.push((columns) => [
          {
            id: "action",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <ActionDiv>Action</ActionDiv>
            ),
            Cell: ({ row }) => (
              <ActionDiv>
                <ActionButton data={row.original} />
              </ActionDiv>
            ),
          },
        
          ...columns,
        ]);
      canRowSelect &&
        hooks.allColumns.push((columns) => [
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => {
              return (

              <div>
                {row.original?.isEvaluated == false ?<div/>:<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />}
              </div>
            )},
          },
          ...columns,
        ]);

        isExpandable && hooks.allColumns.push((columns)=>[
            ...columns,
            {
                // Build our expander column
                id: 'expander', // Make sure it has an ID
                Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
                  <span {...getToggleAllRowsExpandedProps()}>
                    {isAllRowsExpanded ? <MdExpandLess size={16} />:<MdExpandMore size={16} />}
                  </span>
                ),
                Cell: ({ row }) =>
                  // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                  // to build the toggle for expanding a row
                  row.canExpand ? (
                    <span
                      {...row.getToggleRowExpandedProps({
                        style: {
                          // We can even use the row.depth property
                          // and paddingLeft to indicate the depth
                          // of the row
                          paddingLeft: `${row.depth * 2}rem`,
                        },
                      })}
                    >
                      {row.isExpanded ? <Button appearance="ghost" size="xs" >Hide Subpart</Button>:<Button appearance="ghost" size="xs" >Show Subpart</Button>}
                    </span>
                  ) : null,
              },
             
        ])
    }
  );

  useEffect(() => {
    if (columnOrder) {
      setColumnOrder(columnOrder);
    }
  }, [columnOrder]);

  useEffect(() => {
    if (userSelectedRows) {
      let data = [];
      console.log("Selected Flat row: ", selectedFlatRows);
      for (let i in selectedFlatRows) {
        data.push(selectedFlatRows[i].original);
      }
      userSelectedRows(data);
    }
  }, [selectedFlatRows]);

  useEffect(() => {
    console.log("Page Option: ", pageOptions);
  }, [pageOptions]);

  const clearFilter = () => {
    setFilterParam({});
    const clear = document.querySelector(".rs-picker-toggle-clean");
    clear.dispatchEvent(
      new Event("click", { bubbles: true, cancelable: true })
    );
    clear.removeEventListener("click", () => {});
  };

  const { globalFilter, pageIndex, selectedRowIds } = state;
  return (
    <div className="table-resonsive box animate__animated animate__fadeIn animate__faster">
      <div className="box__border">
        <div className="global-filter">
          <div>
            <GlobalFilter
              filter={globalFilter}
              setFilter={setSearch}
              placeholder={searchPlaceholder}
            />
          </div>
          <div className="global-filter__filter">
            {filterProperty &&
              filterProperty.map((item, idx) => (
                <div>
                  <GlobalColoumnFilter
                    key={idx}
                    setFilter={setFilterParam}
                    preFilteredRows={item.data}
                    filter={globalFilter}
                    labelKey={item.label}
                    valueKey={item.value}
                    query={item.query}
                    type={item.type}
                    filterValue={filterValue}
                    property={item.property}
                  />
                </div>
              ))}
          </div>
        </div>
        <table className="table table-hover" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      scope="col"
                      {...column.getHeaderProps(
                        (!column.sortable === false ||
                          column.sortable === undefined) &&
                          column.getSortByToggleProps()
                      )}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <BsArrowDownShort size={20} />
                          ) : (
                            <BsArrowUpShort size={20} />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Fragment>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}> {cell.render("Cell")} </td>
                    ))}
                  </tr>
                  {!isExpandable && row.isExpanded ? (
                    <tr>
                      {/* <div colSpan={visibleColumns.length} > */}
                      {renderRowSubComponent(row.original)}
                      {/* </div> */}
                    </tr>
                  ) : null}
                
                </Fragment>
              );
            })}
          </tbody>
        </table>
        <Pagination
          pageOptions={pageOptions}
          pageIndex={isPaginated==false?pageIndex:pageNumber-1 }
          gotoPage={isPaginated == false ? gotoPage : pageChange}
          canPreviousPage={isPaginated == false ? canPreviousPage : previous}
          canNextPage={isPaginated == false ? canNextPage : next}
          pageCount={pageCount}
          previousPage={() =>
            isPaginated == false
              ? previousPage()
              : pageChange((prev) => prev - 1)
          }
          nextPage={() =>
            isPaginated == false ? nextPage() : pageChange((prev) => prev + 1)
          }
        />
      </div>
      {/* <Footer/> */}
    </div>
  );
}

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  columnOrder: PropTypes.array,
  actionButton: PropTypes.func,
  justifyContent: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  next: PropTypes.bool,
  previous: PropTypes.bool,
  pageCount: PropTypes.bool,
  filterProperty: PropTypes.array,
  setSearch: PropTypes.func,
  setFilterParam: PropTypes.func,
  filterData: PropTypes.array,
  isPaginated: PropTypes.bool,
  renderRowSubComponent: PropTypes.func,
  canRowSelect: PropTypes.bool,
  userSelectedRows: PropTypes.func,
  isExpandable: PropTypes.bool,
  page:PropTypes.number
};
