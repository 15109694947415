import React from 'react'
import PropTypes from "prop-types"
import {CompanianHeader} from '../../components'



function _CompanianHeader({title,authorized,
                        buttonOnClick,rsicon,
                        icon,loading,
                        buttonAppearance,buttonName,
                        iconSize,buttonType,
                        disable,caption,
                        secondaryBtnName,secondaryBtnIcon,
                        secondaryBtnLoading,secondaryBtnAppearance,
                        secondaryBtnIconSize,secondaryBtnDisable,
                        secondaryBtnType,secondaryBtnFunc,
                        secondaryBtnColor,primaryBtnColor,iconPlacement,
                        backClicked,triggerBackEvent,navbarData,navbarIndex,setNavbarIndex,backFunction
                    }) {
    return (
        <div>
           <CompanianHeader 
           title={title} 
           authorized={authorized} 
           buttonOnClick={buttonOnClick} 
           rsicon={rsicon}
           icon={icon}
           loading={loading}
           buttonAppearance={buttonAppearance}
           buttonName={buttonName}
           iconSize={iconSize}
           buttonType={buttonType}
           disable={disable}
           caption={caption}
           secondaryBtnName={secondaryBtnName}
           secondaryBtnIcon={secondaryBtnIcon}
           secondaryBtnLoading={secondaryBtnLoading}
           secondaryBtnAppearance={secondaryBtnAppearance}
           secondaryBtnIconSize={secondaryBtnIconSize}
           secondaryBtnDisable={secondaryBtnDisable}
           secondaryBtnType={secondaryBtnType}
           secondaryBtnFunc={secondaryBtnFunc}
           secondaryBtnColor={secondaryBtnColor}
           primaryBtnColor={primaryBtnColor}
           iconPlacement={iconPlacement}
           backClicked={backClicked} 
           triggerBackEvent={triggerBackEvent}
           navbarData={navbarData}
           navbarIndex={navbarIndex}
           setNavbarIndex={setNavbarIndex}
           backFunction={backFunction}
           /> 
        </div>
    )
}



_CompanianHeader.propTypes={
title:PropTypes.string.isRequired,
authorized:PropTypes.bool,
buttonOnClick:PropTypes.func,
rsicon:PropTypes.string,
icon:PropTypes.any,
loading:PropTypes.bool,
buttonAppearance:PropTypes.string,
buttonName:PropTypes.string,
iconSize:PropTypes.number,
buttonType:PropTypes.string,
disable:PropTypes.bool,
caption:PropTypes.string,
secondaryBtnName:PropTypes.string,
    secondaryBtnIcon:PropTypes.any,
    secondaryBtnLoading:PropTypes.bool,
    secondaryBtnAppearance:PropTypes.string,
    secondaryBtnIconSize:PropTypes.number,
    secondaryBtnDisable:PropTypes.bool,
    secondaryBtnType:PropTypes.string,
    secondaryBtnFunc:PropTypes.func,
    primaryBtnColor:PropTypes.string,
    secondaryBtnColor:PropTypes.string,
    iconPlacement:PropTypes.string,
    backClicked:PropTypes.func,
    triggerBackEvent:PropTypes.bool,
    navbarData:PropTypes.array,
    navbarIndex:PropTypes.number,
    setNavbarIndex:PropTypes.func,
    backFunction:PropTypes.func
}


export default _CompanianHeader