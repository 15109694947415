import React, { Fragment, useState, useEffect } from 'react';
import { Form, Field, FormSpy } from 'react-final-form'
import { FieldArray } from "react-final-form-arrays"
import arrayMutators from "final-form-arrays"
import { OnChange } from 'react-final-form-listeners'
import { Input, Dropdown, Radio, ImageUploader, DatePicker, InputNumber, FileUploader, ColorPicker } from "../../components/form"
import PropTypes from "prop-types"
import { Button, SelectPicker, IconButton, Icon, Alert, Steps } from 'rsuite'
import { required, composeValidators, totalSection, imageRequired } from "../../components/form/Validations"
import { apiRequest, history } from "../../helpers/"
import { IoAddSharp, AiOutlineSetting, RiDeleteBin6Line } from 'react-icons/all'
import { Element, Link } from 'react-scroll'
import * as API from '../../api/API'
import { useQuery, useMutation, QueryClient } from 'react-query'
import * as _ from 'lodash'
import classnames from 'classnames'
const alphabet = "abcdefghijklmnopqrstuvwxyz"

function WorksheetForm(props) {
    const { handleFormSubmit, setIsValid, disable, initialValue, error, showNextPart, setInitialValue, revisionId, setRevisionID, isNewRevision, setIsNewRevision, sectionInitialValue, setSectionInitialValue } = props
    const queryClient = new QueryClient()
    const [revisionChanged, setRevisionChanged] = useState(false)
    const defaultValue = { sections: [{ name: "", no_of_questions: 1, questions: [{ no_of_subparts: 0, subparts: [{ marks: "" }] }] }] }
    const { data: revisionData } = useQuery(['fetchRevision', revisionId], () => apiRequest('GET', API.WORKSHEET_REVISION, null, { ...revisionId }), {
        onSuccess: (data) => {
            try {
                setInitialValue({
                    ...initialValue,
                    is_free: data.is_free,
                    is_latest: data.is_latest,
                    multi_section: data.multi_section,
                    question_sheet: data.question_sheet,
                    answer_sheet: data.answer_sheet,
                    scorable: data.scorable,
                    version_name: data.version_name,
                    no_of_sections: _.isArray(data.sections) ? data.sections.length : undefined,
                    sections: _.isArray(data.sections) ? data.sections : [],
                    status: data?.status
                })
            } catch (e) {

            }

        },
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        enabled: (initialValue !== null && !isNewRevision)
    },

    )
    const { mutate, isLoading: isLatestLoading } = useMutation((data) => apiRequest('PATCH', API.WORKSHEET_REVISION, data, { ...revisionId }), {
        onSuccess: (data) => {
            Alert.success('Success!')
            setRevisionChanged(true)
            setRevisionID(data?.id)
            // queryClient.refetchQueries()
            queryClient.fetchQuery(['fetchRevision', revisionId], () => apiRequest('GET', API.WORKSHEET_REVISION, null, { revision_id: data?.id })).then((data) => {
                setInitialValue({
                    ...initialValue,
                    is_free: data.is_free,
                    is_latest: data.is_latest,
                    multi_section: data.multi_section,
                    question_sheet: data.question_sheet,
                    answer_sheet: data.answer_sheet,
                    scorable: data.scorable,
                    version_name: data.version_name,
                    no_of_sections: data.sections.length,
                    sections: data?.sections,
                    status: data?.status
                })
            })
        }
    })


    function handleRevisionChange(value, obj) {
        console.log("Revision Change: ", value, obj)
        setRevisionID({ revision_id: obj.id })
        setIsNewRevision(false)
        setRevisionChanged(false)
    }

    function handleLatestRevision() {
        mutate({
            is_latest: true
        })
    }

    useEffect(() => {
        if (initialValue && _.isEmpty(revisionId)) {
            setRevisionID({ revision_id: initialValue?.version_id || {} })
            // setIsNewRevision(false)
        }
    }, [initialValue])

    // useEffect(()=>{
    //     if(showNextPart){
    //        setRevisionChanged(true)
    //     }
    // },[showNextPart])

    function handleAddRevision() {
        setRevisionID({})
        setIsNewRevision(true)
        setInitialValue({
            ...initialValue,
            status: "draft",
            multi_section: "No",
            scorable: "Yes",
            is_free: "True",
            no_of_sections: 1,
            version_name: "",
            is_latest: true,
            version_id: null,
            sections: [{ name: "", no_of_questions: 1, questions: [{ no_of_subparts: 0, marks: "" }] }]
        })
        // setSectionInitialValue({
        //     sections:[{name:"",no_of_questions:1,questions:[{no_of_subparts:0,marks:""}]}],
        //     })

        const clear = document.querySelector('.rs-picker-toggle-clean')
        if (clear) {
            clear.dispatchEvent(new Event('click', { bubbles: true, cancelable: true }))

        }
    }


    const WhenFieldChanges = ({ field, becomes, set, to }) => (
        <Field name={set} subscription={{}} >
            {({ input: { onChange } }) => (
                <FormSpy subscription={{}} >
                    {({ form }) => (
                        <OnChange name={field} >
                            {value => {
                                console.log("Form State kkkk: ", form)
                                if (value === becomes) {
                                    onChange(to);
                                }
                            }}
                        </OnChange>
                    )}
                </FormSpy>
            )}
        </Field>
    )


    const DefaultSection = ({ field, becomes, set, to, push, change }) => (
        <FieldArray name={set} subscription={{}} >
            {({ fields: { value } }) => (
                <FormSpy subscription={{}} >
                    {({ form }) => (
                        <OnChange name={field} >
                            {value => {
                                console.log("Form Array State kkkk: ", form)
                                if (value === becomes) {
                                    change(set, undefined)
                                    change('no_of_sections', 1)
                                    push(set, to);
                                }
                            }}
                        </OnChange>
                    )}
                </FormSpy>
            )}
        </FieldArray>
    )

    const headingWrapper = classnames('heading', {
        'mt-5': history.location.pathname !== '/modules/worksheet/edit-worksheet'
    })

    return (
        <div className="add-form add-worksheet-form box">
            {history.location.pathname !== '/modules/worksheet/edit-worksheet' &&
                <Steps current={!showNextPart ? 0 : 1} >
                    <Steps.Item title="Question Settings" status={!showNextPart ? "process" : "finish"} ></Steps.Item>
                    <Steps.Item title="Revision Settings"></Steps.Item>
                </Steps>}
            <div className={headingWrapper} >
                <AiOutlineSetting size={32} />
                <Link to="sectionArea" >
                    <h2>Worksheet Settings</h2>
                </Link>
            </div>
            <Form
                onSubmit={handleFormSubmit}
                initialValues={initialValue}
                mutators={{ ...arrayMutators }}

            >
                {({ handleSubmit, valid, submitError, submitting, pristine, values, form: { mutators: { push, pop, remove }, change } }) => {
                    setIsValid(!valid)

                    return (
                        <form id="addWorksheetForm" onSubmit={handleSubmit}>

                            <div className="add-worksheet-form__firstPart" >
                                <div className="row">
                                    <div className="col-md-7 col-lg-7 col-12" >
                                        <div className="row" >
                                            <div className="col-md-12 col-lg-12 col-12" >
                                                <div className="input-field" >
                                                    <label>Worksheet Title</label>
                                                    <Field
                                                        component={Input}
                                                        name="name"
                                                        validate={required}
                                                        placeholder="Enter the Title of Worksheet"
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-4 col-lg-4 col-12" >
                                            <div className="input-field" >
                                            <label>Background Color</label>
                                             <Field 
                                             name="background_color"
                                            component={ColorPicker}
                                            validate={required}
                                             />  
                                            </div>
                                        </div> */}
                                        </div>

                                        <div className="row" >
                                            <div className="col-md-5 col-lg-5 col-sm-12" >
                                                <div className="input-field" >
                                                    <label>Inactive Date</label>
                                                    <Field
                                                        name="inactive_date"
                                                        component={DatePicker}
                                                        helpBlock="Select the date when this worksheet will expire."
                                                    />
                                                </div>

                                            </div>
                                            <div className="col-md-3 col-lg-3 col-sm-12" >
                                                <div className="input-field" >
                                                    <label>Estimated Time</label>
                                                    <Field
                                                        component={Input}
                                                        validate={required}
                                                        name="estimated_time_to_complete"
                                                        placeholder="(min)"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-sm-12" >
                                                <div className="input-field" >
                                                    <label>Is Popular</label>
                                                    <Field
                                                        name="is_popular"
                                                        component={Radio}
                                                        defaultValue={false}
                                                        radioData={[
                                                            { value: true, label: "Yes" },
                                                            { value: false, label: "No" },
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 col-lg-5 col-12" >
                                        <div className="input-field" >
                                            <label>Image</label>
                                            <Field
                                                component={ImageUploader}
                                                placeholder="Choose"
                                                name="image"
                                                validate={imageRequired}
                                                helpBlock="Uploaded image will be used as a Cover Photo of this worksheet"
                                            />
                                        </div>
                                    </div>

                                </div>


                                {showNextPart &&
                                    <div className="element" id="section-area" >
                                        <div className="heading" >
                                            <AiOutlineSetting size={32} />
                                            <h2>Question Settings</h2>
                                        </div>

                                        <div className="row" >
                                            <div className="col-md-6 col-lg-6 col-sm-12">
                                                <div className="row" >
                                                    <div className="col-md-6 col-lg-6 col-sm-12">
                                                        <div className="input-field" >
                                                            <label>Worksheet can be multi-section</label>
                                                            <Field
                                                                name="multi_section"
                                                                options={[{ label: "Yes" }, { label: "No" },]}
                                                                labelKey="label"
                                                                valueKey="label"
                                                                component={Dropdown}
                                                                cleanable={false}
                                                                searchable={false}
                                                                defaultValue="No"
                                                                disable={initialValue?.status === "Published" || false}
                                                            />

                                                            <Fragment>
                                                                {/* <WhenFieldChanges
                                                            field="multi_section"
                                                            becomes="No"
                                                            set="sections"
                                                            to={undefined}
                                                            /> */}
                                                                {
                                                                    // <WhenFieldChanges
                                                                    // field="multi_section"
                                                                    // becomes="No"
                                                                    // set="no_of_sections"
                                                                    // to={1}
                                                                    // />
                                                                }
                                                                <WhenFieldChanges
                                                                    field="no_of_sections"
                                                                    becomes="2"
                                                                    set="multi_section"
                                                                    to="Yes"
                                                                />
                                                               {(isNewRevision|| !initialValue)&&
                                                            <DefaultSection
                                                            field="multi_section"
                                                            becomes="No"
                                                            set="sections"
                                                            to={{name:"",no_of_questions:1,questions:[{no_of_subparts:0,marks:""}]}}       
                                                            push={push}
                                                            change={change}
                                                            />}

                                                            </Fragment>

                                                        </div>

                                                    </div>
                                                    <div className="col-md-6 col-lg-6 col-sm-12">
                                                        <div className="input-field" >
                                                            <label>Total number of sections</label>
                                                            <Field
                                                                component={InputNumber}
                                                                name="no_of_sections"
                                                                min={1}
                                                                formArray
                                                                arrayFieldName="sections"
                                                                validate={required}
                                                                pushFn={push}
                                                                popFn={pop}
                                                                helpBlock="Enter Minimum of 2 Sections"
                                                                validate={composeValidators(required)}
                                                                disable={initialValue?.status === "Published" || false}
                                                                defaultValue={1}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="row" >
                                                    <div className="col-md-6 col-lg-6 col-sm-12">

                                                        <div className="input-field" >
                                                            <label>Worksheet can be scored</label>
                                                            <Field
                                                                name="scorable"
                                                                options={[{ label: "Yes" }, { label: "No" },]}
                                                                labelKey="label"
                                                                valueKey="label"
                                                                component={Dropdown}
                                                                cleanable={false}
                                                                searchable={false}
                                                                defaultValue="Yes"
                                                                disable={initialValue?.status === "Published" || false}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-6 col-sm-12">
                                                        <div className="input-field" >
                                                            <label>Worksheet Type</label>
                                                            <Field
                                                                name="is_free"
                                                                component={Radio}
                                                                validate={required}
                                                                defaultValue="True"
                                                                disable={initialValue?.status === "Published" || false}
                                                                radioData={[
                                                                    { value: "True", label: "Free" },
                                                                    { value: "False", label: "Paid" },
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Revision dropdown                       */}
                                            {initialValue &&
                                                <div className="col-md-6 col-lg-6 col-sm-12">
                                                    <div className="row" >
                                                        <div className="col-md-6 col-lg-6 col-sm-12">
                                                            <div className="input-field" >
                                                                <label>Select Revisions</label>
                                                                <SelectPicker
                                                                    data={initialValue.worksheet_revisions}
                                                                    labelKey="version_name"
                                                                    valueKey="version_name"
                                                                    defaultValue={initialValue.version_name}
                                                                    searchable={false}
                                                                    cleanable={true}
                                                                    size="lg"
                                                                    onSelect={handleRevisionChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-6 col-sm-12">
                                                            {(!_.isEmpty(revisionId) && !initialValue?.is_latest && !isNewRevision) &&
                                                                <div className="input-field mt-4" >
                                                                    <Button appearance="primary" className="secondary-btn" size="lg" onClick={handleLatestRevision} loading={isLatestLoading}  >Make Latest</Button>
                                                                </div>}
                                                        </div>
                                                    </div>
                                                    {initialValue && initialValue.status === "Published" &&
                                                        <div className="row" >
                                                            <div className="col-md-6 col-lg-6 col-sm-12">
                                                                <div className="input-field mt-5" >
                                                                    <IconButton icon={<Icon icon="plus" />} appearance="primary" size="lg" onClick={handleAddRevision} >Add Revision</IconButton>
                                                                </div>
                                                            </div>
                                                        </div>}
                                                </div>}
                                        </div>
                                        <FieldArray name="sections"
                                            initialValue={sectionInitialValue?.sections  }
                                        >
                                            {({ fields }) =>
                                                fields.map((name, index) => (
                                                    <div className="add-worksheet-form__section" key={index} id="worksheet-sections" >
                                                        <div className="add-worksheet-form__section__heading">
                                                            <p className="section-name">{`Section ${index + 1}`}</p>
                                                            {/* <div className="action-btn" onClick={()=>fields.remove(index)} >
                                                            <RiDeleteBin6Line size={26} />
                                                            </div> */}
                                                        </div>
                                                        <div className="row" >
                                                            <div className="col-md-6 col-lg-6 col-sm-12">
                                                                <div className="input-field" >
                                                                    <label>Section Name</label>
                                                                    <Field
                                                                        component={Input}
                                                                        placeholder={`Enter Section ${index + 1} Name`}
                                                                        name={`${name}.name`}
                                                                        validate={required}
                                                                        disable={initialValue?.status === "Published" || false}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-lg-6 col-sm-12">
                                                                <div className="input-field" >
                                                                    <label>No. of Questions</label>
                                                                    <Field
                                                                        disable={initialValue?.status === "Published" || false}
                                                                        component={InputNumber}
                                                                        name={`${name}.no_of_questions`}
                                                                        min={1}
                                                                        validate={required}
                                                                        formArray
                                                                        arrayFieldName={`${name}.questions`}
                                                                        pushFn={push}
                                                                        popFn={pop}
                                                                        parse={value => Number(value)}
                                                                    // defaultValue={(initialValue&& initialValue.no_of_sections>0)? initialValue?.sections[index]?.questions.length:initialValue?.sections[index].no_of_questions}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <FieldArray name={`${name}.questions`}>
                                                            {({ fields }) =>
                                                                fields.map((question, Qindex) => (
                                                                    <div className="add-worksheet-form__question" key={Qindex} >
                                                                        <div className="add-worksheet-form__section__sub-heading">
                                                                            <p className="section-name">{`Question ${Qindex + 1}`}</p>
                                                                        </div>
                                                                        <div className="row" >
                                                                            <div className="col-md-6 col-lg-6 col-sm-12" >
                                                                                <div className="input-field" >
                                                                                    <label>Add Sub-Parts</label>
                                                                                    <Field
                                                                                        disable={initialValue?.status === "Published" || false}
                                                                                        name={`${question}.no_of_subparts`}
                                                                                        component={InputNumber}
                                                                                        min={0}
                                                                                        formArray={values.scorable == "Yes"}
                                                                                        arrayFieldName={`${question}.subparts`}
                                                                                        pushFn={push}
                                                                                        popFn={pop}
                                                                                        defaultValue={0}
                                                                                        parse={value => Number(value)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6 col-lg-6 col-sm-12">
                                                                                {values.scorable == "Yes" &&
                                                                                    <Fragment>
                                                                                        {values.sections[index]?.questions[Qindex]?.no_of_subparts > 0 ?
                                                                                            <FieldArray name={`${question}.subparts`} >
                                                                                                {({ fields }) =>
                                                                                                    fields.map((subpart, i) => (
                                                                                                        <div className="add-worksheet-form__marksBox" key={i}>
                                                                                                            <label  >{`${Qindex + 1}(${alphabet.charAt(i)})`}</label>
                                                                                                            <Field
                                                                                                                disable={initialValue?.status === "Published" || false}
                                                                                                                name={`${subpart}.marks`}
                                                                                                                component={Input}
                                                                                                                validate={required}
                                                                                                                placeholder="Enter Marks"
                                                                                                                parse={value => Number(value)}
                                                                                                            />

                                                                                                        </div>
                                                                                                    ))}
                                                                                            </FieldArray> :
                                                                                            <div className="input-field" >
                                                                                                <label  >{`${Qindex + 1}`}</label>
                                                                                                <Field
                                                                                                    name={`${question}.marks`}
                                                                                                    disable={initialValue?.status === "Published" || false}
                                                                                                    component={Input}
                                                                                                    validate={required}
                                                                                                    parse={value => Number(value)}
                                                                                                    placeholder="Enter Marks"
                                                                                                />
                                                                                            </div>


                                                                                        }

                                                                                    </Fragment>



                                                                                }
                                                                            </div>
                                                                            <WhenFieldChanges
                                                                                field="scorable"
                                                                                becomes="Yes"
                                                                                set={`${question}.no_of_subparts`}
                                                                                to="0"
                                                                            />


                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </FieldArray>
                                                    </div>

                                                ))
                                            }
                                        </FieldArray>
                                        <div className="row mt-5" id="files-section"  >
                                            <div className="col-md-6 col-lg-6 col-sm-12" >
                                                <div className="input-field" >
                                                    <label>Question Sheet [PDF File] </label>
                                                    <Field
                                                        name="question_sheet"
                                                        component={FileUploader}
                                                        validate={required}
                                                        accept="application/pdf"
                                                        helpBlock='Question Sheet should be in PDF format'
                                                    // disable={initialValue?.status==="Published"||false}
                                                    />
                                                </div>
                                                {initialValue && initialValue?.question_sheet !== "" && !isNewRevision &&
                                                    <a href={`${API.OPENDOOR_URL}${initialValue?.question_sheet}`} className="pdf-field" target="_blank" >
                                                        <IconButton appearance="ghost" icon={<Icon icon="file-pdf-o" />}>Download Question Sheet</IconButton>
                                                    </a>}
                                            </div>
                                            <div className="col-md-6 col-lg-6 col-sm-12" >
                                                <div className="input-field" >
                                                    <label>Answer Sheet [PDF File]</label>
                                                    <Field
                                                        name="answer_sheet"
                                                        component={FileUploader}
                                                        validate={required}
                                                        accept="application/pdf"
                                                        helpBlock='Answer Sheet should be in PDF format'
                                                    //  disable={initialValue?.status==="Published"||false}
                                                    />
                                                </div>
                                                {initialValue && initialValue?.answer_sheet !== "" && !isNewRevision &&
                                                    <a href={`${API.OPENDOOR_URL}${initialValue?.answer_sheet}`} className="pdf-field" target="_blank" >
                                                        <IconButton appearance="ghost" icon={<Icon icon="file-pdf-o" />}>Download Answer Sheet</IconButton>
                                                    </a>}
                                            </div>
                                        </div>
                                    </div>
                                }


                            </div>
                        </form>
                    )
                }}
            </Form>
        </div>
    );
}





WorksheetForm.propTypes = {

    title: PropTypes.string,
    handleFormSubmit: PropTypes.func,
    initialValue: PropTypes.object,
    setIsValid: PropTypes.func,
    error: PropTypes.string,
    subjectOptions: PropTypes.array
}


export default WorksheetForm;
