import React from 'react';
import {Form,Field} from 'react-final-form'
import {Input,Radio} from "../../components/form"
import PropTypes from "prop-types"
import {required,composeValidators} from "../../components/form/Validations"


function StudentQueryForm(props){
    const {handleFormSubmit,setIsValid,disable,initialValue,error}=props


    return (
        <div className="add-form box">
            <Form 
            onSubmit={handleFormSubmit}
            initialValues={initialValue}
            >
                {({handleSubmit,valid,submitError,submitting,pristine})=>{
                    console.log("Valid: ",valid)
                    setIsValid(submitting || pristine)
                    return(
                    <form id="AddStudentQueryForm"   onSubmit={handleSubmit}  >
                     <div className="row">
                         <div className="col-md-4 col-lg-4 col-sm-12" >
                             <div className="input-field" >
                             <label>Student Name</label>
                    <Field placeholder="Student Name" 
                    disable={disable}
                    readOnly
                    validate={required}
                     name="student_name" component={Input} />
                             </div>
                         </div>
                         <div className="col-md-4 col-lg-4 col-sm-12" >
                           <div className="input-field" >
                               <label>Student Email ID</label>
                               <Field 
                         placeholder="Student Email ID" 
                    disable={disable}
                    readOnly
                    validate={required}
                     name="student_email" component={Input} />
                            </div>  
                        
                         </div>
                         <div className="col-md-4 col-lg-4 col-sm-12" >
                         <div className="input-field" >
                          <label>Status</label>
                          <Field
                          name="status"
                          component={Radio}
                          validate={required}
                          radioData={[
                              {label:"Pending",value:"Pending"},
                              {label:"Closed",value:"Closed"},
                          ]}
                          />  
                             
                    </div>
                         </div>
                    </div>   

                    <div className="row" >
                    <div className="col-md-6 col-lg-6 col-sm-12">
                
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12"></div>
                    </div>

                         
                    <div className="input-field" >
                      <label>Query</label> 
                      <Field
                        component={Input}
                        componentClass="textarea"
                        name="message"
                        readOnly
                        placeholder="Write the Answer"
                        validate={required}
                        rows={3}
                        />   
                    </div>
                    <div className="input-field" >
                      <label>Admin Comments {' '}<strong>(Not sent to student)</strong></label> 
                      <Field
                        component={Input}
                        componentClass="textarea"
                        name="admin_comments"
                        placeholder="Write the Answer"
                        
                        rows={3}
                        />   
                    </div>
           
                    {submitError&&<p className="error-msg">{submitError}</p>}
                </form>
                )}}
            </Form>
        </div>
    );
}





StudentQueryForm.propTypes={
    
    title:PropTypes.string,
    handleFormSubmit:PropTypes.func,
    initialValue:PropTypes.object,
    setIsValid:PropTypes.func,
    error:PropTypes.string
}


export default StudentQueryForm;
