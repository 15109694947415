import * as actionTypes from "../actionTypes"
import {updateObject} from "./utility"




const initialState={
   worksheets:[],
   currentIndex:null,
   currentWorksheet:null,
   worksheetId:null,
   evaluationId:null,
}



const updateWorksheet=(action,state)=>{
    return updateObject(state,{
      worksheets:action.payload.data
    })
}

const nextWorksheet=(action,state)=>{
    return updateObject(state,{
        currentIndex:state.currentIndex+1<=state.worksheets.length?state.currentIndex+1:state.currentIndex
        // currentIndex:state.currentIndex+1
    })
}

const previousWorksheet=(action,state)=>{
    return updateObject(state,{
        currentIndex:state.currentIndex-1>=0?state.currentIndex-1:state.currentIndex
        // currentIndex:state.currentIndex-1
    })
}

const setCurrentWorksheetIndex=(action,state)=>{
    return updateObject(state,{
        currentIndex:action.payload.index
    })
}

const setCurrentWorksheet=(action,state)=>{
    return updateObject(state,{
        currentWorksheet:state.worksheets[state.currentIndex]
    })
}


const setEvaluationId=(action,state)=>{
    return updateObject(state,{
        evaluationId:state.currentWorksheet.id
    })
}

const setWorksheetId=(action,state)=>{
    return updateObject(state,{
        worksheetId:state.currentWorksheet.worksheet_revision.id
    })
}



const TeachersReducer=(state=initialState,action)=>{
    switch (action.type){
        case actionTypes.UPDATE_TEACHERS_WORKSHEETS:return updateWorksheet(action,state);
        case actionTypes.NEXT_WORKSHEET:return nextWorksheet(action,state);
        case actionTypes.PREVIOUS_WORKSHEET:return previousWorksheet(action,state);
        case actionTypes.SET_WORKSHEET_CURRENT_INDEX:return setCurrentWorksheetIndex(action,state);
        case actionTypes.SET_CURRENT_WORKSHEET:return setCurrentWorksheet(action,state)
        case actionTypes.SET_EVALUATION_ID:return setEvaluationId(action,state)
        case actionTypes.SET_WORKSHEET_ID:return setWorksheetId(action,state)
       
        default:
            return state
    }
}



export default TeachersReducer