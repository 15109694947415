import React from "react"
import { BiSpreadsheet,BsFileEarmarkSpreadsheet,FcFaq,BiHelpCircle,BsChatDots,GiTeacher,RiNotification2Line,
    IoSchoolOutline,GiBlackBook,VscBook,VscFileSubmodule,RiDashboardLine,FiLayers,FiBook,FaChalkboardTeacher,
    RiUserSettingsLine,FiLink2,BsImages,HiOutlineDocumentReport,RiCouponLine
} from "react-icons/all"

export const NestedMenu = [
    {
        title: "Dashboard",
        path: "/",
        icon: <RiDashboardLine size={22} />,
        id: "1",
        menu: []
    },
    {
        title: 'Master',
        path:null,
        icon:<FiLayers size={22}/>,
        id:"2",
        menu:[
            {
                title: "Board",
                path: "/board",
                icon: <IoSchoolOutline size={22} />,
                id: "3",
                menu: []
            },
            {
                title: "Grade",
                path: "/grade",
                icon: <GiBlackBook size={22} />,
                id: "4",
                menu: [
                    
                ]
            },
            {
                title: "Subject",
                path: "/subject",
                icon: <VscBook size={22} />,
                id: "5",
                menu: [
                    
                ]
            },
            {
                title: "Topic",
                path: "/topic",
                icon: <VscFileSubmodule size={22} />,
                id: "6",
                menu: [
                    
                ]
            },
            {
                title: "Modules",
                path: "/modules",
                icon: <FiBook size={22} />,
                id: "7",
                menu: [
                ]
            },
            {
                title: "Worksheet",
                path: "/all-worksheet",
                icon: <BsFileEarmarkSpreadsheet size={22} />,
                id: "8",
                menu: [
                ]
            },
        ]
    },
    {
        title: "Teachers",
        path: "/teacher",
        icon: <GiTeacher size={22} />,
        id: "9",
        menu: []
    },
    {
        title: "Submitted Worksheets",
        path: "/submitted-worksheets",
        icon: <BiSpreadsheet size={22} />,
        id: "10",
        menu: []
    },
    {
        title: "Coupons",
        path: "/coupon",
        icon: <RiCouponLine size={22} />,
        id: "18",
        menu: []
    },
 
    {
        title: "FAQs",
        path: "/faq",
        icon: <BiHelpCircle size={22} />,
        id: "11",
        menu: []
    },
    {
        title: "Write to Us",
        path: "/student-query",
        icon: <BsChatDots size={22} />,
        id: "12",
        menu: []
    },
    {
        title: "Custom Notification",
        path: "/custom-notification",
        icon: <RiNotification2Line size={22} />,
        id: "13",
        menu: []
    },
    {
        title: "Student Management",
        path: "/student-management",
        icon: <RiUserSettingsLine size={22} />,
        id: "14",
        menu: []
    },
    {
        title: "Reports",
        path: "/reports",
        icon: <HiOutlineDocumentReport size={22} />,
        id: "15",
        menu: []
    },
    {
        title: "About Us",
        path: "/about-us",
        icon: <FiLink2 size={22} />,
        id: "16",
        menu: [
            {
                title: "Images",
                path: "/about-us/images",
                icon: <BsImages size={22} />,
                id: "17",
                menu: []
            },
        ]
    },

]