import React from 'react'
import Lottie from 'react-lottie';
import PropTypes from "prop-types"
import Loader from "../../data/loader4.json"
export default function Animation({json,height,width,loop,caption}) {


    const  defaultOptions=(data)=>{
        return {
            loop:loop,
            autoplay:true,
            animationData:data,
            background:"transparent",
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
              }
        }
       
    }

    return (
        <div className="custom-animation" >
           <Lottie height={height} width={width} options={defaultOptions(json?json:Loader)} id="lottie" />
           <p>{caption}</p>
        </div>
    )
}


Animation.propTypes={
    json:PropTypes.any,
    height:PropTypes.any,
    width:PropTypes.any,
    loop:PropTypes.bool,
    caption:PropTypes.string
}
