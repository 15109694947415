import * as actionTypes from "../actionTypes"


export const updateTeachersWorksheet=(data)=>({
    type:actionTypes.UPDATE_TEACHERS_WORKSHEETS,
    payload:{data}
})

export const nextWorksheet=()=>({
    type:actionTypes.NEXT_WORKSHEET,
})

export const previousWorksheet=()=>({
    type:actionTypes.PREVIOUS_WORKSHEET,
})

export const setCurrentWorksheetIndex=(index)=>({
    type:actionTypes.SET_WORKSHEET_CURRENT_INDEX,
    payload:{index}
})


export const setCurrentWorksheet=()=>({
    type:actionTypes.SET_CURRENT_WORKSHEET
})


export const setEvaluationId=()=>({
    type:actionTypes.SET_EVALUATION_ID
})


export const setWorksheetId=()=>({
    type:actionTypes.SET_WORKSHEET_ID
})


export const changeFilterData=(data)=>({
    type:actionTypes.CHANGE_FILTER_DATA,
    payload:{data}
})