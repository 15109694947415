//@author:Himanshu Barmola
//Project: OpenDoor Sheets Admin Panel


// Customizing the Input field for Redux-Form

import React from "react"
import { Input,HelpBlock } from "rsuite"
export const renderDropdown = (props) => {
    return (
        <div className="input-field">
            <Input
                {...props.input}
                id={props.id}
                placeholder={props.placeholder}
                value={props.input.value}
                name={props.input.name}
                disabled={props.disable}
                block="true"
                size={props.size?props.size:"lg"}
                type={props.isPassword?"password":"text"}
                readOnly={props.readOnly}
                componentClass={props.componentClass}
                rows={props.rows}
                className={props.meta.touched && (props.meta.error|| props.meta.submitError) != undefined ? `error-field ${props.class}` : props.class}
            />
            {props.helpBlock&&<HelpBlock>{props.helpBlock}</HelpBlock>}
            {props.meta.touched&&(props.meta.error|| props.meta.submitError)&&<p className="error-msg" >{(props.meta.error|| props.meta.submitError)}</p>}
        </div>
    )
}



export default renderDropdown