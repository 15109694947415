//@author:Himanshu Barmola
//Project: OpenDoor Sheets Admin Panel


// Customizing the Input field for Redux-Form

import React from "react"
import { InputGroup,Input } from "rsuite"
export const renderInput = (props) => {
    const {icon:Icon}=props
    return (
        <div className="input-field">
            <InputGroup inside >
           <InputGroup.Addon>
            <Icon size={24} />
            </InputGroup.Addon>
            <Input
           
                {...props.input}
                placeholder={props.placeholder}
                value={props.input.value}
                name={props.input.name}
                type={props.input.type}
                maxLength={props.maxLength}
                minLength={props.minLength}
                type={props.isPassword?"password":"text"}
                val
                block
                size="lg"
                className={props.meta.touched && (props.meta.error|| props.meta.submitError) != undefined ? "input-icon error-field" : 'input-icon'}
            />
            </InputGroup>
            {props.meta.touched&&(props.meta.error|| props.meta.submitError)&&<p className="error-msg" >{(props.meta.error|| props.meta.submitError)}</p>}
        </div>
    )
}



export default renderInput