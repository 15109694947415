import React from 'react';
import PropTypes from "prop-types"
import {BiArrowBack} from 'react-icons/all'
import {IconButton,Icon,Button} from "rsuite"
import history from "../../helpers/history"
import {connect} from "react-redux"
import * as action from '../../redux/creators/masters.creator'
import {Breadcrumb} from "../index"
import {photos} from "../../constants/images"
import {theme} from "../../constants"
import styled from "styled-components"
import CompanianNavbar from "./CompanianNavbar"
const CompanianHeader = ({title,authorized,
                        buttonOnClick,rsicon,
                        icon:RIcon,buttonName,
                        buttonAppearance,iconSize,
                        loading,buttonType,
                        disable,caption,
                        secondaryBtnName,secondaryBtnIcon,
                        secondaryBtnLoading,secondaryBtnAppearance,
                        secondaryBtnIconSize,secondaryBtnDisable,
                        secondaryBtnType,secondaryBtnFunc,
                        primaryBtnColor,secondaryBtnColor,iconPlacement,backClicked,triggerBackEvent,
                        navbarData,navbarIndex,setNavbarIndex,backFunction
                    }) => {

    const SeconaryButton=styled.div`
             .rs-btn{
                border-color:${secondaryBtnColor} !important;
                color:${secondaryBtnAppearance=='primary'?'#fff':secondaryBtnColor} !important;
                background-color:${secondaryBtnAppearance=='primary'?secondaryBtnColor:undefined} !important;
                svg{
                    color:${secondaryBtnAppearance=='primary'?'#fff':secondaryBtnColor} !important;
                }
                i{
                    background-color:${secondaryBtnAppearance=='primary'?secondaryBtnColor:undefined} !important;  
                }
             }           
    `


    function handleBackButton(){
        if(triggerBackEvent){
            backClicked(true)
        }else if(backFunction){
            backFunction()
        }else{
            history.goBack()
        }
    }



    return (
        <div className="companian">
             <Breadcrumb />
            <div className="companian__box">
                <div className="companian__box__left" >      
                <div className="companian__box__left__header" >
                    <div className="companian__box__left__header__jumbo" >
                    <BiArrowBack size={30} onClick={handleBackButton}  />
                    <h1>{title}</h1>
                    </div>
                    {caption&&<div className="companian__box__left__header__caption" >
                    <p>{caption}</p>
                    </div>}
                </div>     
                </div>
                {authorized&&<div className="companian__box__right" >
                    {(secondaryBtnName)&&
                    <SeconaryButton>
                         <IconButton 
                         className={secondaryBtnAppearance == 'subtle'?'subtle-btn':''}
                         size="lg" 
                         appearance={secondaryBtnAppearance?secondaryBtnAppearance:"ghost"} 
                         icon={<Icon icon={secondaryBtnIcon} />} 
                         loading={secondaryBtnLoading}
                         disabled={secondaryBtnDisable}
                         color={secondaryBtnAppearance}
                         onClick={secondaryBtnFunc}
                         type={secondaryBtnType}
                         id="secondary-btn"
                         >{secondaryBtnName}</IconButton>
                    </SeconaryButton>}
                    {buttonOnClick&&<div>
                        {rsicon?
                        <IconButton
                        id="primary-btn"
                        type={buttonType} 
                        onClick={buttonOnClick}
                        loading={loading}
                        disabled={disable}
                        placement={iconPlacement?iconPlacement:"left"}
                        appearance={buttonAppearance?buttonAppearance:'primary'} 
                        color={buttonAppearance}
                        icon={<Icon icon={rsicon} />} 
                         size="lg" > 
                         {buttonName?buttonName:`Add ${title.slice(0,title.length-1)}`} 
                         </IconButton>:
                         <Button 
                         type={buttonType}
                         disabled={disable}
                         size="lg"
                         onClick={buttonOnClick}
                         loading={loading}
                         appearance={buttonAppearance?buttonAppearance:'primary'}
                         >
                             {RIcon&&<RIcon size={iconSize} />}
                         {buttonName?buttonName:`Add ${title.slice(0,title.length-1)}`}    
                         </Button>
                         }
                    </div>}
                </div>}
                <div className="companian__box__banner" >
                <img src={photos.companianBanner} />

                </div>
            </div>
            {navbarData&&
            <CompanianNavbar
            navbarData={navbarData}
            navbarIndex={navbarIndex}
            setNavbarIndex={setNavbarIndex}
            />}
        </div>
    );
}



CompanianHeader.propTypes={
    title:PropTypes.string.isRequired,
    authorized:PropTypes.bool,
    buttonOnClick:PropTypes.func,
    rsicon:PropTypes.string,
    icon:PropTypes.any,
    loading:PropTypes.bool,
    buttonAppearance:PropTypes.string,
    buttonName:PropTypes.string,
    iconSize:PropTypes.number,
    buttonType:PropTypes.string,
    disable:PropTypes.bool,
    caption:PropTypes.string,
    secondaryBtnName:PropTypes.string,
    secondaryBtnIcon:PropTypes.any,
    secondaryBtnLoading:PropTypes.bool,
    secondaryBtnAppearance:PropTypes.string,
    secondaryBtnIconSize:PropTypes.number,
    secondaryBtnDisable:PropTypes.bool,
    secondaryBtnType:PropTypes.string,
    secondaryBtnFunc:PropTypes.func,
    primaryBtnColor:PropTypes.string,
    secondaryBtnColor:PropTypes.string,
    iconPlacement:PropTypes.string,
    backClicked:PropTypes.func,
    triggerBackEvent:PropTypes.bool,
    navbarData:PropTypes.array,
    navbarIndex:PropTypes.number,
    setNavbarIndex:PropTypes.func,
    backFunction:PropTypes.func
}


const mapStateToProps=state=>{
    return{
    }
}


const mapDispatchToProps=dispatch=>{
    return{
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CompanianHeader);
