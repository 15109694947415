import React from 'react';
import {IconButton,Icon} from "rsuite"
const Pagination = ({pageIndex,pageCount,pageOptions,gotoPage,previousPage,nextPage,canPreviousPage,canNextPage}) => {
    return (
        <div className="pagination">
           <span>Page{' '} <strong>{pageIndex+1} of {pageCount?Math.ceil(pageCount/10) : pageOptions.length} </strong> </span>
               <IconButton icon={<Icon icon="angle-double-left" /> } onClick={()=>gotoPage(1)}  size="xs" disabled={!canPreviousPage} />
               <IconButton icon={<Icon icon="angle-left" /> } onClick={previousPage}  size="xs" disabled={!canPreviousPage} />
               <IconButton icon={<Icon icon="angle-right" /> } onClick={nextPage}  size="xs" disabled={!canNextPage} />
               <IconButton icon={<Icon icon="angle-double-right" /> } onClick={()=>gotoPage(Math.ceil(pageCount/10))} size="xs" disabled={!canNextPage} /> 
        </div>
    );
}

export default Pagination;
