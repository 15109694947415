import React,{useEffect,useState} from 'react';
import {photos} from "../../constants/images"
import {Form,Field} from "react-final-form"
import {connect} from "react-redux"
import {InputIcon,PasswordInput} from "../../components/form"
import {HiOutlineMail} from "react-icons/all"
import {Button} from "rsuite"
import {email,required,composeValidators} from "../../components/form/Validations"
import {useMutation} from 'react-query'
import {history,apiRequest,getErrors} from "../../helpers"
import * as API from '../../api/API'
import * as action from '../../redux/creators/auth.creator'
import {FORM_ERROR} from 'final-form'
import jwt_decode from 'jwt-decode'
import setAuthToken from "../../helpers/setAuthToken"

function ForgotPassword({dispatch}){
    const {isLoading,mutateAsync,data}=useMutation((data)=>apiRequest('POST',API.PASSWORD_RESET,data,null))
    const [passwordSuccessMsg,setPasswordSuccessMsg]=useState(null)

    const handleForgotPassword=(values)=>{
        return mutateAsync(values)
        .then((res)=>{
            setPasswordSuccessMsg('If your email is registered with us. Then you will get an email to reset your password!')

        })
        .catch((err)=>{
            return{
                email:getErrors(err,'email'),
                [FORM_ERROR]:getErrors(err,'detail') || getErrors(err,'msg'),
            }
        })
    }




    return (
        <div className="login-container" >
            <div className="login-container__form" >
                <div className="login-container__form__logo" >
                <img src={photos.logo} />
                </div>
                <Form onSubmit={handleForgotPassword} >
                 {({handleSubmit,submitting,pristine,submitError})=>{
                     return(
                    <form onSubmit={handleSubmit} >
                        <div className="input-field" >
                        <Field  component={InputIcon}  name="email" type="email" placeholder="Enter your Email"  icon={HiOutlineMail} validate={composeValidators(required,email)}  />
                        </div>
                        {submitError&&
                        <div className="error-field">
                           <p className="heading">{submitError}</p> 
                        </div>}
                        {passwordSuccessMsg&&
                           <div className="success-msg">
                           <p className="heading">{passwordSuccessMsg}</p> 
                        </div> 
                        }
                        <div className="button-field">
                        <Button appearance="primary" size="lg" type="submit" disabled={submitting || pristine} block loading={isLoading}   >Forgot Password</Button>
                        </div>
                      
                    </form>
                     )
                 }}   
           
            </Form>
            {/* <div className="login-container__form__caption" >
            <p>ForgotPassword with your credentials in admin panel to view and manage the worksheets.</p>
            </div> */}
            </div>
        </div>
    );
}

const mapStateToProps=state=>{
    return {

    }
}

export default connect(mapStateToProps,null)(ForgotPassword)
