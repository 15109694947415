export const UPDATE_BOARD_LIST=     "UPDATE_BOARD_LIST"
export const UPDATE_GRADE_LIST= "UPDATE_GRADE_LIST"
export const UPDATE_SUBJECT_LIST="UPDATE_SUBJECT_LIST"
export const UPDATE_TOPIC_LIST="UPDATE_TOPIC_LIST"
export const UPDATE_MODULE_LIST="UPDATE_MODULE_LIST"


export const UPDATE_NEW_USER="UPDATE_NEW_USER";
export const SET_CURRENT_USER ="SET_CURRENT_USER"
export const LOGOUT_USER="LOGOUT_USER";


export const UPDATE_TEACHERS_WORKSHEETS="UPDATE_TEACHERS_WORKSHEETS"

export const NEXT_WORKSHEET="NEXT_WORKSHEET";
export const PREVIOUS_WORKSHEET="PREVIOUS_WORKSHEET"
export const SET_WORKSHEET_CURRENT_INDEX="SET_WORKSHEET_CURRENT_INDEX"

export const SET_CURRENT_WORKSHEET="SET_CURRENT_WORKSHEET"

export const SET_WORKSHEET_ID="SET_WORKSHEET_ID"
export const SET_EVALUATION_ID="SET_EVALUATION_ID"

export const UPDATE_STUDENTS_LIST="UPDATE_STUDENTS_LIST"

export const SET_NAVBAR_INDEX="SET_NAVBAR_INDEX"


export const CHANGE_FILTER_DATA="CHANGE_FILTER_DATA"


export const STORE_XFDF_STRING= 'STORE_XFDF_STRING'