//@author:Himanshu Barmola
//Project: OpenDoor Sheets Admin Panel


// Customizing the Input field for Redux-Form

import React from "react"
import { Uploader,HelpBlock } from "rsuite"
export const RenderFileUploader = (props) => {
    return (
        <div className="input-field" >
            <Uploader  
            draggable
            {...props.input}
            placeholder={props.placeholder}
            value={props.input.value}
            name={props.input.name}
            disabled={props.disable}
            block="true"
            size="lg"
            multiple={false}
            accept={props.accept}
            componentClass={props.componentClass}
            rows={props.rows}
            className={props.meta.touched && (props.meta.error|| props.meta.submitError) != undefined ? `error-field ${props.class}` : props.class}
            >
        <div style={{lineHeight:"200px"}}>Click or Drag files to this area to upload</div>
        </Uploader>
        {props.helpBlock&&<HelpBlock>{props.helpBlock}</HelpBlock>}
            {props.meta.touched&&(props.meta.error|| props.meta.submitError)&&<p className="error-msg" >{(props.meta.error|| props.meta.submitError)}</p>}
        </div>
        
    )
}



export default RenderFileUploader