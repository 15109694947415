function convertToFile(url,callback,label){
    const httpsUrl=url.replace(/^http:\/\//i, 'https://');
    const name=httpsUrl.split('/')[url.split('/').length-1]
    fetch(httpsUrl)
    .then(async response => {
      const contentType = response.headers.get('content-type')
      const blob = await response.blob()
      const file = new File([blob], name, { contentType })
        callback(file)
    })
}



export default convertToFile