import React from 'react'
import PropTypes from 'prop-types'
import {Table} from "..";
import {COL} from "../../columns/EvaluationReportList"


function _EvaluationReport({data,loading}) {


    return (
        <div>
            <Table
            isPaginated={false}
            columns={COL}
            columnOrder={['student_name','board','grade','submitted_date','evaluated_date','evaluated_by','status_shared','evaluated_pdf']}
            explicitData={data?.evaluation}
            hideActionButton
            loading={loading}
            />
        </div>
    )
}




_EvaluationReport.propTypes={
    data:PropTypes.object,
    loading:PropTypes.bool
}



export default _EvaluationReport

