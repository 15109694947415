//@author:Himanshu Barmola
//Project: OpenDoor Sheets Admin Panel


// Customizing the Input field for Redux-Form

import React from "react"
import { TagPicker,Icon } from "rsuite"
export const renderInputPicker = (props) => {
    let fetchedData=[]
    if(props.input.value){
        let temp=props.input.value;
        for(let key in temp){
            let data={
                id:key,
                label:temp[key]
            }
            fetchedData.push(data)
        }
    }
    return (
        <div className="input-field">
            <TagPicker
                {...props.input}
                data={props.options}
                placeholder={props.placeholder}
                value={props.input.value}
                name={props.input.name}
                disabled={props.disable}
                labelKey={props.labelKey}
                defaultValue={props.input.value}
                cleanable={false}
                searchable={props.searchable}
                data={fetchedData}
                valueKey="label"
                placement="bottomStart"
                creatable
                block="true"
                size="lg"
                className={props.meta.touched && (props.meta.error|| props.meta.submitError) != undefined ? "error-field p-0" : undefined}
            />
            {props.meta.touched&&(props.meta.error|| props.meta.submitError)&&<p className="error-msg" >{(props.meta.error|| props.meta.submitError)}</p>}
        </div>
    )
}



export default renderInputPicker