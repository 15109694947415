import React from 'react';
import {Form,Field} from 'react-final-form'
import {ImageUploader} from "../../components/form"
import PropTypes from "prop-types"
import {required,composeValidators} from "../../components/form/Validations"


function UploadImageForm(props){
    const {handleFormSubmit,setIsValid,disable,initialValue,error}=props


    return (
        <div className="add-form box">
            <Form 
            onSubmit={handleFormSubmit}
            initialValues={initialValue}
            >
                {({handleSubmit,valid,submitError,submitting,pristine})=>{
                    console.log("Valid: ",valid)
                    setIsValid(submitting || pristine)
                    return(
                    <form id="uploadImageForm"   onSubmit={handleSubmit}  >
                    <label>Upload Image</label>
                    <Field 
                    disable={disable}
                    validate={composeValidators(required)} 
                    name="image" 
                    component={ImageUploader} />
                    {submitError&&
                    <p className="error-msg">{submitError}</p>}
                </form>
                )}}
            </Form>
        </div>
    );
}





UploadImageForm.propTypes={
    
    title:PropTypes.string,
    handleFormSubmit:PropTypes.func,
    initialValue:PropTypes.object,
    setIsValid:PropTypes.func,
    error:PropTypes.string
}


export default UploadImageForm;
