import React ,{useRef}from 'react';
import {Form,Field} from 'react-final-form'
import {Input,Dropdown,ImageUploader,InputIcon,MultiSelect,TagPicker,ColorPicker} from "../../components/form"
import PropTypes from "prop-types"
import {useQuery} from 'react-query'
import {required,composeValidators,imageRequired,minPrice2} from "../../components/form/Validations"
import {FaRupeeSign} from "react-icons/all"
import {HelpBlock,Button} from 'rsuite'
import {IoAddSharp} from 'react-icons/all'
import history from "../../helpers/history"
import numeral from 'numeral'
const ModuleForm = ({handleFormSubmit,
                    setIsValid,
                    disable,
                    initialValue,
                    error,
                    isReq,
                    boardData,
                    subjectData,
                    gradeData,
                    topicData,
                    setSubjects
                }) => {
            const containerRef=useRef()        
            const formatPrice = value =>
            value === undefined
              ? '' // make controlled
              : numeral(value).format('0,0.00')
    return (
        <div className="add-form box">
        <Form 
        onSubmit={handleFormSubmit}
        initialValues={initialValue}
        >
            {({handleSubmit,valid,submitError,submitting,pristine,values})=>{
                console.log("Valid: ",valid)
                setIsValid(submitting || pristine)
                if(values.subjects){
                    console.log("Subject Values:",values.subjects)
                    setSubjects(values.subjects)
                }
                return(
                <form id="addModuleForm"   onSubmit={handleSubmit}  >
                    <div className="input-field" >
                        <label>Name</label>
                        <Field  
                        component={Input}
                        placeholder="Enter Module Name"
                        name="name"
                        validate={required}
                        />  
                    </div>
                  <div className="row" >
                        <div className="col-md-6 col-lg-6 col-sm-12" >
                        <div className="input-field" src={containerRef}  >
                        <label>Board</label>
                        <Field  
                        component={MultiSelect}
                        options={boardData||[]}  
                        placeholder="Select Board"
                        name="boards"
                        labelKey="name"
                        valueKey="id"
                        validate={required}
                        container={containerRef.current}
                        />
                        {!initialValue&&<div className="help-block" >
                        <HelpBlock>Board not available?</HelpBlock>
                        <Button appearance="subtle" 
                        onClick={()=>history.push({pathname:'/board/add-board',state:{location:'/modules/add-module'}})}
                         size="sm">
                            <IoAddSharp size={22} /> Add Board</Button>
                         </div>}  
                        </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-12" >
                        <div className="input-field" >
                        <label>Grade</label>
                        <Field
                        options={gradeData||[]}  
                        component={MultiSelect}  
                        placeholder="Select Grade"
                        name="grades"
                        labelKey="name"
                        valueKey="id"
                        validate={required}
                        />
                          {!initialValue&& <div className="help-block" >
                        <HelpBlock>Grade not available?</HelpBlock>
                        <Button appearance="subtle" 
                        onClick={()=>history.push({pathname:'/grade/add-grade',state:{location:'/modules/add-module'}})}
                         size="sm">
                            <IoAddSharp size={22} /> Add Grade</Button>
                         </div>}   
                        </div>
                     
                        </div>        
                </div>
                <div className="row" >
                        <div  className="col-md-6 col-lg-6 col-sm-12" >
                        <div className="input-field" >
                        <label>Subject</label>
                        <Field  
                        options={subjectData||[]}
                        component={MultiSelect}  
                        placeholder="Select Subject"
                        name="subjects"
                        labelKey="name"
                        valueKey="id"
                        // validate={required}
                        />
                          {!initialValue&&<div className="help-block" >
                        <HelpBlock>Subject not available?</HelpBlock>
                        <Button appearance="subtle" 
                        onClick={()=>history.push({pathname:'/subject/add-subject',state:{location:'/modules/add-module'}})}
                         size="sm">
                            <IoAddSharp size={22} /> Add Subject</Button>
                         </div>}   
                        </div>
                        </div>
                        <div  className="col-md-6 col-lg-6 col-sm-12" >
                        <div className="input-field" >
                        <label>Topic</label>
                        <Field 
                        options={topicData||[]} 
                        component={MultiSelect}  
                        placeholder="Select Topic"
                        name="topics"
                        loadingMsg="Please select at least one subject"
                        labelKey="name"
                        valueKey="id"
                        validate={required}
                        /> 
                          {!initialValue&&<div className="help-block" >
                        <HelpBlock>Topic not available?</HelpBlock>
                        <Button appearance="subtle" 
                        onClick={()=>history.push({pathname:'/topic/add-topic',state:{location:'/modules/add-module'}})}
                         size="sm">
                            <IoAddSharp size={22} /> Add Topic</Button>
                         </div>}  
                        </div>
                        </div>        
                </div>
                <div className="row" >
                        <div  className="col-md-6 col-lg-6 col-sm-12" >
                        <div className="input-field" >
                        <label>Evaluation Period</label>
                        <Field  
                        component={Input}
                        placeholder="Enter the Evaluation Period"
                        name="evaluation_period"
                        
                        />  
                        </div>
                        </div>
                        <div  className="col-md-6 col-lg-6 col-sm-12" >
                           <div className="row" >
                               <div className="col-md-6 col-lg-6 col-sm-12" >
                               <div className="input-field" >
                        <label>Price</label>
                        <Field  
                        component={InputIcon}
                        icon={FaRupeeSign}
                        type="tel"
                        pattern="^[0-9]*$"
                        maxlength={10}
                            minlength={10}
                        placeholder="Module Price "
                        name="price_inr"
                        // format={formatPrice}
                        // formatOnBlur
                        validate={composeValidators(required,minPrice2)}
                        parse={(value)=>Number(value)}
                        />  
                        </div>
                               </div>
                               <div className="col-md-6 col-lg-6 col-sm-12" >
                                <div className="input-field" >
                                <label>Background Color</label>
                                <Field
                                component={ColorPicker}
                                name="background_color"
                                validate={required}
                                helpBlock="Right Background Color helps UI to look elegant along with the Image"

                                />
                                </div>
                               </div>
                        </div> 
                        
                        </div>        
                </div>
                <div className="row" >
                    <div  className="col-md-6 col-lg-6 col-sm-12">
                        <div className="input-field" >
                        <label>Module Offerings</label>
                        <Field
                        component={TagPicker}
                        name="module_offerings"
                        placeholder="Create Module Offerings"
                        />
                        </div>
                        <div className="input-field" >
                        <label>Description</label>
                        <Field
                        component={Input}
                        componentClass="textarea"
                        name="description"
                        placeholder="Description"
                        rows={3}
                        />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12">
                    <div className="input-field" >
                    <label>Upload Image</label>
                      <Field 
                      component={ImageUploader} 
                      placeholder="Choose" 
                      name="image" 
                      validate={!isReq?undefined:imageRequired}
                      /> 
                    </div>   
                    </div>
                </div>
              
            </form>
            )}}
        </Form>
    </div>
    );
}


ModuleForm.propTypes={
    title:PropTypes.string,
    handleFormSubmit:PropTypes.func,
    initialValue:PropTypes.object,
    setIsValid:PropTypes.func,
    error:PropTypes.string
}

export default ModuleForm;
