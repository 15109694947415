//@author:Himanshu Barmola
//Project: OpenDoor Sheets Admin Panel


// Customizing the Input field for Redux-Form

import React,{useState,useEffect} from "react"
import { InputNumber,HelpBlock } from "rsuite"
import * as _ from 'lodash'
export const RenderInputNumber = (props) => {
    const [currentValue,setCurrentValue]=useState('0')

    const handleInputChange=(value,event)=>{
        console.log("HInpoopoo: ",event,value)
        if(props.formArray){
            props.input.onChange(value)
            if(event.target.className==='rs-icon rs-icon-arrow-up-line'){
                props.pushFn(props.arrayFieldName,{name:"",no_of_questions:1,questions:[{no_of_subparts:0,marks:""}]})
            }else if(event.target.className==="rs-icon rs-icon-arrow-down-line"){
                console.log("Deleting Field Array: ",props.arrayFieldName)
                props.popFn(props.arrayFieldName)
            }
        }else{
            props.input.onChange(value)
        }    
    }



    return (
        <div className="input-field">
            <InputNumber
                {...props.input}
                placeholder={props.placeholder}
                value={props.input.value}
                name={props.input.name}
                disabled={props.disable}
                min={props.min}
                max={props.max}
                readOnly
                onChange={(e,v)=>handleInputChange(e,v)}
                block="true"
                size="lg"
                componentClass={props.componentClass}
                rows={props.rows}
                className={props.meta.touched && (props.meta.error|| props.meta.submitError) != undefined ? `error-field ${props.class}` : props.class}
            />
            {props.helpBlock&&<HelpBlock>{props.helpBlock}</HelpBlock>}
            {props.meta.touched&&(props.meta.error|| props.meta.submitError)&&<p className="error-msg" >{(props.meta.error|| props.meta.submitError)}</p>}
        </div>
    )
}



export default RenderInputNumber