import React, { useState,useEffect } from 'react';
import { Document, Page,pdfjs } from 'react-pdf';
import PropTypes from 'prop-types'
import {AiOutlineLeft,AiOutlineRight,AiOutlineClose} from "react-icons/all"
import {IconButton} from "rsuite"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PDFViewer({file}) {

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);


    useEffect(()=>{
      console.log("File: ",file)
    },[])
  
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }



    const prevPage=()=>{
        if(pageNumber>1){
            setPageNumber((prev)=>--prev)
        }
    }
    
    const nextPage=()=>{
        if(pageNumber<numPages){
            setPageNumber((prev)=>++prev)
        }
    }
  

    return (
        <div className="pdf-viewer" >
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <div className="pdf-viewer__pageChanger" >
                <div className="pdf-viewer__pageChanger__box" >
                <IconButton icon={<AiOutlineLeft/>} onClick={prevPage} disabled={pageNumber==1} />
                <p>Page {pageNumber} of {numPages}</p>
                <IconButton icon={<AiOutlineRight/>} onClick={nextPage} disabled={pageNumber==numPages}  />
                </div>
            </div>
    </div>
    )
}





PDFViewer.propTypes={

}