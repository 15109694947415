import * as _ from 'lodash'

export const getErrors=(err,name)=>{
    if(err.response.status<500){
        try{
            console.log("Error:",err.response)
            if(JSON.parse(err.response.request.response).hasOwnProperty(name)){
                if(_.isArray(JSON.parse(err.response.request.response)[name])){
                    return JSON.parse(err.response.request.response)[name][0]
                }else{
                    return err?.response?.data[name]
                }
            }
        }catch(e){
            console.log("Error Parsing:",e)
        }
    }
    
}