import React,{useState,useEffect} from 'react';
import { Form, Field } from 'react-final-form'
import { Input, Radio, Dropdown } from "../../components/form"
import PropTypes from "prop-types"
import { required, composeValidators, email } from "../../components/form/Validations"
import { photos } from "../../constants/images"
import { useQuery,useMutation } from 'react-query'
import { apiRequest,getErrors } from '../../helpers'
import * as API from "../../api/API"
import {Button,Alert} from 'rsuite'
import {Dialog} from '../../components'


function TeacherForm(props) {
    const { handleFormSubmit, setIsValid, disable, initialValue, error } = props
    const [isResetPassword,setResetPassword]=useState(false)
    const [passwordSuccessMsg,setPasswordSuccessMsg]=useState(null)
    const [passwordErrorMsg,setPasswordErrorMsg]=useState(null)
    const { data: countryList, isLoading: isCountryLoading } = useQuery(['fetchCountryList'], () => apiRequest('GET', API.COUNTRY, null, null),{
        refetchIntervalInBackground:false,
        refetchOnReconnect:false,
        refetchOnMount:false
    })
    const { data: cityList, isLoading: isCityLoading } = useQuery(['fetchCityList'], () => apiRequest('GET', API.CITY, null, null),{
        refetchIntervalInBackground:false,
        refetchOnReconnect:false,
        refetchOnMount:false
    })
    const {mutateAsync:sendPasswordResetLink,isLoading:isPasswordResetLoading}=useMutation((payload)=>apiRequest('POST',API.PASSWORD_RESET,payload,null))


    function handleResetPassword(){
        let data={
            email:initialValue?.email
        }
        sendPasswordResetLink(data)
        .then((res)=>{
            setPasswordSuccessMsg(res?.msg)
        })
        .catch((err)=>{
            setPasswordErrorMsg(getErrors(err,'msg'))
        })
    }

    

    return (
        <div className="add-form box">
            <Form
                onSubmit={handleFormSubmit}
                initialValues={initialValue}
            >
                {({ handleSubmit, valid, submitError, submitting, pristine }) => {
                    console.log("Valid: ", valid)
                    setIsValid(submitting || pristine)
                    return (
                        <form id="addTeacherForm" onSubmit={handleSubmit}>
                            <div className="teacher-form" >
                                <div className="row" >
                                    <div className="col-md-5 col-lg-5 col-sm-12">
                                        <div className="animate__animated animate__fadeInUp animate__faster image" >
                                            <img src={photos.teacher} />
                                        </div>
                                    </div>
                                    <div className="animate__animated animate__fadeInDown animate__faster  col-md-7 col-lg-7 col-sm-12">
                                        <div className="row" >
                                            <div className="col-md-6 col-lg-6 col-sm-12">
                                                <div className="input-field" >
                                                    <label>First Name</label>
                                                    <Field placeholder="Eg. Raghav"
                                                        disable={disable}
                                                        validate={composeValidators(required)} name="first_name" component={Input} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-6 col-sm-12">
                                                <div className="input-field" >
                                                    <label>Last Name</label>
                                                    <Field placeholder="Eg. Kanan"
                                                        disable={disable}
                                                        validate={composeValidators(required)} name="last_name" component={Input} />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row" >
                                            <div className="col-md-6 col-lg-6 col-sm-12">
                                                <div className="input-field" >
                                                    <label>Email ID</label>
                                                    <Field placeholder="Eg. abc@xyz.com"
                                                        disable={disable}
                                                        validate={composeValidators(required, email)} name="email" component={Input}
                                                        helpBlock="This Email ID will be used by Teachers to login into their account."
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-6 col-sm-12">
                                                <div className="input-field">
                                                    <label>Mobile Number</label>
                                                    <Field placeholder="Eg. 1234567890"
                                                        disable={disable}
                                                        validate={composeValidators(required)} name="mobile_no" component={Input} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" >
                                            <div className="col-md-6 col-lg-6 col-sm-12">
                                                <div className="input-field" >
                                                    <label>Country</label>
                                                    <Field
                                                        component={Dropdown}
                                                        options={countryList || []}
                                                        labelKey="name"
                                                        valueKey="id"
                                                        name="country"
                                                        defaultValue={1}
                                                        cleanable={false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-6 col-sm-12">

                                                <div className="input-field" >
                                                    <label>City</label>
                                                    <Field
                                                        component={Dropdown}
                                                        options={cityList || []}
                                                        labelKey="name"
                                                        valueKey="id"
                                                        name="city"
                                                        defaultValue={1}
                                                        cleanable={false}
                                                    />
                                                </div>

                                            </div>

                                        </div>
                                        <div className="row" >
                                            <div className="col-md-6 col-lg-6 col-sm-12">
                                                <div className="input-field" >
                                                    <label>Status</label>
                                                    <Field
                                                        disable={disable}
                                                        radioData={[
                                                            { label: "Active", value: true },
                                                            { label: "Inactive", value: false }
                                                        ]}
                                                        defaultValue={true}
                                                        validate={composeValidators(required)} name="is_active" component={Radio}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-6 col-sm-12">
                                            {initialValue&&<div className="input-field flex mt-4" >
                                              <Button appearance="subtle" onClick={()=>setResetPassword(true)} size="lg" >Reset Password</Button>              
                                            </div>}
                                            </div>

                                        </div>
                                        {submitError && <div className="error-field" >
                                            <img src={photos.error} />
                                            <p className="heading" >{submitError}</p>
                                        </div>}
                                    </div>
                                </div>
                                <Dialog
                                secondaryButtonFunc={()=>setResetPassword(false)}
                                isOpen={isResetPassword}
                                toggle={()=>setResetPassword(false)}
                                secondaryButtonName="No"
                                primaryButtonName="Yes, Reset"
                                primaryButtonAppearance="green"
                                loading={isPasswordResetLoading}
                                successMsg={passwordSuccessMsg}
                                errorMsg={passwordErrorMsg}
                                primaryButtonFunc={handleResetPassword}
                                title="Reset Password"
                                bodyContent={<p>Do you really want to send a reset password email?</p>}
                                />
                            </div>
                        </form>
                    )
                }}
            </Form>
        </div>
    );
}





TeacherForm.propTypes = {

    title: PropTypes.string,
    handleFormSubmit: PropTypes.func,
    initialValue: PropTypes.object,
    setIsValid: PropTypes.func,
    error: PropTypes.string
}


export default TeacherForm;
