
import moment from 'moment'
import {Button,Badge} from 'rsuite'
import {history} from "../helpers"
import {theme} from "../constants"
import {VscFilePdf} from "react-icons/all"
import * as API from "../api/API"
export const COL=[
    {
        Header:'Name',
        Footer:'Name',
        accessor:'student_name',
        Filter:'',

    },
    {
        Header:'Grade',
        Footer:'Grade',
        accessor:'grade',
        Filter:'',

    },
    {
        Header:'Board',
        Footer:'Board',
        accessor:'board',
        Filter:'',

    },
    {
        Header:'Teacher',
        Footer:'Teacher',
        accessor:'teacher_name',
        Filter:'',

    },
    {
        Header:'Submitted Date',
        Footer:'Submitted Date',
        accessor:'submitted_date',
        Filter:'',
        Cell:({value})=>(
            <p>{value?moment(value).format('ll'):'--'}</p>
        )
    },
    {
        Header:'Submitted PDF',
        Footer:'Submitted PDF',
        accessor:'submitted_pdf',
        Filter:'',
        Cell:({value})=>(
            <a href={value} target="_blank" >
                <VscFilePdf style={{color:theme.THEME_PRIMARY_COLOR}} size={24} />
            </a>
        )
    },
]