import React,{useEffect,useState} from 'react';
import {Form,Field} from 'react-final-form'
import {Input,MultiSelect} from "../../components/form"
import PropTypes from "prop-types"
import {required,composeValidators} from "../../components/form/Validations"
import {photos} from "../../constants/images"
import {connect} from 'react-redux'
import {getBoardList,getGradeList,getSubjectList,getModuleList} from "../../redux/actions/masters.action"
import {GlobalColoumnFilter} from "../../components/tables"
import {useQuery} from "react-query"
import * as API from "../../api/API"
import {apiRequest} from "../../helpers"

function AssignModulesForm(props){
    const {handleFormSubmit,setIsValid,disable,initialValue,BOARDS,GRADES,SUBJECTS,
        error,getBoardList,getGradeList,getSubjectList,getModuleList}=props
    const [filter,setFilter]=useState({})
    const {data:MODULES}=useQuery(['fetchModueList',JSON.stringify(filter)],()=>apiRequest('GET',API.MODULE,null,{...filter}),{
        keepPreviousData:true
    })
    // const [boardList,setBoardList]=useState(null)


    useEffect(()=>{
     getBoardList()
     getGradeList()
     getSubjectList()
    },[])


    useEffect(()=>{
        console.log("Table Filter param: ",filter)
    },[JSON.stringify(filter)])

    return (
        <div className="add-form box">
            <Form 
            onSubmit={handleFormSubmit}
            initialValues={initialValue}
            >
                {({handleSubmit,valid,submitError,submitting,pristine})=>{
                    console.log("Valid: ",valid)
                    setIsValid(submitting || pristine)
                    return(
                    <form id="assignModulesForm"   onSubmit={handleSubmit}  >
                        <div className="teacher-form" >
                        <div className="row" >
                        <div className="col-md-5 col-lg-5 col-sm-12">
                            <div className="image" >
                            <img src={photos.assignModules} />
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-7 col-sm-12">
                            <div className="row" >
                            <div className="col-md-4 col-lg-4 col-sm-12" >
                            <div className="input-field" >
                            <label>Board</label>
                            <GlobalColoumnFilter
                              setFilter={setFilter}  
                              preFilteredRows={BOARDS}
                              filterValue={filter}
                              property="name"
                              labelKey="name"
                              valueKey="id"
                              query="boards"
                            />
                            </div>  
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-12" >
                            <div className="input-field" >
                            <label>Grade</label>
                            <GlobalColoumnFilter
                              setFilter={setFilter}  
                              preFilteredRows={GRADES}
                              filter={filter}
                              property="name"
                              labelKey="name"
                              valueKey="id"
                              query="grades"
                            />
                            </div>
                        
                            </div> 
                            <div className="col-md-4 col-lg-4 col-sm-12" >
                            <div className="input-field" >
                            <label>Subjects</label>
                            <GlobalColoumnFilter
                              setFilter={setFilter}  
                              preFilteredRows={SUBJECTS}
                              filter={filter}
                              property="name"
                              labelKey="name"
                              valueKey="id"
                              query="subjects"
                            />
                            </div>
                            </div>
                            </div>
                            <div className="row" >

                            <div className="col-md-12 col-lg-12 col-sm-12">
                            <div className="input-field" >
                            <label>Modules</label>
                    <Field placeholder="Select Modules" 
                    disable={disable}
                    options={MODULES?.results||[]}
                    labelKey="name"
                    valueKey="id"
                    validate={composeValidators(required)} 
                    name="modules_assigned" 
                    component={MultiSelect} />
                            </div>
                            </div>
                            </div>
                            </div>
                        
                    {submitError&&<p className="error-msg">{submitError}</p>}
                        </div>
                        </div>
                
                        
                  
                </form>
                )}}
            </Form>
        </div>
    );
}





AssignModulesForm.propTypes={
    
    title:PropTypes.string,
    handleFormSubmit:PropTypes.func,
    initialValue:PropTypes.object,
    setIsValid:PropTypes.func,
    error:PropTypes.string
}


const mapStateToProps=state=>{
    return{
        BOARDS:state.MasterReducer.boards,
        GRADES:state.MasterReducer.grades,
        SUBJECTS:state.MasterReducer.subjects,
        MODULES:state.MasterReducer.modules,

    }
}


export default connect(mapStateToProps,{
    getBoardList,
    getGradeList,
    getSubjectList,
    getModuleList
})(AssignModulesForm);
