//@author:Himanshu Barmola
//Project: OpenDoor Sheets Admin Panel


// Customizing the Input field for Redux-Form

import React from "react"
import { Input,HelpBlock,RadioGroup,Radio } from "rsuite"
export const RenderRadio = (props) => {
    return (
        <div className="input-field">
            <RadioGroup 
            inline 
            name={props.input.name} 
            value={props.input.value} 
            size="lg"
            
            className={props.meta.touched && (props.meta.error|| props.meta.submitError) != undefined ? `error-field ${props.class}` : props.class}
            {...props.input} 
            >
                {props.radioData&&props.radioData.map((item,idx)=>
                <Radio disabled={props.disable} key={idx} value={item.value}  >{item.label}</Radio>
                )}
            </RadioGroup>
            {props.helpBlock&&<HelpBlock>{props.helpBlock}</HelpBlock>}
            {props.meta.touched&&(props.meta.error|| props.meta.submitError)&&<p className="error-msg" >{(props.meta.error|| props.meta.submitError)}</p>}
        </div>
    )
}



export default RenderRadio