import * as actionTypes from "../actionTypes"


export const updateBoardList=(data)=>({
    type:actionTypes.UPDATE_BOARD_LIST,
    payload:{data}
})

export const updateGradeList=(data)=>({
    type:actionTypes.UPDATE_GRADE_LIST,
    payload:{data}
})

export const updateTopicList=(data)=>({
    type:actionTypes.UPDATE_TOPIC_LIST,
    payload:{data}
})

export const updateSubjectList=(data)=>({
    type:actionTypes.UPDATE_SUBJECT_LIST,
    payload:{data}
})

export const updateModuleList=(data)=>({
    type:actionTypes.UPDATE_MODULE_LIST,
    payload:{data}
})


export const updateStudentList=(data)=>({
    type:actionTypes.UPDATE_STUDENTS_LIST,
    payload:{data}
})


export const updateNavbarIndex=(data)=>({
    type:actionTypes.SET_NAVBAR_INDEX,
    payload:{data}
})

export const storeXfdfString=(data)=>({
    type:actionTypes.STORE_XFDF_STRING,
    payload:{data}
})