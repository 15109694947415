import React from 'react'
import PropTypes from "prop-types"
import { photos } from '../../constants/images'
import { HiOutlinePhone, IoSchoolOutline,SiGoogleclassroom,FaGlobeEurope ,HiOutlineCurrencyRupee,BsCreditCard} from 'react-icons/all'
import { theme } from "../../constants"
import * as API from '../../api/API'
export default function Profile({ name, phone, country, board, grade, amount, paymentMode,image }) {
    return (
        <div className="profile box" >
            <div className="row" >
                <div className="col-md-8 col-lg-8 col-sm-12">
                    <div className="profile__info-box" >
                        <div className="profile__info-box__phone" >
                            <HiOutlinePhone size={30} color={theme.THEME_PRIMARY_COLOR} />
                            <p>{phone}</p>
                        </div>
                        <div className="profile__info-box__name" >
                            <h2>{name}</h2>
                        </div>
                        <div className="row pt-5" >
                            <div className="col-md-4 col-lg-4 col-sm-12">
                                <div className="profile__info-box__detail" >
                                    <div className="row" >
                                        <div className="col-md-3 col-lg-3 col-sm-12">
                                            <IoSchoolOutline size={40} color={theme.THEME_PRIMARY_COLOR} />
                                        </div>
                                        <div className="col-md-9 col-lg-9 col-sm-12">
                                           <div className="profile__info-box__detail__col">
                                               <p className="heading" >{board} </p>
                                               <p className="sub-heading" >Board</p>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-12">
                            <div className="profile__info-box__detail" >
                                    <div className="row" >
                                        <div className="col-md-3 col-lg-3 col-sm-12">
                                            <SiGoogleclassroom size={40} color={theme.THEME_PRIMARY_COLOR} />
                                        </div>
                                        <div className="col-md-9 col-lg-9 col-sm-12">
                                           <div className="profile__info-box__detail__col">
                                               <p className="heading" >{grade} </p>
                                               <p className="sub-heading" >Grade</p>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-12">
                            <div className="profile__info-box__detail" >
                                    <div className="row" >
                                        <div className="col-md-3 col-lg-3 col-sm-12">
                                            <FaGlobeEurope size={40} color={theme.THEME_PRIMARY_COLOR} />
                                        </div>
                                        <div className="col-md-9 col-lg-9 col-sm-12">
                                           <div className="profile__info-box__detail__col">
                                               <p className="heading" >{country} </p>
                                               <p className="sub-heading" >Country</p>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row pt-5" >
                            <div className="col-md-4 col-lg-4 col-sm-12">
                                <div className="profile__info-box__detail" >
                                    <div className="row" >
                                        <div className="col-md-3 col-lg-3 col-sm-12">
                                            <HiOutlineCurrencyRupee size={40} color={theme.THEME_PRIMARY_COLOR} />
                                        </div>
                                        <div className="col-md-9 col-lg-9 col-sm-12">
                                           <div className="profile__info-box__detail__col">
                                               <p className="heading" >{amount} </p>
                                               <p className="sub-heading" >Total Paid</p>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 col-lg-5 col-sm-12">
                            <div className="profile__info-box__detail" >
                                    <div className="row" >
                                        <div className="col-md-3 col-lg-3 col-sm-12">
                                            <BsCreditCard size={50} color={theme.THEME_PRIMARY_COLOR} />
                                        </div>
                                        <div className="col-md-9 col-lg-9 col-sm-12">
                                           <div className="profile__info-box__detail__col">
                                               <p className="heading" >{paymentMode} </p>
                                               <p className="sub-heading" >Payment Mode</p>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-12">
                          
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-lg-4 col-sm-12">
                    <div className="profile__pic" >
                       <img src={image || photos.avatar} /> 
                    </div>
                </div>
            </div>
        </div>
    )
}


Profile.propTypes = {
    name: PropTypes.string,
    phone: PropTypes.string,
    country: PropTypes.string,
    board: PropTypes.string,
    grade: PropTypes.string,
    amount: PropTypes.string,
    paymentMode: PropTypes.string,
    image: PropTypes.string,
}