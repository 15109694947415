import React, { Fragment,lazy,Suspense } from "react"
import { Switch, Redirect,Route } from "react-router-dom"
import {PublicLayout } from "../layouts"
import { ProtectedRoute, SuperAdminRoute, NormalRoute,TeacherRoute } from "../layouts/RouteLayout"
import {Animation} from '../components'
// import Modules from "../views/masters/modules/Modules"

const AuthLayout =lazy(()=>import('../layouts/Auth/AuthLayout'))

const Boards = lazy(() => import("../views/masters/boards/Boards"));
const AddBoard = lazy(() => import("../views/masters/boards/AddBoard"));
const EditBoard = lazy(() => import("../views/masters/boards/EditBoard"));

const Grades = lazy(() => import("../views/masters/grades/Grades"));
const AddGrade = lazy(() => import("../views/masters/grades/AddGrade"));
const EditGrade = lazy(() => import("../views/masters/grades/EditGrade"));

const Subjects = lazy(() => import("../views/masters/subjects/Subjects"));
const AddSubject = lazy(() => import("../views/masters/subjects/AddSubject"));
const EditSubject = lazy(() => import("../views/masters/subjects/EditSubject"));

const Topics = lazy(() => import("../views/masters/topics/Topics"));
const AddTopic = lazy(() => import("../views/masters/topics/AddTopic"));
const EditTopic = lazy(() => import("../views/masters/topics/EditTopic"));

const Modules = lazy(() => import("../views/masters/modules/Modules"));
const CreateModule = lazy(() => import("../views/masters/modules/CreateModule"));
const EditModule = lazy(() => import("../views/masters/modules/EditModule"));

const Worksheet = lazy(() => import("../views/masters/worksheets/Worksheet"));
const AddWorksheet = lazy(() => import("../views/masters/worksheets/AddWorksheet"));
const EditWorksheet = lazy(() => import("../views/masters/worksheets/EditWorksheet"));


const FAQs = lazy(() => import("../views/faq/FAQs"));
const AddFAQs = lazy(() => import("../views/faq/AddFaq"));
const EditFAQs = lazy(() => import("../views/faq/EditFaq"));


const StudentQuery = lazy(() => import("../views/student-query/StudentQuery"));
const EditStudentQuery = lazy(() => import("../views/student-query/EditStudentQuery"));

const Teacher = lazy(() => import("../views/teacher/Teachers"));
const TeacherById = lazy(() => import("../views/teacher/TeacherById"));
const AddTeacher = lazy(() => import("../views/teacher/AddTeacher"));
const EditTeacher = lazy(() => import("../views/teacher/EditTeacher"));
const Permission = lazy(() => import("../views/teacher/Permission"));
const AssignModules = lazy(() => import("../views/teacher/AssignModules"));
const WorksheetAssigned = lazy(() => import("../views/teacher/WorksheetAssigned"));
const WorksheetEvaluated = lazy(() => import("../views/teacher/WorksheetEvaluated"));


const Dashboard = lazy(() => import("../views/dashboard/Dashboard"));
const LoginPage = lazy(() => import("../views/Auth/LoginPage"));
const ResetPassword = lazy(() => import("../views/Auth/ResetPassword"));
const ForgotPassword = lazy(() => import("../views/Auth/ForgotPassword"));
const UpdatePassword = lazy(() => import("../views/Auth/UpdatePassword"));
const CreateAdmin= lazy(() => import("../views/Auth/CreateAdmin"));




const Footer = lazy(() => import("../views/footer/Footer"));
const Success = lazy(()=>import('../container/success/SuccessOne'))
const Unauthorized = lazy(()=>import('../views/Fallback/Unauthorized'))
const PdfEditor = lazy(()=>import('../components/pdf-editor/PdfEditor'))
const PdfRN = lazy(()=>import('../components/pdf-editor/PdfRN'))

const CustomNotification = lazy(()=>import('../views/custom-notification/CustomNotification'))
const SendNotification = lazy(()=>import('../views/custom-notification/SendNotification'))
const EditNotification = lazy(()=>import('../views/custom-notification/EditNotification'))
const Students = lazy(()=>import('../views/students/Students'))
const StudentDashboard = lazy(()=>import('../views/students/StudentDashboard'))
const AboutUs = lazy(()=>import('../views/about-us/AboutUs'))
const Images = lazy(()=>import('../views/about-us/Images'))
const UploadImage = lazy(()=>import('../views/about-us/UploadImage'))
const Reports = lazy(()=>import('../views/reports/Reports'))
const ReportDashboard = lazy(()=>import('../views/reports/ReportDashboard'))

const SubmittedWorksheets = lazy(()=>import('../views/submitted-worksheets/SubmittedWorksheets'))
const Coupon = lazy(()=>import('../views/coupon/Coupon'))
const AddCoupon = lazy(()=>import('../views/coupon/AddCoupon'))
const EditCoupon = lazy(()=>import('../views/coupon/EditCoupon'))




const Routes = () => {
    return (
        <Fragment>
            <Suspense fallback={   <div className="loader" >
                    <Animation
                        height={400}
                        width={400}
                        caption="Assessments made Powerful"
                    />
                </div>} >
            <Switch>
                <ProtectedRoute component={Dashboard} layout={AuthLayout}exact path="/" />
                <NormalRoute component={LoginPage} layout={PublicLayout} exact path="/login" />
                <NormalRoute component={ResetPassword} layout={PublicLayout} exact path="/reset-password" />
                <NormalRoute component={ForgotPassword} layout={PublicLayout} exact path="/forgot-password" />
                <ProtectedRoute component={UpdatePassword} layout={AuthLayout} exact path="/update-password" />
                <Route component={Unauthorized} layout={PublicLayout} exact path="/401" />
                <ProtectedRoute component={Boards} layout={AuthLayout} exact path="/board" />
                <SuperAdminRoute component={AddBoard} layout={AuthLayout} exact path="/board/add-board" />
                <SuperAdminRoute component={EditBoard} layout={AuthLayout} exact path="/board/edit-board" />
                <ProtectedRoute component={Grades} layout={AuthLayout} exact path="/grade" />
                <SuperAdminRoute component={AddGrade} layout={AuthLayout} exact path="/grade/add-grade" />
                <SuperAdminRoute component={EditGrade} layout={AuthLayout} exact path="/grade/edit-grade" />
                <ProtectedRoute component={Subjects} layout={AuthLayout} exact path="/subject" />
                <SuperAdminRoute component={AddSubject} layout={AuthLayout} exact path="/subject/add-subject" />
                <SuperAdminRoute component={EditSubject} layout={AuthLayout} exact path="/subject/edit-subject" />
                 <ProtectedRoute component={Topics} layout={AuthLayout} exact path="/topic" />
                 <SuperAdminRoute component={AddTopic} layout={AuthLayout} exact path="/topic/add-topic" />
                 <SuperAdminRoute component={EditTopic} layout={AuthLayout} exact path="/topic/edit-topic" />
                 <ProtectedRoute component={Modules} layout={AuthLayout} exact path="/modules" />
                 <SuperAdminRoute component={CreateModule} layout={AuthLayout} exact path="/modules/add-module" />
                 <SuperAdminRoute component={EditModule} layout={AuthLayout} exact path="/modules/edit-module" />
                 <ProtectedRoute component={Worksheet} layout={AuthLayout} exact path="/modules/worksheet" />
                 <ProtectedRoute component={Worksheet} layout={AuthLayout} exact path="/all-worksheet" />
                 <SuperAdminRoute component={AddWorksheet} layout={AuthLayout} exact path="/modules/worksheet/add-worksheet"/>
                 <SuperAdminRoute component={EditWorksheet} layout={AuthLayout} exact path="/modules/worksheet/edit-worksheet"/>
                 <ProtectedRoute component={Success} layout={AuthLayout} exact path="/success" />
                 <SuperAdminRoute component={FAQs} layout={AuthLayout} exact path="/faq" />
                 <SuperAdminRoute component={AddFAQs} layout={AuthLayout} exact path="/faq/add-faq" />
                 <SuperAdminRoute component={EditFAQs} layout={AuthLayout} exact path="/faq/edit-faq" />
                 <SuperAdminRoute component={StudentQuery} layout={AuthLayout} exact path="/student-query" />
                 <SuperAdminRoute component={EditStudentQuery} layout={AuthLayout} exact path="/student-query/edit-student-query" />
                 <SuperAdminRoute component={Teacher} layout={AuthLayout} exact path="/teacher" />
                 <SuperAdminRoute component={AddTeacher} layout={AuthLayout} exact path="/teacher/add-teacher" />
                 <SuperAdminRoute component={EditTeacher} layout={AuthLayout} exact path="/teacher/edit-teacher" />
                 <SuperAdminRoute component={Permission} layout={AuthLayout} exact path="/teacher/permission" />
                 <SuperAdminRoute component={AssignModules} layout={AuthLayout} exact path="/teacher/permission/assign-modules" />
                 <SuperAdminRoute component={WorksheetAssigned} layout={AuthLayout} exact path="/teacher/worksheet-assigned" />
                 <SuperAdminRoute component={WorksheetEvaluated} layout={AuthLayout} exact path="/teacher/worksheet-evaluated" />
                 <SuperAdminRoute component={CreateAdmin} layout={AuthLayout} exact path="/create-admin" />
                 <ProtectedRoute component={PdfEditor} layout={AuthLayout} exact path="/load-worksheet" />
                 <NormalRoute component={PdfRN} layout={PublicLayout} exact path="/load-worksheet-rn" />
                 <TeacherRoute component={TeacherById} layout={AuthLayout} exact path="/teacher-dashboard" />
                 <SuperAdminRoute component={CustomNotification} layout={AuthLayout} exact path="/custom-notification" />
                 <SuperAdminRoute component={SendNotification} layout={AuthLayout} exact path="/custom-notification/send-notification" />
                 <SuperAdminRoute component={EditNotification} layout={AuthLayout} exact path="/custom-notification/edit-notification" />
                 <SuperAdminRoute component={Students} layout={AuthLayout} exact path="/student-management" />
                 <SuperAdminRoute component={StudentDashboard} layout={AuthLayout} exact path="/student-management/student-dashboard" />
                 <SuperAdminRoute component={AboutUs} layout={AuthLayout} exact path="/about-us" />
                 <SuperAdminRoute component={Images} layout={AuthLayout} exact path="/about-us/images" />
                 <SuperAdminRoute component={UploadImage} layout={AuthLayout} exact path="/about-us/upload-image" />
                 <SuperAdminRoute component={SubmittedWorksheets} layout={AuthLayout} exact path="/submitted-worksheets" />
                 <SuperAdminRoute component={Reports} layout={AuthLayout} exact path="/reports" />
                 <SuperAdminRoute component={ReportDashboard} layout={AuthLayout} exact path="/reports/report-dashboard" />
                 <SuperAdminRoute component={Coupon} layout={AuthLayout} exact path="/coupon" />
                 <SuperAdminRoute component={AddCoupon} layout={AuthLayout} exact path="/coupon/add-coupon" />
                 <SuperAdminRoute component={EditCoupon} layout={AuthLayout} exact path="/coupon/edit-coupon" />
               
                {/* <Redirect to="not-found" /> */}
            </Switch>
            </Suspense>
        </Fragment>

    )
}


export default Routes