import moment from 'moment'
import {Badge} from 'rsuite'
import {MdExpandMore,MdExpandLess} from 'react-icons/all'
import {theme} from "../constants"
import {Button} from 'rsuite'
import {history} from "../helpers"


export const COL=[
    {
        Header:'Module',
        Footer:'Module',
        accessor:'worksheet_name',
        Filter:'',
    },
    {
        Header:'Boards',
        Footer:'Boards',
        accessor:'boards',
        Filter:'',
        Cell:({value,row})=>(
            <div className="custom-badge" {...row.getToggleRowExpandedProps()}  >
               <p>{value[0]}</p>
               {value.length>1&&<Badge content={`+ ${value.length-1} more`} style={{backgroundColor:theme.THEME_SEONDARY_COLOR}} /> }
            </div>
        )
    },
    {
        Header:'Subjects',
        Footer:'Subjects',
        accessor:'subjects',
        Filter:'',
        Cell:({value,row})=>(
            <div className="custom-badge" {...row.getToggleRowExpandedProps()}  >
               <p>{value[0]}</p>
               {value.length>1&&<Badge content={`+ ${value.length-1} more`} style={{backgroundColor:theme.THEME_SEONDARY_COLOR}} /> }
            </div>
        )
    },
    {
        Header:'Grades',
        Footer:'Grades',
        accessor:'grades',
        Filter:'',
        Cell:({value,row})=>(
            <div className="custom-badge" {...row.getToggleRowExpandedProps()}  >
               <p>{value[0]}</p>
               {value.length>1&&<Badge content={`+ ${value.length-1} more`} style={{backgroundColor:theme.THEME_SEONDARY_COLOR}} /> }
            </div>
        )
    },
    {
        Header:'Date Submitted',
        Footer:'Date Submitted',
        accessor:'submitted_date',
        Filter:'',
        Cell:({value})=>(
            <p>{value?moment(value).format('ll'):'--'}</p>
        )
    }, 
    {
        Header:'Open',
        Footer:'Open',
        accessor:'open',
        Filter:'',
        Cell:({value,row})=>{
            console.log("Submitted Row: ",row)
            return(
                <Button appearance="primary" size="xs" onClick={()=>history.push({pathname:'/load-worksheet',state:{pdf:row.original.evaluated_pdf,data:row.original,noNav:true}})} >Open</Button>
            )
            }
    }, 
    {
        Header:()=>null,
        id:'expander',
        Cell:({row})=>(
            <span {...row.getToggleRowExpandedProps()} style={{display:"flex",justifyContent:"center",cursor:"pointer"}} >
               {row.isExpanded?<MdExpandLess/>:<MdExpandMore/>}
            </span>
        )
    },
   ]