/** @format */

import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import AuthReducer from "./auth.reducer"
import MasterReducer from "./master.reducer"
import TeachersReducer from "./teachers.reducer"
import { reducer as formReducer } from "redux-form";

const persistConfig = {
  key: "root",
  storage, 
  whitelist: ['AuthReducer','TeachersReducer'],
  blacklist: []
};


const TeachersConfig={
  key:'TeachersReducer',
  storage,
  whitelist:['worksheets']
}


const Reducers = combineReducers({
  AuthReducer,  
  MasterReducer,
  TeachersReducer:persistReducer(TeachersConfig,TeachersReducer),
  form: formReducer,
});



export default persistReducer(persistConfig, Reducers);
