//@author:Himanshu Barmola
//Project: OpenDoor Sheets Admin Panel


// Customizing the Input field for Redux-Form

import React from "react"
import { SelectPicker,Icon } from "rsuite"
export const renderInput = (props) => {
    return (
        <div className="input-field">
            <SelectPicker
                {...props.input}
                data={props.options}
                placeholder={props.placeholder}
                value={props.input.value}
                onClean={props.onClean}
                name={props.input.name}
                disabled={props.disable}
                labelKey={props.labelKey}
                valueKey={props.valueKey}
                defaultValue={props.value}
                cleanable={props.cleanable}
                searchable={props.searchable}
                placement="bottomStart"
                preventOverflow
                block="true"
                size="lg"
                className={props.meta.touched && (props.meta.error|| props.meta.submitError) != undefined ? "error-field p-0" : undefined}
                renderMenu={menu=>{
                    if(props.options.length===0){
                        return(
                            <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                            <Icon icon="spinner" spin /> Fetching..
                          </p>
                        )
                       
                    }
                    return menu;
                }}
            />
            {props.meta.touched&&(props.meta.error|| props.meta.submitError)&&<p className="error-msg" >{(props.meta.error|| props.meta.submitError)}</p>}
        </div>
    )
}



export default renderInput