export const photos={
    logo:require('../assets/images/od-logo.png').default,
    logoFlat:require('../assets/images/logo-flat.png').default,
    avatar:require('../assets/images/user.png').default,
    error:require('../assets/images/triangle.svg').default,
    companianBanner:require('../assets/images/companian-banner.png').default,
    graduation:require('../assets/images/graduation.svg').default,
    imageIcon:require('../assets/images/image_icon.svg').default,
    401:require('../assets/images/401.png').default,
    teacher:require('../assets/images/teacher.png').default,
    assignModules:require('../assets/images/assign-modules.png').default,
    updatePassword:require('../assets/images/updatePassword.png').default,
    createAdmin:require('../assets/images/createAdmin.png').default,
    quote:require('../assets/images/quote.png').default,
    uploadButton:require('../assets/images/uploadButton2.png').default,
    notification:require('../assets/images/notification.png').default,
    draft:require('../assets/images/draft.png').default,
    coupon:require('../assets/images/coupon.png').default,
    message:require('../assets/images/message.png').default,
}





export default {
    photos
}