import React,{useState,useEffect,Fragment} from 'react'
import PropTypes from 'prop-types'
import {Table} from "../../container";
import {Dialog} from '../../components'
import {COL} from "../../columns/SubscribedModulesList"
import {AssignSubscriptionForm} from "../"
import history from "../../helpers/history"
import * as API from "../../api/API"
import {useQuery,useMutation,QueryClient} from 'react-query'
import {apiRequest,getErrors} from "../../helpers"
import {connect} from 'react-redux'
import * as action from '../../redux/actions/masters.action'
import {Alert} from 'rsuite'


function _SubscribedModules({data,isOpen,setOpen,userSelectedRow,setUserSelectedRow,setSelectedModule}) {
    const [error,setError]=React.useState(null)
    const queryClient= new QueryClient()
    const {data:moduleData,isLoading}=useQuery(['fetchSubscribedModules',data.id],()=>apiRequest('GET',`${API.STUDENT_MANAGEMENT}${data.id}/`,null,null),{
           refetchOnReconnect:false,
           refetchOnWindowFocus:false,
    })

    const {mutateAsync:assignSubscription,isLoading:isAssigningSubscription}=useMutation((data)=>apiRequest('POST',API.ASSIGN_SUBSCRIPTION,data,null))

    const handleFormSubmitButton=(e)=>{
        document
       .getElementById('assignSubscriptionForm')
       .dispatchEvent(new Event('submit',{cancelable:true,bubbles:true}))
   }


    function handleAssignSubscription(values){
        let postData={
            ...values,
            student_id:data.id
        }
        assignSubscription(postData)
        .then((res)=>{
            setOpen(false)
            Alert.success('Module Assigned Succesfully !',3000)
            queryClient.fetchQuery(['fetchSubscribedModules'],()=>apiRequest('GET',`${API.STUDENT_MANAGEMENT}${data.id}/`,null,null))
        })
        .catch((err)=>{
           setError(getErrors(err,'msg'))
        })
    }

  

    return (
        <div>
            <Table
            isPaginated={false}
            columns={COL}
            columnOrder={['selection','module','no_of_worksheets','no_of_submissions','no_of_evaluations','subscription_date']}
            explicitData={moduleData?.msg?.subscribed_modules}
            loading={isLoading}
            hideActionButton
            canRowSelect
            userSelectedRows={(item)=>setUserSelectedRow(item)}
            />
             <Dialog
            isOpen={isOpen}
            toggle={()=>setOpen(false)}
            title="Assign Subscription"
            primaryButtonAppearance="primary"
            secondaryButtonFunc={()=>setOpen(false)}
            loading={isAssigningSubscription}
            secondaryButtonName="Cancel"
            primaryButtonName="Assign Subscription"
            errorMsg={error}
            primaryButtonFunc={handleFormSubmitButton}
            bodyContent={
                <AssignSubscriptionForm
                   handleFormSubmit={handleAssignSubscription} 
                   setSelectedModule={setSelectedModule}
                />
            }
            />
        </div>
    )
}




_SubscribedModules.propTypes={
    data:PropTypes.object
}


const mapStateToProps=state=>{
    return{
        BOARDS:state.MasterReducer.boards,
        GRADES:state.MasterReducer.grades,
        SUBJECTS:state.MasterReducer.subjects,
        MODULES:state.MasterReducer.modules,
    }
}

const mapDispatchToProps=dispatch=>{
    return{
        _getBoardList:()=>dispatch(action.getBoardList()),
        _getGradeList:()=>dispatch(action.getGradeList()),
        _getSubjectList:()=>dispatch(action.getSubjectList()),
        _getModuleList:()=>dispatch(action.getModuleList()),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(_SubscribedModules)

