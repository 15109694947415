import React from 'react'
import PropTypes from 'prop-types'
import {Table} from "..";
import {COL} from "../../columns/SubmissionReportList"


function _SubmissionReport({data,loading}) {


    return (
        <div>
            <Table
            isPaginated={false}
            columns={COL}
            columnOrder={['student_name','board','grade','submitted_date','teacher_name','submitted_pdf']}
            explicitData={data?.submission}
            hideActionButton
            loading={loading}
            />
        </div>
    )
}




_SubmissionReport.propTypes={
    data:PropTypes.object
}



export default _SubmissionReport

