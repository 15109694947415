import React,{useState,useEffect,useCallback,Fragment} from 'react'
import PropTypes from 'prop-types'
import {Table} from "../../container";
import {COL} from "../../columns/StudentSubmittedWorksheetsList"
import history from "../../helpers/history"
import * as API from "../../api/API"
import {useQuery} from 'react-query'
import {apiRequest} from "../../helpers"
import {Badge} from 'rsuite'
import {updateTeachersWorksheet} from "../../redux/creators/teachers.creator"
import {connect} from 'react-redux'

function _SubmittedWorksheets({data,updateTeachersWorksheet}) {


    const {data:moduleData,isLoading}=useQuery(['fetchSubmittedWorksheets'],()=>apiRequest('GET',`${API.STUDENT_MANAGEMENT}${data.id}/`,null,null),{
           refetchOnReconnect:false,
           refetchOnWindowFocus:false,
           onSuccess:(res)=>{
               let tempData=[];
                if(res.msg.submitted_worksheets.length>0){
                    for(let key in res.msg.submitted_worksheets){
                        let data={
                           ...res.msg.submitted_worksheets[key],
                           isEvaluated:false
                        }
                        tempData.push(data)
                    }
                    updateTeachersWorksheet(tempData)   
                }
           }
    })


    const showExpandedRow=useCallback((value)=>{
        console.log("Expanded Value: ",value)
        return(
            <Fragment>
            {/* <div className="permission-subRow" > */}
               <td className="permission-subRow__box" >
                 <h4 className={`animate__animated animate__fadeInUp animate__faster`} >Boards</h4>
                <ul>
                    {value.boards.map((item,idx)=>{
                        return(
                            <li className={`animate__animated animate__fadeInUp animate__delay-${idx*800}ms`}  key={idx}><Badge content={item} style={{backgroundColor:"#e9ecef",color:"#8898aa"}} /> </li>
                        )
                    })}
                </ul>
               </td>
               <td className="permission-subRow__box" >
                 <h4 className={`animate__animated animate__fadeInUp animate__faster`} >Grades</h4>
                <ul>
                    {value.grades.map((item,idx)=>{
                        return(
                            <li className={`animate__animated animate__fadeInUp animate__delay-${idx*800}ms`} key={idx}><Badge content={item} style={{backgroundColor:"#e9ecef",color:"#8898aa"}} /> </li>
                        )
                    })}
                </ul>
               </td>
               <td className="permission-subRow__box" >
                 <h4 className={`animate__animated animate__fadeInUp animate__faster`} >Subjects</h4>
                <ul>
                    {value.subjects.map((item,idx)=>{
                        return(
                            <li className={`animate__animated animate__fadeInUp animate__delay-${idx*800}ms`} key={idx}><Badge content={item} style={{backgroundColor:"#e9ecef",color:"#8898aa"}} /> </li>
                        )
                    })}
                </ul>
               </td>
            {/* </div> */}
            </Fragment>
        )
    },[])


    return (
        <div>
            <Table
            columns={COL}
            columnOrder={['worksheet_name','boards','subjects','grades','submitted_date','open']}
            explicitData={moduleData?.msg?.submitted_worksheets}
            hideActionButton
            renderRowSubComponent={showExpandedRow}
            />
        </div>
    )
}




_SubmittedWorksheets.propTypes={
    data:PropTypes.object
}



const mapDispatchToProps=dispatch=>{
    return{
        updateTeachersWorksheet:(data)=>dispatch(updateTeachersWorksheet(data))
    }
}

export default connect(null,mapDispatchToProps)(_SubmittedWorksheets)