import axios from "axios";
import { logoutUser} from "./redux/actions/auth.action";
import { toast } from "react-toastify";
import setAuthToken from './helpers/setAuthToken'
import {setCurrentUser} from './redux/creators/auth.creator'
import jwt_decode from 'jwt-decode'
import { history } from "./helpers";
import * as API from "./api/API"


export default{ 
  setupInterceptors: (store) => {
    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        //catches if the session ended!
        if (error.response.status == 401) {
          if (!(window.location.pathname == "/login")) {
            console.log("Authentication Error")
            toast.error("Session Timed Out!", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose:5000,

            });
            store.dispatch(logoutUser());
            setTimeout(() => {
             history.push('/login')
            }, 2000);
          } else {
            console.log("User not 401")
            store.dispatch(logoutUser());
          }
        } else if (error.response.status >= 500) {
          // toast.error("Server error", {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        }
        return Promise.reject(error);
      }
    );
    axios.defaults.baseURL=API.BASE_URL;
  },}


export const checkUserAuthentication=(store)=>{
        const authToken=JSON.parse(localStorage.getItem('localToken'))
        console.log("Auth Tokennnnnn: ",authToken.token.access)
        if(authToken.token.access){
          setAuthToken(authToken.token.access)
            const decoded = jwt_decode(authToken.token.access)
            store.dispatch(setCurrentUser(decoded))
            const currentTime = Date.now() / 1000;
            if (decoded.exp < currentTime) {
              toast.error("Session Timed Out!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose:5000,
  
              });
              console.log("User Token Expired")
                // Logout user
                store.dispatch(logoutUser());
                // Clear current Profile
                window.location.href = "/login";
            }
        }
  }



