import React, { Fragment, useEffect, useState } from 'react'
import Sidebars from "react-sidebar"
import PropTypes from 'prop-types';


const mql = window.matchMedia(`(min-width: 800px)`);

function Sidebar({ sidebarOpen, setSidebar, children, menuItem,overlayId }) {
    const [sidebarDocket, setSidebarDocked] = useState(mql.matches)


    useEffect(() => {
        mql.addListener(mediaQueryChanged)

        return () => {
            mql.removeListener(mediaQueryChanged)
        }
    }, [])

    const mediaQueryChanged = () => {
        setSidebarDocked(mql.matches)
        setSidebar(false)
    }
    return (
        <Fragment>
            <Sidebars
                sidebarId="sidebar-id"
                contentId="content-id"
                sidebar={menuItem}
                open={sidebarOpen}
                docked={sidebarDocket}
                onSetOpen={() => setSidebar(true)}
                sidebarClassName="sidebar-main"
                shadow={false}
                overlayId={overlayId}
                contentClassName="sidebar-content"
            >
                <div>
                    {children}
                </div>
            </Sidebars>
        </Fragment>
    )
}

Sidebar.propTypes = {
    setSidebar: PropTypes.func.isRequired,
    sidebarOpen: PropTypes.bool.isRequired,
    menuItem: PropTypes.any,
    overlayId:PropTypes.string
};


export default Sidebar
