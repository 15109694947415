import React,{useState,useEffect} from 'react';
import {
    Nav,
    NavItem,
    Navbar,
    NavbarBrand,
    Collapse,
    DropdownItem,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu
} from 'reactstrap';
import {photos} from "../../constants/images"
import {HiOutlineMenuAlt3} from "react-icons/all"
import {connect} from "react-redux"
import {logoutUser} from "../../redux/actions/auth.action"
import {Dialog} from "../../components"
import {Avatar,Badge,Dropdown} from "rsuite"
import theme from "../../constants/theme"
import {AiOutlineUserAdd,AiOutlinePoweroff,CgPassword} from "react-icons/all"
import {history} from '../../helpers'
/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
const localToken=JSON.parse(localStorage.getItem('localToken'))||false

const Header = ({dispatch,USER}) => {
    const [showCaution,setCaution]=useState(false)
    const [options,setOptions]=useState([
        {
            label:'Logout',
            ICON:AiOutlinePoweroff,
            func:()=>{
                setCaution(true)
            }
        }
    ])

    const teacherMenu=[
      
        {
            label:'Update Password',
            ICON:CgPassword,
            func:()=>{
                history.push('/update-password')
            }
        },
        {
            label:'Logout',
            ICON:AiOutlinePoweroff,
            func:()=>{
                setCaution(true)
            }
        },
    ]

    const superAdminMenu=[
       
        {
            label:'Add user',
            ICON:AiOutlineUserAdd,
            func:()=>{
                history.push('/create-admin')
            }
    },
    {
        label:'Logout',
        ICON:AiOutlinePoweroff,
        func:()=>{
            setCaution(true)
        }
    },
    ]


    /*--------------------------------------------------------------------------------*/
    /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
    /*--------------------------------------------------------------------------------*/
    const showMobilemenu = () => {
        // document.getElementById('main-wrapper').classList.toggle('show-sidebar');
    }

    const triggerMobileMenu = () => {
        const offcanvasMobileMenu = document.querySelector(
          ".sidebar-main"
        );
        offcanvasMobileMenu.classList.toggle("active");
      };


    useEffect(()=>{
        if(USER&&USER.USER_TYPE==="Teacher"){
        
            setOptions(teacherMenu)
        }
        else if(USER&& USER.USER_TYPE==="SuperAdmin"){
            setOptions(superAdminMenu)
        }
        else if(USER&& USER.USER_TYPE==="Admin"){
            setOptions(teacherMenu)
        }
    },[])

    return (
        <header className="topbar navbarbg" data-navbarbg="skin1" id="topbar" >
            <Navbar className="top-navbar" dark expand="md">
                <div className="navbar-header" id="logobg" data-logobg="skin6">
                    {/*--------------------------------------------------------------------------------*/}
                    {/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
                    {/*--------------------------------------------------------------------------------*/}
                    <NavbarBrand href="/">
                        <b className="logo-icon">
                            {/* <img src={photos.logo} alt="homepage" className="dark-logo" /> */}
                            <img
                                src={photos.logo}
                                alt="homepage"
                                className="light-logo"
                            />
                            <h3>OpenDoor Sheets</h3>
                            <span className="user_type" >
                                <Badge content={
                            USER?.USER_TYPE=="SuperAdmin"?"Super Admin":
                            USER?.USER_TYPE=="Admin"?"Admin":"Teacher"
                        } style={{backgroundColor:USER?.USER_TYPE=="SuperAdmin"?theme.SUCCESS_COLOR:theme.THEME_PRIMARY_COLOR}} />
                        </span>
                        </b>
                        {/* <span className="logo-text">
                            <img src={photos.logo} alt="homepage" className="dark-logo" />
                            <img
                                src={photos.logo}
                                className="light-logo"
                                alt="homepage"
                            />
                        </span> */}
                    </NavbarBrand>
                    {/*--------------------------------------------------------------------------------*/}
                    {/* Mobile View Toggler  [visible only after 768px screen]                         */}
                    {/*--------------------------------------------------------------------------------*/}
                    <button className="hem-menu  nav-toggler d-block d-md-none" onClick={() => triggerMobileMenu()}>
                        <HiOutlineMenuAlt3 size={28}  />
                    </button>
                </div>
                <Collapse className="navbarbg" navbar data-navbarbg="skin1" >
                    <Nav className="ml-auto float-right" navbar>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Start Profile Dropdown                                                         */}
                        {/*--------------------------------------------------------------------------------*/}
                        <Dropdown placement="leftStart"
                        renderTitle={()=>{
                            return <Avatar circle style={{backgroundColor:theme.THEME_SEONDARY_COLOR}} >
                                {USER?.USER_TYPE=="SuperAdmin"?"SA":
                                USER?.USER_TYPE=="Teacher"?"T":
                                "A"}</Avatar>
                        }}
                        >
                            {options.map((item,idx)=>{
                                    const {label,ICON,func}=item
                                    return(
                                    <Dropdown.Item onClick={func} key={idx} >
                                    <ICON size={24} />{' '} {label}
                                 </Dropdown.Item>
                                    )
                                })}
                        </Dropdown>
                        {/* <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret className="pro-pic">
                                <Avatar circle style={{backgroundColor:theme.THEME_SEONDARY_COLOR}} >{localToken.user_type=="SuperAdmin"?"SA":"A"}</Avatar>
                            </DropdownToggle>
                            <DropdownMenu right className="user-dd">
                                {options.map((item,idx)=>{
                                    const {label,ICON,func}=item
                                    return(
                                    <DropdownItem onClick={func} key={idx} >
                                    <ICON size={24} />{' '} {label}
                                 </DropdownItem>
                                    )
                                })}
                            </DropdownMenu>
                        </UncontrolledDropdown> */}
                        {/*--------------------------------------------------------------------------------*/}
                        {/* End Profile Dropdown                                                           */}
                        {/*--------------------------------------------------------------------------------*/}
                    </Nav>
                </Collapse>
            </Navbar>
            {showCaution&&
            <Dialog
            isOpen={showCaution}
            toggle={()=>setCaution(false)}
            bodyContent={<p>Are you sure you want to Logout?</p>}
            primaryButtonName="Yes, Logout"
            primaryButtonFunc={()=>dispatch(logoutUser())}
            title="Logout"
            secondaryButtonName="No"
            secondaryButtonFunc={()=>setCaution(false)}
            />}
        </header>
    );
}

const mapStateToProps=state=>{
    return{
        USER:state.AuthReducer.auth
    }
}


export default connect(mapStateToProps,null)(Header);
