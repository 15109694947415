import React from 'react'
import PropTypes from 'prop-types'
import { Animation } from "../"
import success from "../../data/success.json"
import {photos} from "../../constants/images"
import {GiGraduateCap,GiBlackBook,VscBook,AiOutlineSafetyCertificate} from "react-icons/all"
import data from "../../data/label.json"
export default function Success({data,grade,subject}) {

    const renderDetail=(label,item,Icon)=>{
      return(
        <div className="success__detail__element text">
        <label>{label}</label>
        <div className="success__detail__element__info">
        <Icon size={24} />
        <h4>{item}</h4>
        </div>
        </div>
      )
    }

    const renderGroup=(label,item,icon)=>{
        return(
            <div className="success__detail__element text">
                
            </div>
        )
    }


    return (
        <div className="row">
            <div className="col-md-6 col-lg-6 col-md-12" >
            <div className="success">
            <div className="success__broadcast">
                <div>
                    <Animation json={success} width={70} height={70} />
                </div>
                <div>
                    <h1>Success !</h1>
                    <p>Board has been created successfully. Board detail is mentioned below</p>
                </div>
            </div>
            <div className="success__detail" >
                <div className="row" >
                    <div className="col-md-6 col-lg-6 col-md-12">
                        <div className="success__detail__element text">
                            <label>Board Name</label>
                            <div className="success__detail__element__info">
                            <GiGraduateCap size={24} />
                            <h4>CBSE</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-md-12">
                    <div className="success__detail__element image">
                        <AiOutlineSafetyCertificate size={50} />
                            {/* <img src={photos.avatar} /> */}
                    </div>
                    </div>
                </div>
                <div className="success__detail__data-grid">
                    {grade&&renderDetail('Grade',grade,GiBlackBook)}
                    {subject&&renderDetail('Subject',subject,VscBook)}
                </div>
            </div>
        </div>
            </div>
            <div className="col-md-6 col-lg-6 col-md-12" ></div>
        </div>
   
    )
}
