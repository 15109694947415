import * as actionTypes from "../actionTypes"


export const updateUserDetail=(data)=>({
    type:actionTypes.UPDATE_NEW_USER,
    payload:{data}
})

export const setCurrentUser=(data)=>({
    type:actionTypes.SET_CURRENT_USER,
    payload:{data}
})



export const logoutUser=()=>({
    type:actionTypes.LOGOUT_USER
})

