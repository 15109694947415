import React from 'react'
import PropTypes from 'prop-types'
import {useQuery} from 'react-query'
import {Table} from "..";
import * as API from "../../api/API"
import {apiRequest} from "../../helpers"
import Chart from 'react-apexcharts'
import {theme} from '../../constants'
import {MdExpandMore,MdExpandLess} from 'react-icons/all'
import {photos} from '../../constants/images'

function _Graph({worksheetId,loading}) {
    const [graphData,setGraphData]=React.useState(null)
    const [error,setError]=React.useState(null)
    const {data,isLoading}=useQuery(['fetchGraph'],()=>apiRequest('GET',API.GRAPH,null,{worksheet_id:worksheetId}),{
        refetchIntervalInBackground:false,
        onSuccess:(xdata)=>{
            let worksheet = xdata.msg.worksheet;
            let section = xdata.msg.section;
            let question = xdata.msg.question;
            let xAxisData= Object.values(worksheet.graph_data);
            // let xAxisData= [0,5,3,23,9,0,17,13,35,28];
            let xAxisLabel = ['0-10','10-20','20-30','30-40','40-50','50-60','60-70','70-80','80-90','90-100'];
            console.log("Graph Label: ",xAxisLabel);
            console.log('Graph Data: ',xAxisData);
            let worksheetGraph={
                options: {
                    chart: {
                      id: 'apexchart-example'
                    },
                    title: {
                        text: 'Worksheet'
                      },
                    xaxis: {
                      categories: xAxisLabel
                    },
                    plotOptions: {
                        bar: {
                          borderRadius: 4,
                        }
                      },
                    fill:{
                        colors:[theme.THEME_PRIMARY_COLOR]
                    }
                  },
                  series: [{
                    name: 'Student',
                    data: xAxisData
                  }],
                  average:worksheet.average,
                  heighestPercentage:worksheet.highest_percentage.toFixed(2)
            }
            let sectionGraph={
                options: {
                    chart: {
                      id: 'apexchart-example'
                    },
                    title: {
                        text: 'Section'
                      },
                    plotOptions: {
                        bar: {
                          borderRadius: 4,
                          horizontal: true,
                        }
                      },
                    xaxis: {
                      categories: Object.keys(section)
                    },
                    fill:{
                        colors:[theme.THEME_PRIMARY_COLOR]
                    }
                  },
                  series: [{
                    name: 'Student',
                    data: Object.values(section)
                  }],
            }
            let questionName=Object.keys(question);
            console.log("Quessss: ",questionName)
            let questionTable=[]
            for(let i in question){
                console.log("Index:",i)
                if(question.hasOwnProperty(i)){
                    let questionObject={
                        name:i,
                        avg_marks:question[i].avg_marks,
                        highest_marks:question[i].highest_marks,
                        subRows:question[i].subparts
                    }
                    questionTable.push(questionObject)
                }
            }    
            console.log("Question Table: ",questionTable)
            setGraphData({worksheet:worksheetGraph,section:sectionGraph,question:questionTable})
        },
        onError:(err)=>{
          console.log('Error Msg: ',err.response.data.msg)
          setError(err?.response?.data?.msg)
        }
    })


    


    const showExpandedRow=React.useCallback((value)=>{
        console.log("Expanded Value: ",value)
        return(
            <React.Fragment>
            {/* <div className="permission-subRow" > */}
            {value.subparts.map((item,idx)=>{
                        return(
                            <tr>
                        <td>{item.name}</td>
                        <td>{item.avg_marks}</td>
                        <td>{item.highest_marks}</td>

                        </tr>

                )
                
            })}
          
            {/* </div> */}
            </React.Fragment>
        )
    },[])

 const COL=[
        {
            Header:'Name',
            Footer:'Name',
            accessor:'name',
            Filter:'',
        },
        {
            Header:'Average',
            Footer:'Average',
            accessor:'avg_marks',
            Filter:'',
        },
        {
            Header:'Highest',
            Footer:'Highest',
            accessor:'highest_marks',
            Filter:'',
        },
       ]

    return (
        <React.Fragment>
        <div className="box" >
            {graphData&&
            <React.Fragment>
            <div className="row" >
            <div className="col-md-8 col-lg-8 col-sm-12">
            <Chart  options={graphData.worksheet.options} series={graphData.worksheet.series} type="bar"  height={320} />
            </div>
            <div className="col-md-4 col-lg-4 col-sm-12">
                <div className="stacked_card">
                    <div className="stacked_card__box" >
                        <h3>{graphData.worksheet.average.toFixed(2)}</h3>
                        <p>Average</p>
                    </div>
                    <div className="stacked_card__box" >
                        <h3>{graphData.worksheet.heighestPercentage}<span>%</span></h3>
                        <p>Heighest Percentage</p>
                    </div>
                </div>
            </div>
            </div>
            <div className="row mt-3" >
            <div className="col-md-8 col-lg-8 col-sm-12">
            <Chart  options={graphData.section.options} series={graphData.section.series} type="bar"  height={320} />
            </div>
            <div className="col-md-4 col-lg-4 col-sm-12">
            
            </div>
            </div>
            </React.Fragment>}
            {error&&
 <div className="msg-box" >
 <img src={photos.error} />
 <p>{error}</p>
 </div>  
            }
        </div>
            {!error  && <Table
              columns={COL}   
              columnOrder={['name','avg_marks','highest_marks','expander']}  
              hideActionButton
              explicitData={graphData?.question}
              isExpandable
              loading={graphData==null ?true:false}
            />}
        </React.Fragment>
    )
}




_Graph.propTypes={
    worksheetId:PropTypes.number,
    loading:PropTypes.bool
}



export default _Graph

