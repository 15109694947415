import * as actionTypes from "../actionTypes"
import {updateObject} from "./utility"

const initialState={
   auth:{
       isAuthenticated:false,
       TOKEN:null,
       USER_TYPE:'',
       USER_PROFILE:null
   }
}



const updateUserDetail=(action,state)=>{
    return updateObject(state,{
       auth:{
           isAuthenticated:true,
           TOKEN:action.payload?.data.token.access || null,
           USER_TYPE:action.payload?.data.user_type || '' ,
           USER_PROFILE:action.payload?.data.USER_PROFILE || null
       }
    })
}

export const setCurrentUser=(action,state)=>{
    return updateObject(state,{
        auth:{
            ...state.auth,
            USER_PROFILE:action.payload.data
        }
    })
}


export const logoutUser=(action,state)=>{
    return updateObject(state,{
        auth:{
            isAuthenticated:false,
            TOKEN:null,
            USER_TYPE:'',
            USER_PROFILE:null
        }
     })
}




const AuthReducer=(state=initialState,action)=>{
    switch (action.type){
        case actionTypes.UPDATE_NEW_USER:return updateUserDetail(action,state);
        case actionTypes.SET_CURRENT_USER:return setCurrentUser(action,state);
        case actionTypes.LOGOUT_USER:return logoutUser(action,state)
    
        default:
            return state
    }
}



export default AuthReducer