

export const COL=[
    {
        Header:'Name',
        Footer:'Name',
        accessor:'teacher_name',
        Filter:'',

    },
    {
        Header:'Email',
        Footer:'Email',
        accessor:'email',
        Filter:'',

    },
    {
        Header:'No. of Assigned Worksheets',
        Footer:'No. of Assigned Worksheets',
        accessor:'no_of_assigned_worksheets',
        Filter:'',
     

    },
    {
        Header:'No. of Evaluated Worksheets',
        Footer:'No. of Evaluated Worksheets',
        accessor:'no_of_evaluated_worksheets',
        Filter:'',

    },

]