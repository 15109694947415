import React, { Fragment, useState } from 'react'
import {Sidebar}from "../../../components"
import MenuItem from "./MenuItem"

export default function _Sidebar({ component }) {
    const [sidebar, setSidebar] = useState(true)
    return (
        <Fragment>
            <Sidebar
                setSidebar={setSidebar}
                sidebarOpen={sidebar}
                shadow={false}
                overlayId="sidebar-overlay"
                menuItem={<MenuItem />}
            >
                {component}

            </Sidebar>
        </Fragment>
    )
}
