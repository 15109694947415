import React from 'react';
import {Form,Field} from 'react-final-form'
import {Input} from "../../components/form"
import PropTypes from "prop-types"
import {required,composeValidators} from "../../components/form/Validations"


function BoardForm(props){
    const {handleFormSubmit,setIsValid,disable,initialValue,error}=props


    return (
        <div className="add-form box">
            <Form 
            onSubmit={handleFormSubmit}
            initialValues={initialValue}
            >
                {({handleSubmit,valid,submitError,submitting,pristine})=>{
                    console.log("Valid: ",valid)
                    setIsValid(submitting || pristine)
                    return(
                    <form id="addBoardForm"   onSubmit={handleSubmit}  >
                    <label>Board Name</label>
                    <Field placeholder="Eg. Central Board of Secondary Education" 
                    disable={disable}
                    validate={composeValidators(required)} name="name" component={Input} />
                    {submitError&&<p className="error-msg">{submitError}</p>}
                </form>
                )}}
            </Form>
        </div>
    );
}





BoardForm.propTypes={
    
    title:PropTypes.string,
    handleFormSubmit:PropTypes.func,
    initialValue:PropTypes.object,
    setIsValid:PropTypes.func,
    error:PropTypes.string
}


export default BoardForm;
