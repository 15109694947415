import React,{useState} from 'react'
import {Navbar,Nav} from 'rsuite'
import PropTypes from "prop-types"
import {connect} from "react-redux"

export default function CompanianNavbar({navbarData,navbarIndex,setNavbarIndex}) {
    const [activeIndex,setActiveIndex]=useState(1)


    function handleSelect(index){
        console.log("Navbar Index:",index)
       setNavbarIndex(index)
    }

    function changePage(item){
        console.log("Item CHange: ",item)
    }

    return (
        <div className="companian-navbar animate__animated animate__fadeIn" >
            <Navbar appearance="subtle" >
              <Navbar.Body>
                  <Nav activeKey={navbarIndex} onSelect={handleSelect} >
                    { navbarData.map((item,index)=>{
                        return(
                            <Nav.Item eventKey={item.id}  onClick={()=>changePage(item)}  >{item.name}</Nav.Item>
                        )
                    })}
                  </Nav>
            </Navbar.Body>  
            </Navbar>
        </div>
    )
}





CompanianNavbar.propTypes={
    navbarData:PropTypes.array.isRequired,
    navbarIndex:PropTypes.number,
    setNavbarIndex:PropTypes.func
}