import Compressor from 'compressorjs';


function compressImage(image){
    return new Promise((resolve,reject)=>{
        new Compressor(image,{
            quality:0.6,
            success:(compressedImage)=>{
                const file = new File([compressedImage], compressedImage?.name, { contentType:'image/jpeg' })
                    resolve(file)
            }
        })
    })
}


export default  compressImage