//Validating functions


export const required = value => (value || typeof value === 'number' || value===false ? undefined : 'This is required field.')

export const imageRequired = value => (value || typeof value === 'number' ? undefined : 'Please upload the image.')
export const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? 'Email Address is not valid.'
        : undefined
const minLength = min => value =>
    value && value.length < min ? `Password Must be ${min} characters or more` : undefined
export const minLength2 = minLength(8)

const minPrice=min=>value=>value&&value==min?'Price must be greater than ₹0.00':undefined
export const minPrice2=minPrice(0)

const minSection=min=>value=>value&&value<min?'Total sections should be greater than 1':undefined
export const totalSection=minSection(2)


export const confirmPasswordVailid = (value, b) => {
    // console.log("Confrm Props:", a, b, c, d)
    if (b.password && b.confirmPassword !== b.password) {
        return "New Password and Confirm Password must be same."
    }
    else if (value && value.length < 8) {
        return "Password Must be 8 characters or more."
    }
    else return undefined
} 
export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)