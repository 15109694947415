import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form'
import { Input, Dropdown } from "../../components/form"
import { GlobalColoumnFilter } from "../../components/tables/GlobalColoumnFilter"
import * as action from '../../redux/actions/masters.action'
import { connect } from 'react-redux'
import { required, composeValidators } from "../../components/form/Validations"
import { SelectPicker } from 'rsuite'
function AssignSubscriptionForm({ handleFormSubmit, initialValue,setSelectedModule,
    _getBoardList, _getGradeList, _getSubjectList, _getModuleList, BOARDS, GRADES, SUBJECTS, MODULES }) {
    const [boardFilter, setBoardFilter] = useState(null)
    const [gradeFilter, setGradeFilter] = useState(null)
    const [subjectFilter, setSubjectFilter] = useState(null)
    const [filteredModules,setFilteredModules]=useState(null)

    useEffect(() => {
        _getBoardList()
        _getGradeList()
        _getSubjectList()
        _getModuleList()
    }, [])

    useEffect(()=>{
        if(MODULES.length>0){
            setFilteredModules(MODULES)
        }
    },[MODULES])


    function getFilteredItem(arr,key,value){
        let data=[];
        for(let i in arr){
            for(let j in arr[i][key]){
                if(arr[i][key][j]==value){
                    data.push(arr[i])
                }  
            }
        }
        return data  
  }

    useEffect(()=>{
        if(boardFilter && !gradeFilter  && !subjectFilter){
            let board=getFilteredItem(MODULES,'boards',boardFilter)
            if(board.length>0){
                setFilteredModules(board)
            }
        }else if(boardFilter && gradeFilter  && !subjectFilter){
            let board=getFilteredItem(MODULES,'boards',boardFilter)
            if(board.length>0){
                let grade=getFilteredItem(MODULES,'grades',gradeFilter)
                if(grade.length>0){
                    setFilteredModules(grade)
                }
            }
        }else if(boardFilter && gradeFilter  && subjectFilter){
            let board=getFilteredItem(MODULES,'boards',boardFilter)
            if(board.length>0){
                let grade=getFilteredItem(MODULES,'grades',gradeFilter)
                if(grade.length>0){
                    let subjects=getFilteredItem(MODULES,'subjects',subjectFilter)
                    if(subjects.length>0){
                        setFilteredModules(subjects)
                    }
                }
            }
        }
        else{
            setFilteredModules(MODULES)
        }


    },[boardFilter,gradeFilter,subjectFilter])

    return (
        <Form
            onSubmit={handleFormSubmit}
            initialValues={initialValue}
        >
            {({ handleSubmit, valid, submitError, submitting, pristine }) => {
                return (
                    <form id="assignSubscriptionForm" onSubmit={handleSubmit} >
                        <div className="input-field" >
                            <label>Board Name</label>
                            <SelectPicker
                                data={BOARDS}
                                valueKey="id"
                                labelKey="name"
                                placeholder="Select Board"
                                onSelect={(id, item) => setBoardFilter(id)}
                                onClean={() => setBoardFilter(null)}
                            />
                        </div>
                        <div className="input-field" >
                            <label>Grade Name</label>
                            <SelectPicker
                                data={GRADES}
                                valueKey="id"
                                labelKey="name"
                                placeholder="Select Grade"
                                onSelect={(id, item) => setGradeFilter(id)}
                                onClean={() => setGradeFilter(null)}
                            />
                        </div>
                        <div className="input-field" >
                            <label>Subject Name</label>
                            <SelectPicker
                                data={SUBJECTS}
                                valueKey="id"
                                labelKey="name"
                                placeholder="Select Subject"
                                onSelect={(id, item) => setSubjectFilter(id)}
                                onClean={() => setSubjectFilter(null)}
                            />
                        </div>
                        <div className="input-field" >
                            <label>Module Name</label>
                            <Field
                                component={Dropdown}
                                options={filteredModules||[]}
                                valueKey="id"
                                labelKey="name"
                                name="module_id"
                                placeholder="Select Module"       
                                onSelect={(id, item) => setSelectedModule(id)}
                                onClean={() => setSelectedModule(null)}
                            />
                        </div>

                        {submitError && <p className="error-msg">{submitError}</p>}
                    </form>
                )
            }}
        </Form>
    )
}






const mapStateToProps = state => {
    return {
        BOARDS: state.MasterReducer.boards,
        GRADES: state.MasterReducer.grades,
        SUBJECTS: state.MasterReducer.subjects,
        MODULES: state.MasterReducer.modules,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        _getBoardList: () => dispatch(action.getBoardList()),
        _getGradeList: () => dispatch(action.getGradeList()),
        _getSubjectList: () => dispatch(action.getSubjectList()),
        _getModuleList: () => dispatch(action.getModuleList()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignSubscriptionForm)
