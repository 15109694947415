import React,{useEffect} from 'react'
import { Form, Field } from 'react-final-form'
import { required, composeValidators } from "../../components/form/Validations"
import {PDFViewer} from '../../components'
import PropTypes from "prop-types"
import { FieldArray } from "react-final-form-arrays"
import arrayMutators from "final-form-arrays"
import {Button} from 'rsuite'
import { Input } from '../../components/form'
import {photos} from '../../constants/images'

export default function CommentsForm({pdf,initialValue,onSubmit,worksheetData,evaluationStatus,onSubmitOverallComments}) {

  const mustBeNumber = value => (isNaN(value) ? 'Must be a number' : undefined)
  const maxMarks = max => value =>
  isNaN(value) || value <= max ? undefined : `Maximum marks is  ${max}`


    function showCommentBox(index,isComment){
        const commentBox=document.querySelector(`#comment-${index}`);
        const commentBtn=document.querySelector(`#commentBtn-${index}`);
        if(commentBox.className === 'input-field hide'){
          commentBox.classList.remove('hide')
          commentBtn.innerHTML=isComment?"HIDE COMMENT":"REMOVE COMMENT"
        }else if(commentBox.className === 'input-field'){
          commentBox.classList.add('hide')
          commentBtn.innerHTML=isComment?"SHOW COMMENT":"ADD COMMENT"
        }
      }

      useEffect(()=>{
        console.log("Section Value:",initialValue)
      },[initialValue])



    return (
        <div>
        {initialValue ?
        <React.Fragment>  
        <Form
        mutators={{ ...arrayMutators }}
        onSubmit={onSubmit}>
        {({ handleSubmit, form: { mutators: { push, pop, remove }, change } }) => {
          return (
            <form id="addMarksForm" onSubmit={handleSubmit}  >
              <div className="row" >
                <div className="col-md-12 col-lg-12 col-sm-12">
                  {(initialValue) ?
                    <FieldArray name="sections" initialValue={initialValue} >
                      {({ fields }) =>
                        fields.map((name, index) => {
                          return (
                            <div className="marks-card" key={index}>
                              <div className="marks-card__labelDiv" >
                              <div className="row" >
                              <div className="col-md-4 col-lg-4  col-sm-4" >
                              <div className="marks-card__labelDiv__box" >
                              <p className="heading" >{`${initialValue[index].section}`}</p>
                              <p className="sub-heading" >{`Section`}</p>
                              </div>
                              </div>

                              <div className="col-md-4 col-lg-4 col-sm-4" >
                              <div className="marks-card__labelDiv__box" >
                              <p className="heading" >{`${initialValue[index].question_no}`}</p>
                              <p className="sub-heading" >{`Question`}</p>
                              </div>
                              </div>

                             {initialValue[index].subpart_name&&<div className="col-md-4 col-lg-4 col-sm-12" >
                              <div className="marks-card__labelDiv__box" >
                              <p className="heading" >{`${initialValue[index].subpart_name}`}</p>
                              <p className="sub-heading" >{`Subpart`}</p>
                              </div>
                              </div> }   
                              </div>
                           
                              </div>
                              <div className="row" >
                              <div className="col-md-6 col-lg-6 col-sm-12" >
                              {worksheetData?.scorable == "Yes"&&<div className="input-field" >
                              <label>Marks</label>
                              <Field
                              name={`${name}.marks`}
                              component={Input}
                              placeholder="Enter Marks"
                              size="sm"
                              validate={evaluationStatus!=='draft'?composeValidators(required, mustBeNumber, maxMarks(initialValue[index]?.marks)):undefined}
                              />
                              </div>}
                              </div>
                              <div className="col-md-6 col-lg-6 col-sm-12 d-flex justify-content-center align-items-center mt-4" >
                              <Button appearance="ghost" id={`commentBtn-${index}`} onClick={()=>showCommentBox(index,initialValue[index]?.description?true:false)}>{initialValue[index]?.description?"Show Comment":"Add Comment"}</Button>
                              </div>
                              </div>
                              
                              <div className={"input-field hide"}  id={`comment-${index}`} >
                              <label>Comments</label>
                              <Field
                              name={`${name}.description`}
                              component={Input}
                              placeholder="Write your comments"
                              size="sm"
                              />
                              </div>
                            </div>
                          )
                        })
                      }
                    </FieldArray>:
                     <div className="msg-box" >
                     <img src={photos.error} />
                     <p>No data found inside  section.</p>
                     </div>       
                    }



                </div>
              </div>
              <hr></hr>
              <div className="input-field" >
                <label>Overall Comment</label>
                <Field name="overall_comment" component={Input} rows={3} componentClass='textarea' placeholder="Write overall comments here" />
              </div>
            </form>
          )
        }}
      </Form>
      </React.Fragment>
      
      :
      <div className="msg-box" >
        <img src={photos.error} />
        <p>No Commments to show.</p>
      </div>
      }
        </div>
    )
}



CommentsForm.propTypes={
    initialValue:PropTypes.any,
    pdf:PropTypes.string,
    evaluatedWorksheet:PropTypes.object,
    reEvaluatedWorksheet:PropTypes.object,
    onSubmit:PropTypes.func.isRequired,
    worksheetData:PropTypes.any
}