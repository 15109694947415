//@author:Himanshu Barmola
//Project: OpenDoor Sheets Admin Panel


// Customizing the Input field for Redux-Form

import React,{useState} from "react"
import ImageUploader from 'react-images-upload'
import {convertImgToBase64URL} from "../../helpers"
import {BiImage} from 'react-icons/all'
import {photos} from "../../constants/images"
import {HelpBlock} from 'rsuite'

export const RenderFileUploader = (props) => {
    const [imageFile,setImageFile]=useState(null)
    function changeFile() {
        var file = props.input?.value[0] || props.input.value
        var reader = new FileReader();
        reader.onload=()=>{
            setImageFile(reader.result)
        }
        reader.readAsDataURL(file)
      }
      if(props.input?.value){
        changeFile(props.input.value)
      }
    return (
        <div className="input-field">
            <div className="row mb-1" >
            <div className="col-md-6 col-lg-6 col-sm-12">
            <ImageUploader
            buttonText={props.placeholder}
            singleImage
            imgExtension={['.jpg','.png','jpeg','svg']}
            label={props.input.value!=='' && props.input.value.length>0?props.input.value[0].name:"No file Selected"}
            name={props.input.name}
            {...props.input}
            className={props.meta.touched && (props.meta.error|| props.meta.submitError) != undefined ? "error-field p-0" : undefined}
            />
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12" >
                <div className="image-viewer" >
                    {imageFile?
                        <img src={imageFile} />:
                        <img src={photos.imageIcon} />

                }
                </div>
            </div>
            </div>
            {props.helpBlock&&<HelpBlock>{props.helpBlock}</HelpBlock>}
            {props.meta.touched&&(props.meta.error|| props.meta.submitError)&&<p className="error-msg" >{(props.meta.error|| props.meta.submitError)}</p>}
        </div>
    )
}



export default RenderFileUploader