import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
// import { store } from "../../redux/store/index"


const TeacherRoute = props => {
    // const state = store.getState()
    // const isAuthenticated = state.AuthReducer.isAuthenticated
    const { layout: Layout, component: Component,auth, ...rest } = props;
    const localToken=JSON.parse(localStorage.getItem('localToken'))||false

    return (
        <Route
            {...rest}
            render={matchProps => (
                localToken&&(localToken.user_type=="Teacher")&&localToken.token.access?
                <Layout>
                    <Component {...matchProps} />
                </Layout> :
               localToken&&localToken.user_type!=="Teacher"&&localToken.token.access? 
               <Redirect to="/401" />:

                <Redirect to="/login" />
            )}
        />
    );
};

TeacherRoute.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string
};

const mapStateToProps =(state)=>{
    return {
        auth:state.AuthReducer.auth
    }
}

export default connect(mapStateToProps,null)(TeacherRoute);