import moment from 'moment'
import {Button,Badge} from 'rsuite'
import {history} from "../helpers"
import {theme} from "../constants";

export const COL=[
    {
        Header:'Name',
        Footer:'Name',
        accessor:'student_name',
        Filter:'',

    },
    {
        Header:'Grade',
        Footer:'Grade',
        accessor:'grade',
        Filter:'',

    },
    {
        Header:'Board',
        Footer:'Board',
        accessor:'board',
        Filter:'',
    },
    {
        Header:'Teacher',
        Footer:'Teacher',
        accessor:'teacher_name',
        Filter:'',
    },
    {
        Header:'Subscription Date',
        Footer:'Subscription Date',
        accessor:'date_of_subscription',
        Filter:'',
        Cell:({value})=>(
            <p>{value?moment(value).format('ll'):'--'}</p>
        )
    },
    {
        Header:'Evaluation Date',
        Footer:'Evaluation Date',
        accessor:'evaluated_date',
        Filter:'',
        Cell:({value})=>(
            <p>{value?moment(value).format('ll'):'--'}</p>
        )
    },
    {
        Header:'Status Shared',
        Footer:'Status Shared',
        accessor:'status_shared',
        Filter:'',
        Cell:({value})=>(
            <Badge content={!value?"Pending":"Shared"} style={{backgroundColor:!value?theme.INACTIVE_COLOR:theme.SUCCESS_COLOR}}  />
        )
    },
    {
        Header:'Status',
        Footer:'Status',
        accessor:'evaluated',
        Filter:'',
        Cell:({value})=>(
            <Badge 
            content={value?"Evaluated":'Submitted'} 
            style={{backgroundColor:!value?theme.INACTIVE_COLOR:theme.SUCCESS_COLOR}}  />
        )
    },

]