import React,{useEffect,useState} from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from "react-redux"

const ProtectedRoute = props => {
    const [authState,setAuthState]=useState(null)
    const localToken=JSON.parse(localStorage.getItem('localToken'))||false
    // const state = store.getState()
    // const isAuthenticated = state.AuthReducer.isAuthenticated
    const { layout: Layout, component: Component,auth, ...rest } = props;
    console.log("Protected Local Token:",JSON.parse(localStorage.getItem('localToken')))
    console.log("Protected Auth:",auth)
 


    return (
        <Route
            {...rest}
            render={matchProps => (
               localToken&&localToken.token.access ?
                    <Layout>
                        <Component {...matchProps} />
                    </Layout> :
                    <Redirect to="/login" />
            )}
        />
    );
};

ProtectedRoute.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string
};

const mapStateToProps=state=>{
    return{
        auth:state.AuthReducer.auth
    }
}

export default connect(mapStateToProps,null)(ProtectedRoute);