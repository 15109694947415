import React from 'react'
import PropTypes from 'prop-types'
import {Table} from "../../container";
import {COL} from "../../columns/TeacherReportList"


function _TeacherReport({data,loading}) {


    return (
        <div>
            <Table
            isPaginated={false}
            columns={COL}
            columnOrder={['id','teacher_name','email','no_of_assigned_worksheets','no_of_evaluated_worksheets']}
            explicitData={data?.teachers}
            hideActionButton
            loading={loading}
            />
        </div>
    )
}




_TeacherReport.propTypes={
    data:PropTypes.object,
    loading:PropTypes.bool
}



export default _TeacherReport

