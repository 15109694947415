import React from 'react';
import {Success} from "../../components"

const SuccessOne = () => {
    return (
        <div className="box" >
            <Success/>
        </div>
    );
}

export default SuccessOne;
