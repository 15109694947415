import * as actionTypes from "../actionTypes"
import * as API from '../../api/API'
import * as trigger from "../creators/masters.creator"
import axios from 'axios'

//getting the list of all Board present in database
export const getBoardList=()=>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        axios.get(API.ALL_BOARD)
        .then((res)=>{
            dispatch(trigger.updateBoardList(res.data))
            resolve(res.data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

//getting the list of all Grade present in databse
export const getGradeList=()=>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        axios.get(API.ALL_GRADE)
        .then((res)=>{
            dispatch(trigger.updateGradeList(res.data))
            resolve(res.data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

//getting the list of all Topics present in databse
export const getTopicList=()=>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        axios.get(API.ALL_TOPIC)
        .then((res)=>{
            dispatch(trigger.updateTopicList(res.data))
            resolve(res.data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}


//getting the list of all Grade present in databse
export const getSubjectList=()=>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        axios.get(API.ALL_SUBJECT)
        .then((res)=>{
            dispatch(trigger.updateSubjectList(res.data))
            resolve(res.data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const getModuleList=()=>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        axios.get(API.ALL_MODULE)
        .then((res)=>{
            dispatch(trigger.updateModuleList(res.data))
            resolve(res.data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}




//getting the list of all students present in database
export const getStudentsList=()=>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        axios.get(API.STUDENTS)
        .then((res)=>{
            dispatch(trigger.updateStudentList(res.data))
            resolve(res.data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}
