import React, { useState, Fragment,useEffect } from 'react';
import { Table as BasicTable } from "../../components/tables"
import PropTypes from "prop-types"
import { Animation, Dialog } from "../../components"
import { useQuery, useQueryClient, useMutation } from 'react-query'
import { apiRequest } from "../../helpers/apiRequest"
import { Dropdown, Icon, Alert } from 'rsuite'
import { HiDotsHorizontal } from "react-icons/all"
import history from "../../helpers/history"
import { getErrors } from '../../helpers';
import * as _ from 'lodash'

const Table = ({
    columns,
    columnOrder,isExpandable,actionModifiedFunc,
    justifyContent, searchPlaceholder,apiAllData,explicitData,isPaginated,canRowSelect,userSelectedRows,loading,
    api, apiKey, actionModified,editLink,notDeletable,fetchedData,fetchingStatus,filterProperty,module_id,renderRowSubComponent,hideActionButton,id,
}) => {

    const queryClient = useQueryClient()
    const [page, setPage] = useState(1)
    const [filter,setFilter]=useState({})
    const [showDelete, setShowDelete] = useState(false)
    const [search,setSearch]=useState('')
    const [selectedRow, setSelectedRow] = useState(null)
    const [singleData,setSingleData]=useState(null)
    const [deleteError,setDeleteError]=useState(null)
    const [searchingData,setSearchingData]=useState(null)

    const {data:filterData}=useQuery([`${apiKey}-all`],()=>apiRequest('GET',apiAllData,null,null))

    const { data, error, isLoading, isFetching, status } = useQuery([apiKey, page,search,JSON.stringify(filter),module_id], () => apiRequest('GET', api, null, { page,search,...filter,...module_id }), {
        keepPreviousData: true,

        onSuccess:(xdata)=>{
            if(fetchedData){
                fetchedData(xdata)
            }
        },
        enabled:id?false:true && !!api && !searchingData
    })

    const {data:dataByID,isFetching:isFetchingByID } = useQuery([`${apiKey}-id`], () => apiRequest('GET', `${api}${id}/`, null,null), {
        keepPreviousData: true,
        onSuccess:(xdata)=>{
            console.log("Fetched Single Data: ",xdata)
            if(fetchedData){
                fetchedData(xdata)
            }
            let tempData=singleData;
            tempData.push(xdata)
            setSingleData(tempData)
            setSearchingData(tempData)
        },
        enabled:!!id && explicitData==null
    })


    if(fetchingStatus){
        fetchingStatus(isFetching)
    }

    useEffect(()=>{
        console.log("Table Filter param: ",filter)
    },[JSON.stringify(filter)])

    const { mutateAsync, isLoading: deleteLoading, reset } = useMutation((id) => apiRequest('DELETE', `${api}${id}/`, null, null), {
        onSuccess: (data) => {
            Alert.success(`Deleted successfuly !`, 2000)
            setSelectedRow(null)
            setShowDelete(false)
            queryClient.fetchQuery([apiKey, page,search,JSON.stringify(filter),module_id], () => apiRequest('GET', api, null, { page,search,...filter,...module_id }))
        },
        onError: (err) => {
            console.log("Err Msg: ",err)
            setDeleteError(getErrors(err,'msg'))
            reset()
        }
    })

    const ActionButton = (original) => {
        return (
            <div className="action-btn" >
                <Dropdown renderTitle={() => <HiDotsHorizontal size={24} />} placement="leftStart" >
                    {editLink&&<Dropdown.Item onClick={() => editBoard(original.data)}> <Icon icon="edit" /> Edit</Dropdown.Item>}
                    {!notDeletable&&<Dropdown.Item onClick={() => parseDeleteDetails(original.data)} > <Icon icon="trash-o" /> Delete</Dropdown.Item>}
                    {actionModified && 
                    actionModified.map((item, idx) => (
                        <Dropdown.Item onClick={() => item.func(original.data)}> {item.icon&&<Icon icon={item.icon} />} {item.label}</Dropdown.Item>
                    ))}
                    {actionModifiedFunc &&
                     actionModifiedFunc(original.data)
                    }

                </Dropdown>
            </div>
        )
    }

    const editBoard = (original) => {
        history.push({ pathname: editLink, state: original })
    }

    const parseDeleteDetails = (props) => {
        console.log("Props: ", props)
        setShowDelete(true)
        setSelectedRow(props)
    }

    useEffect(()=>{
        setSingleData(explicitData || data)
        if(!isPaginated){
            setSearchingData(explicitData || data)
        }
    },[explicitData,data])



    useEffect(()=>{
     if(!isPaginated && singleData){
            let searchFor=search?.toLowerCase() || ''    
            let searchingFrom = searchingData?.length>0?searchingData : [];
            let filteredData=searchingFrom.filter((item)=> Object.values(item).findIndex(val =>val?.toString()?.toLowerCase().includes(searchFor)) > -1)
            if(filteredData.length>0){
                setSingleData(filteredData)
            }else{
                setSingleData(searchingData)
            }
            console.log('Single data: ',singleData)
            console.log('Filtere data: ',filteredData,  searchFor)
            console.log('Searching data: ',searchingData)
     }   
    },[search])



    return (
        <Fragment>
            {(singleData || !loading ) ?
                <BasicTable
                    data={singleData?.results || singleData || []}
                    columns={columns}
                    columnOrder={columnOrder}
                    actionButton={!hideActionButton?ActionButton:undefined}
                    justifyContent={justifyContent ? justifyContent : "flex-end"}
                    searchPlaceholder={searchPlaceholder}
                    next={data?.next}
                    previous={data?.previous}
                    pageCount={data?.count}
                    pageChange={setPage}
                    filterProperty={filterProperty}
                    setSearch={setSearch}
                    setFilterParam={setFilter}
                    filterValue={filter}
                    isPaginated={isPaginated}
                    canRowSelect={canRowSelect}
                    renderRowSubComponent={renderRowSubComponent}
                    userSelectedRows={userSelectedRows}
                    isExpandable={isExpandable}
                    page={page}
                /> :
                <div className="loader" >
                    <Animation
                        height={400}
                        width={400}
                        caption="Hold on a moment.."
                    />
                </div>
            }
            {selectedRow &&
                <Dialog
                    loading={deleteLoading}
                    isOpen={showDelete}
                    toggle={() =>{
                         setShowDelete(false)
                         setDeleteError(null)
                        }}
                    secondaryButtonFunc={() => {
                        setShowDelete(false)
                        setDeleteError(null)
                        reset()
                    }}
                    primaryButtonFunc={() => mutateAsync(selectedRow.id)}
                    primaryButtonName="Delete"
                    title="Delete"
                    errorMsg={deleteError}
                    bodyContent={<p>Are you sure you want to delete <strong>{selectedRow.name}</strong> ?</p>}
                />}
        </Fragment>
    );
}



Table.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    explicitData:PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    columnOrder: PropTypes.array,
    actionButton: PropTypes.func,
    justifyContent: PropTypes.string,
    id:PropTypes.any,
    searchPlaceholder: PropTypes.string,
    api: PropTypes.string.isRequired,
    apiKey: PropTypes.string.isRequired,
    notDeletable:PropTypes.bool,
    editLink:PropTypes.string,
    actionModified:PropTypes.arrayOf(PropTypes.shape({
        func:PropTypes.func,
        icon:PropTypes.any,
        label:PropTypes.string,
        loading:PropTypes.bool
    })),
    fetchedData:PropTypes.func,
    fetchingStatus:PropTypes.func,
    filterProperty:PropTypes.array,
    apiAllData:PropTypes.string,
    filterData:PropTypes.array,
    renderRowSubComponent:PropTypes.func,
    hideActionButton:PropTypes.bool,
    isPaginated:PropTypes.bool,
    canRowSelect:PropTypes.bool,
    userSelectedRows:PropTypes.func,
    isExpandable:PropTypes.bool
}

export default Table;
