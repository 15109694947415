import * as actionTypes from "../actionTypes"
import {updateObject} from "./utility"


const defaultFilterData={
    status:'Submitted',
    boards:[],
    grades:[],
    subjects:[],
    modules:[],
    topics:[]
}

const initialState={
    boards:[],
    grades:[],
    topics:[],
    subjects:[],
    modules:[],
    students:[],
    navbarIndex:1,
    filterData:defaultFilterData,
    xfdf:'none'

}



const updateBoardList=(action,state)=>{
    return updateObject(state,{
       boards:action.payload.data
    })
}

const updateGradeList=(action,state)=>{
    return updateObject(state,{
        grades:action.payload.data
    })
}

const updateTopicList=(action,state)=>{
    return updateObject(state,{
        topics:action.payload.data
    })
}

const updateSubjectList=(action,state)=>{
    return updateObject(state,{
        subjects:action.payload.data
    })
}

const updateModuleList=(action,state)=>{
    return updateObject(state,{
        modules:action.payload.data
    })
}

const updateStudentList=(action,state)=>{
    return updateObject(state,{
        students:action.payload.data
    })
}

const updateNavbarIndex=(action,state)=>{
    return updateObject(state,{
        navbarIndex:action.payload.data
    })
}


const changeFilterData=(action,state)=>{
    return updateObject(state,{
       filterData:action.payload.data
    })
}

const storeXfdf=(action,state)=>{
    return updateObject(state,{
        xfdf:action.payload.data
    })
}


const MasterReducer=(state=initialState,action)=>{
    switch (action.type){
        case actionTypes.UPDATE_BOARD_LIST:return updateBoardList(action,state);
        case actionTypes.UPDATE_GRADE_LIST:return updateGradeList(action,state);
        case actionTypes.UPDATE_TOPIC_LIST:return updateTopicList(action,state);
        case actionTypes.UPDATE_SUBJECT_LIST:return updateSubjectList(action,state);
        case actionTypes.UPDATE_MODULE_LIST:return updateModuleList(action,state);
        case actionTypes.UPDATE_STUDENTS_LIST:return updateStudentList(action,state)
        case actionTypes.SET_NAVBAR_INDEX:return updateNavbarIndex(action,state)
        case actionTypes.CHANGE_FILTER_DATA:return changeFilterData(action,state)
        case actionTypes.STORE_XFDF_STRING:return storeXfdf(action,state)

        default:
            return state
    }
}



export default MasterReducer