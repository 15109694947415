import React from "react"
import {FaChalkboardTeacher
} from "react-icons/all"

export const TeacherMenu= [
    {
        title: "Teacher",
        path: "/teacher-dashboard",
        icon: <FaChalkboardTeacher size={22} />,
        id: "1",
        menu: []
    },

]