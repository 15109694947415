import React from 'react';
import PropTypes from "prop-types"
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {photos} from "../../constants/images"
import {Button} from 'rsuite'
const Dialog = ({isOpen,toggle,primaryButtonName,primaryButtonAppearance,size,scrollable,
    primaryButtonFunc,secondaryButtonName,secondaryButtonFunc,bodyContent,errorMsg,title,loading,successMsg}) => {
    return (
        <div>
        <Modal isOpen={isOpen} modalTransition={{timeout:0}} size={size}  tabIndex="-1" scrollable={scrollable} >
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
            {!successMsg&&bodyContent}
            {errorMsg&&<div className="error-field" >
                <img src={photos.error} />
                <p className="heading" >{errorMsg}</p>
            </div> }
            {successMsg&&<div className="success-msg" >
                
                <p className="heading" >{successMsg}</p>
            </div> }

        </ModalBody>
        {!successMsg?
        <ModalFooter>
          {secondaryButtonFunc&&
          <Button appearance="subtle" onClick={secondaryButtonFunc?secondaryButtonFunc:toggle}>{secondaryButtonName?secondaryButtonName:"Cancel"}</Button>}{' '}
          {primaryButtonFunc&&
          <Button 
          loading={loading}
          appearance="primary" color={!primaryButtonAppearance?"red":primaryButtonAppearance} onClick={primaryButtonFunc?primaryButtonFunc:toggle}>{primaryButtonName?primaryButtonName:'Ok'}</Button>}
        </ModalFooter>:
        <ModalFooter>
            <Button appearance="primary" onClick={secondaryButtonFunc?secondaryButtonFunc:toggle} >Close</Button>
        </ModalFooter>
        
        }
      </Modal>
        </div>
    );
}



Dialog.propTypes={
    isOpen:PropTypes.bool,
    toggle:PropTypes.func,
    primaryButtonName:PropTypes.string,
    primaryButtonFunc:PropTypes.func,
    secondaryButtonName:PropTypes.string,
    secondaryButtonFunc:PropTypes.func,
    primaryButtonAppearance:PropTypes.string,
    title:PropTypes.string,
    bodyContent:PropTypes.element,
    errorMsg:PropTypes.string,
    successMsg:PropTypes.string,
    loading:PropTypes.bool
}

export default Dialog;
