import React from 'react';
import {Form,Field} from 'react-final-form'
import {Input,Radio} from "../../components/form"
import PropTypes from "prop-types"
import {required,composeValidators} from "../../components/form/Validations"


function FaqForm(props){
    const {handleFormSubmit,setIsValid,disable,initialValue,error}=props


    return (
        <div className="add-form box">
            <Form 
            onSubmit={handleFormSubmit}
            initialValues={initialValue}
            >
                {({handleSubmit,valid,submitError,submitting,pristine})=>{
                    console.log("Valid: ",valid)
                    setIsValid(submitting || pristine)
                    return(
                    <form id="AddFaqForm"   onSubmit={handleSubmit}  >
                     <div className="row">
                         <div className="col-md-8 col-lg-8 col-sm-12" >
                             <div className="input-field" >
                             <label>Question</label>
                    <Field placeholder="Enter Question" 
                    disable={disable}
                    validate={required}
                     name="question" component={Input} />
                             </div>
                         </div>
                         <div className="col-md-4 col-lg-4 col-sm-12" >
                         <div className="input-field" >
                          <label>Status</label>
                          <Field
                          name="active"
                          component={Radio}
                          validate={required}
                          radioData={[
                              {label:"Active",value:true},
                              {label:"Inactive",value:false},
                          ]}
                          />  
                        </div>      
                         </div>
                    </div>   
                    <div className="input-field" >
                      <label>Answer</label> 
                      <Field
                        component={Input}
                        componentClass="textarea"
                        name="answer"
                        placeholder="Write the Answer"
                        validate={required}
                        rows={3}
                        />   
                    </div>
           
                    {submitError&&<p className="error-msg">{submitError}</p>}
                </form>
                )}}
            </Form>
        </div>
    );
}





FaqForm.propTypes={
    
    title:PropTypes.string,
    handleFormSubmit:PropTypes.func,
    initialValue:PropTypes.object,
    setIsValid:PropTypes.func,
    error:PropTypes.string
}


export default FaqForm;
