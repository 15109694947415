import React,{useState} from 'react'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
export default function AboutUsForm({editorState,setEditorState}) {


    function onEditorStateChange(contentState){
        setEditorState(contentState)
    }



    return (
        <div className="about-us box-1" >
            <Editor
              editorState={editorState}
              // contentState={editorState}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              onEditorStateChange={onEditorStateChange}
            
            />
            {/* <textarea
          disabled
          value={JSON.stringify(editorState,null,4)}
        /> */}
        </div>
    )
}
