import moment from 'moment'


export const COL=[
    {
        Header:'Module',
        Footer:'Module',
        accessor:'module',
        Filter:'',
    },
    {
        Header:'No of Worksheets',
        Footer:'No of Worksheets',
        accessor:'no_of_worksheets',
        Filter:'',
    },
    {
        Header:'Subscription Date',
        Footer:'Subscription Date',
        accessor:'subscription_date',
        Filter:'',
        Cell:({value})=>(
            <p>{value?moment(value).format('ll'):'--'}</p>
        )
    },
    {
        Header:'No of Submissions',
        Footer:'No of Submissions',
        accessor:'no_of_submissions',
        Filter:'',
    },
    {
        Header:'No of Evaluation',
        Footer:'No of Evaluation',
        accessor:'no_of_evaluations',
        Filter:'',
    },
   ]