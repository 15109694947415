import React from 'react';
import {Form,Field} from 'react-final-form'
import {Input,ImageUploader,ColorPicker} from "../../components/form"
import PropTypes from "prop-types"
import {required,composeValidators,imageRequired} from "../../components/form/Validations"


function SubjectForm(props){
    const {handleFormSubmit,setIsValid,disable,initialValue,error,isReq}=props


    return (
        <div className="add-form box">
            <Form 
            onSubmit={handleFormSubmit}
            initialValues={initialValue}
            >
                {({handleSubmit,valid,submitError,submitting,pristine})=>{
                    console.log("Valid: ",valid)
                    setIsValid(submitting || pristine)
                    return(
                    <form id="addSubjectForm"   onSubmit={handleSubmit}  >
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12" >
                        <div className="input-field" >
                    <label>Name</label>
                    <Field placeholder="Eg. Mathematics" 
                    disable={disable}
                    validate={!isReq?undefined:composeValidators(required)} name="name" component={Input} />
                    </div>
                        </div>
                        {/* <div className="col-md-4 col-lg-4 col-sm-12" >
                            <div className="input-field" >
                               <label>Background Color</label> 
                               <Field
                               name="background_color"
                               component={ColorPicker}
                               validate={required}
                               helpBlock="Right Background Color helps UI to look elegant along with the Image"
                               />
                            </div>
                        </div> */}
                    </div>
                   
                    <div className="input-field" >
                    <label>Image</label>
                      <Field 
                      component={ImageUploader} 
                      placeholder="Choose" 
                      name="image" 
                      validate={!isReq?undefined:imageRequired}
                      /> 
                    </div>
                </form>
                )}}
            </Form>
        </div>
    );
}





SubjectForm.propTypes={
    
    title:PropTypes.string,
    handleFormSubmit:PropTypes.func,
    initialValue:PropTypes.object,
    setIsValid:PropTypes.func,
    error:PropTypes.string,
    isReq:PropTypes.bool
}


export default SubjectForm;
