import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form'
import { Input, MultiSelect,Radio,DatePicker } from "../../components/form"
import PropTypes from "prop-types"
import { required, composeValidators } from "../../components/form/Validations"
import { photos } from "../../constants/images"
import { connect } from "react-redux"
import * as action from "../../redux/actions/masters.action"
import { CheckPicker } from 'rsuite'


function NotificationForm(props) {

    const { handleFormSubmit, setIsValid, disable, initialValue, error, _getBoards, _getGrades, boards, grades, _getStudents, students } = props
    const [boardFilter, setBoardFilter] = useState([])
    const [gradeFilter, setGradeFilter] = useState([])
    const [filteredStudents, setFilteredStudents] = useState(null)

    useEffect(() => {
        _getBoards()
        _getGrades()
        _getStudents()
    }, [])

    useEffect(() => {
        if (boardFilter.length>0 && gradeFilter.length == 0) {
            let data = students.filter((item,index) => boardFilter.includes(item.board))
            console.log('Board Filter: ',data)
            if (data.length > 0) {
                setFilteredStudents(data)
            }

        } else if (boardFilter.length>0 && gradeFilter.length>0) {
            let data = students.filter((item,index) => boardFilter.includes(item.board) && gradeFilter.includes(item.grade))
            console.log('Board and Grade Filter: ',data)
            if (data.length > 0) {
                setFilteredStudents(data)
            }
        } 
        else if (boardFilter.length == 0  && gradeFilter.length>0) {
            let data = students.filter((item,index) =>gradeFilter.includes(item.grade))
            console.log('Grade Filter: ',data)
            if (data.length > 0) {
                setFilteredStudents(data)
            }
        }   
        else {
            setFilteredStudents(students)
        }
    }, [boardFilter, gradeFilter,students])

    return (
        <div className="add-form box">
            <Form
                onSubmit={handleFormSubmit}
                initialValues={initialValue}
            >
                {({ handleSubmit, valid, submitError, submitting,values, pristine }) => {
                    setIsValid(submitting || pristine)
                    return (
                        <form id="sendNotificationForm" onSubmit={handleSubmit}  >
                            <div className="row" >
                                <div className="col-md-7 col-lg-7 col-sm-12">
                                    <div className="row" >
                                        <div className="col-md-6 col-lg-6 col-sm-12">
                                            <div className="input-field" >
                                                <label>Board (Filter)</label>
                                                <CheckPicker
                                                    data={boards || []}
                                                    labelKey="name"
                                                    valueKey="id"
                                                    placeholder="Select Board"
                                                    onSelect={(id, item) => setBoardFilter(id)}
                                                    onClean={() => setBoardFilter([])}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-sm-12">
                                            <div className="input-field" >
                                                <label>Grade (Filter)</label>
                                                <CheckPicker
                                                    data={grades || []}
                                                    labelKey="name"
                                                    valueKey="id"
                                                    placeholder="Select Grade"
                                                    onSelect={(id, item) => setGradeFilter(id)}
                                                    onClean={() => setGradeFilter([])}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" >
                                        <div className="col-md-12 col-lg-12 col-sm-12">
                                            <div className="input-field" >
                                                <label>Student by Name</label>
                                                <Field
                                                    options={filteredStudents || []}
                                                    labelKey="first_name"
                                                    labelKey="first_name"
                                                    valueKey="id"
                                                    placeholder="Select Students"
                                                    component={MultiSelect}
                                                    name="students"
                                                    validate={required}
                                                    selectAll
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6 col-lg-6 col-sm-12"></div> */}
                                    </div>
                                    <div className="input-field" >
                                        <label>Title</label>
                                        <Field

                                            placeholder="Enter Notification Title"
                                            disable={disable}
                                            validate={composeValidators(required)} name="title" component={Input} />
                                    </div>
                                    <div className='input-field' >
                                        <label>Body</label>
                                        <Field
                                            component={Input}
                                            componentClass="textarea"
                                            name="body"
                                            placeholder="Enter Description"
                                            rows={3}
                                            validate={required}
                                        />
                                    </div>
                                    <div className="row" >
                                        <div className="col-md-6 col-lg-6 col-sm-12">          
                                            <div className="input-field" >
                                                    <label></label>
                                                    <Field
                                                        name="status"
                                                        component={Radio}
                                                        defaultValue="send"
                                                        validate={required}
                                                        radioData={[
                                                            { value: "send", label: "Send Now" },
                                                            { value: "schedule", label: "Schedule" },
                                                        ]}
                                                    />
                                               
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-sm-12">
                                        {values.status=="schedule"&&<div className="input-field" >
                                                 <Field
                                                 name="datetime"
                                                 validate={required}
                                                 ranges={[
                                                    {
                                                      label: 'Now',
                                                      value: new Date()
                                                    }
                                                  ]}
                                                  component={DatePicker}
                                                  dateFormat="YYYY-MM-DD HH:mm:ss"
                                                 />           
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 col-lg-5 col-sm-12">
                                    <div className="image" >
                                        <img src={photos.notification} />
                                    </div>
                                </div>
                            </div>

                            {submitError && <p className="error-msg">{submitError}</p>}
                        </form>
                    )
                }}
            </Form>
        </div>
    );
}





NotificationForm.propTypes = {

    title: PropTypes.string,
    handleFormSubmit: PropTypes.func,
    initialValue: PropTypes.object,
    setIsValid: PropTypes.func,
    error: PropTypes.string
}

const mapStateToProps = state => {
    return {
        boards: state.MasterReducer.boards,
        grades: state.MasterReducer.grades,
        students: state.MasterReducer.students
    }
}

const mapDispatchToProps = dispatch => {
    return {
        _getBoards: () => dispatch(action.getBoardList()),
        _getGrades: () => dispatch(action.getGradeList()),
        _getStudents: () => dispatch(action.getStudentsList()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(NotificationForm);


