import React from "react"
import Routes from "./routes/Routes"
import {QueryClient,QueryClientProvider} from "react-query"
import {ReactQueryDevtools} from "react-query/devtools"
import {ToastContainer} from 'react-toastify'
import NETWORK from './network-interceptor'
import {checkUserAuthentication} from "./network-interceptor"
import {store} from './redux/store/index'
import setAuthToken from "./helpers/setAuthToken"
const authToken=JSON.parse(localStorage.getItem('localToken'))

if(authToken){
  setAuthToken(authToken.token.access)
  checkUserAuthentication(store)
}
NETWORK.setupInterceptors(store)

function App() {
  const queryClient = new QueryClient()

  window.onerror=(message,source,linenumber,colNumber,errorObject)=>{
    console.log("Window Error: ",message)
  }
 


  return (
    <div className="App">
      <QueryClientProvider client={queryClient} >
        <ReactQueryDevtools initialIsOpen={false} />
        <ToastContainer />
        <Routes/>
      </QueryClientProvider>
    </div>
  );
}

export default App;
