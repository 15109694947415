import React,{useState} from 'react'
import {useAsyncDebounce} from 'react-table'
import {Input,InputGroup} from 'rsuite'
import {BsSearch} from "react-icons/all"
export default function GlobalFilter({filter,setFilter,placeholder}) {
    const [value,setValue]=useState(filter)

    const onChange = useAsyncDebounce(value=>{
        setFilter(value || undefined)
    },500)

    return (
        <span >
            <InputGroup >
            <InputGroup.Addon>
            <BsSearch size={24} />
            </InputGroup.Addon>
            <Input value={value || ''}  
            onChange={v=>{setValue(v) 
                onChange(v)}}  placeholder={placeholder?placeholder:"Search anything.."} />
            </InputGroup>
         
        </span>
    )
}
