import React from 'react';
import {Breadcrumb as RSBreadcrumb} from "rsuite"
import history from "../../helpers/history"
import PropTypes from 'prop-types';

const Breadcrumb = () => {
    const {pathname}=history.location;
    const pathnames=pathname.split('/').filter(x=>x) 

    function getRand(){
        return new Date().getTime().toString() + Math.floor(Math.random()*1000000);
    }

    return (
        <RSBreadcrumb>
            <RSBreadcrumb.Item onClick={()=>history.push('/')} >Home</RSBreadcrumb.Item>
            {pathnames.map((item,index)=>{
                const routeTo=`/${pathnames.slice(0,index+1).join('/')}`;
                const isLast = index === pathnames.length - 1;
                return(
                    <RSBreadcrumb.Item 
                    key={getRand()}
                    onClick={()=>history.push(routeTo)} 
                    active={isLast} 
                    style={{textTransform:"capitalize"}}>{item}</RSBreadcrumb.Item>
                )
            })}
        </RSBreadcrumb>
    );
}


Breadcrumb.propTypes={

}

export default Breadcrumb;
