//@author:Himanshu Barmola
//Project: OpenDoor Sheets Admin Panel


// Customizing the Input field for Redux-Form

import React from "react"
import { DatePicker,HelpBlock,DateRangePicker } from "rsuite"
export const RenderDatePicker = (props) => {
    const {beforeToday}=DateRangePicker
    return (
        <div className="input-field">
               <DatePicker
        {...props}
        onChange={props.input.onChange}
        value={props.input.value}
        placeholder={props.placeholder}
        name={props.input.name}
        format={props.dateFormat}
        disabled={props.disable}
        placement={props.placement?props.placement:"top"}
        ranges={props.ranges}
        size="lg"
        oneTap
        disabledDate={beforeToday()}
        block="true"
        cleanable={false}
        className={props.meta.touched && (props.meta.error|| props.meta.submitError) != undefined ? `error-field ${props.class}` : props.class}
      />
            {props.helpBlock&&<HelpBlock>{props.helpBlock}</HelpBlock>}
            {props.meta.touched&&(props.meta.error|| props.meta.submitError)&&<p className="error-msg" >{(props.meta.error|| props.meta.submitError)}</p>}
        </div>
    )
}



export default RenderDatePicker