import React from 'react'
import PropTypes from 'prop-types'
import {Table} from "../../container";
import {COL} from "../../columns/StudentReportList"


function _StudentReport({data,loading}) {


    return (
        <div>
            <Table
            isPaginated={false}
            columns={COL}
            columnOrder={['id','student_name','grade','board','teacher_name','date_of_subscription','evaluated_date','status_shared','evaluated']}
            explicitData={data?.students}
            hideActionButton
            loading={loading}
            />
        </div>
    )
}




_StudentReport.propTypes={
    data:PropTypes.object,
    loading:PropTypes.bool
}



export default _StudentReport

