import React,{useEffect} from 'react';
import {photos} from "../../constants/images"
import {Form,Field} from "react-final-form"
import {connect} from "react-redux"
import {InputIcon,PasswordInput} from "../../components/form"
import {HiOutlineMail} from "react-icons/all"
import {Button} from "rsuite"
import {email,required,composeValidators} from "../../components/form/Validations"
import {useMutation} from 'react-query'
import {history,apiRequest,getErrors} from "../../helpers"
import * as API from '../../api/API'
import * as action from '../../redux/creators/auth.creator'
import {FORM_ERROR} from 'final-form'
import jwt_decode from 'jwt-decode'
import setAuthToken from "../../helpers/setAuthToken"

function Login({dispatch}){
    const {isLoading,mutateAsync,data}=useMutation((data)=>apiRequest('POST',API.LOGIN,data,null))

    const handleLogin=(values)=>{
        return mutateAsync(values)
        .then((res)=>{
            const data={
                ...res,
                USER_PROFILE:jwt_decode(res.token.access)
            }
            setAuthToken(res.token.access)
            console.log("Api Resonse: ",res)
            localStorage.setItem('localToken',JSON.stringify(data))
            dispatch(action.updateUserDetail(data))
            if(res.user_type==="Teacher"){
                history.push('/teacher-dashboard')

            }else{
                history.push('/')
            }
        })
        .catch((err)=>{
            return{
                [FORM_ERROR]:getErrors(err,'detail') || getErrors(err,'msg'),
            }
        })
    }




    return (
        <div className="login-container" >
            <div className="login-container__form" >
                <div className="login-container__form__logo" >
                <img src={photos.logo} />
                </div>
                <Form onSubmit={handleLogin} >
                 {({handleSubmit,submitting,pristine,submitError})=>{
                     return(
                    <form onSubmit={handleSubmit} >
                        <div className="input-field" >
                        {/* <label>Email</label> */}
                        <Field  component={InputIcon}  name="email_id" type="email" placeholder="Enter your Email"  icon={HiOutlineMail} validate={composeValidators(required,email)}  />
                        </div>
                        <div className="input-field" >
                        {/* <label>Password</label> */}
                      
                        <Field  component={PasswordInput} name="password" placeholder="Enter your Password"  icon={HiOutlineMail} validate={required}  />
                        </div>
                        {submitError&&
                        <div className="error-field">
                           <p className="heading">{submitError}</p> 
                        </div>}
                        <div className="button-field">
                        <Button appearance="primary" size="lg" type="submit" disabled={submitting || pristine} block loading={isLoading}   >Login</Button>
                        </div>
                        <div className="d-flex flex-row justify-content-end" >
                            <a onClick={()=>history.push('/forgot-password')} >Forgot Password?</a>
                        </div>
                    </form>
                     )
                 }}   
           
            </Form>
            <div className="login-container__form__caption" >
            <p>Login with your credentials in admin panel to view and manage the worksheets.</p>
            </div>
            </div>
        </div>
    );
}

const mapStateToProps=state=>{
    return {

    }
}

export default connect(mapStateToProps,null)(Login)
