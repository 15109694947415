export const BASE_URL="/api/"
export const OPENDOOR_URL="https://opendoorsheets.qilinlab.com"

export const LOGIN="/accounts/v1/admin/login/"

//Get List of Boards
export const BOARD="/sheets/v1/admin/board/" 
export const GRADE="/sheets/v1/admin/grade/"
export const SUBJECT="/sheets/v1/admin/subject/"
export const TOPIC="/sheets/v1/admin/topic/"

export const ALL_SUBJECT="/sheets/v1/app/subject/"
export const ALL_GRADE="/accounts/v1/app/grade/"
export const ALL_TOPIC="/sheets/v1/app/topic/"
export const ALL_BOARD="/accounts/v1/app/board/"
export const ALL_MODULE="/sheets/v1/app/module/"

export const MODULE="/sheets/v1/admin/module/"
export const WORKSHEET="/sheets/v1/admin/worksheet/"
export const WORKSHEET_REVISION="/sheets/v1/admin/worksheet-revision/"


export const FAQ="/support/v1/admin/faq/"
export const STUDENT_QUERY="/support/v1/admin/student-query/"

export const TEACHERS= "/evaluation/v1/admin/teacher/"
export const CITY="/accounts/v1/admin/city/"
export const COUNTRY="/accounts/v1/admin/country/"
export const PASSWORD_RESET="/password_reset/"
export const CONFIRM_PASSWORD="/password_reset/confirm/"
export const PERMISSIONS="/evaluation/v1/admin/permission/"
export const UPDATE_PASSWORD="/evaluation/v1/admin/update-password/"
export const CREATE_ADMIN="/accounts/v1/admin/register-admin/"
export const EVALUATE_SHEET="/evaluation/v1/admin/evaluate-sheet/"
export const COMMENTS="/evaluation/v1/admin/comments/"

export const SHARE="/evaluation/v1/admin/share-results/"

export const NOTIFICATION="/notification/v1/admin/send-notification/"

export const STUDENTS="/accounts/v1/admin/students/"

export const STUDENT_MANAGEMENT="/evaluation/v1/admin/student-management/"

export const USER_EVALUATION="/evaluation/v1/app/submission/"

export const ASSIGN_SUBSCRIPTION="/subscriptions/v1/admin/assign-subscription/"

export const CANCEL_SUBSCRIPTION="/subscriptions/v1/admin/cancel-subscription/"


export const ABOUT_US="/about/v1/admin/about/"

export const SUBMITTED_WORKSHEETS="/evaluation/v1/admin/submitted-worksheets/"

export const IMAGES="/about/v1/app/upload-media/"

export const REPORT='/evaluation/v1/admin/reporting/'

export const GRAPH='/evaluation/v1/admin/graphs/';


export const RESET_WORKSHEET='/evaluation/v1/admin/reset-worksheets/';


export const COUPON='/subscriptions/v1/admin/coupon/';

