/** @format */

import React from "react";
import { Form, Field } from "react-final-form";
import { Input, DatePicker,Radio } from "../../components/form";
import { Button } from "rsuite";
import PropTypes from "prop-types";
import { required, composeValidators } from "../../components/form/Validations";
import {photos} from "../../constants/images"

const Chance = require("chance").Chance();

function CouponForm(props) {
  const { handleFormSubmit, setIsValid, disable, initialValue, error } = props;
  const [isGenerating,setIsGenerating]=React.useState(false)

  return (
    <div className="add-form box">
      <Form onSubmit={handleFormSubmit} initialValues={initialValue}
      mutators={{
          generateCouponCode:(args,state,utils)=>{
            utils.changeValue(state,'coupon_code',()=>Chance.string({
                length: 10,
                casing: "upper",
                alpha: true,
                numeric: true,
              }))
          },
          
      }}
      
      >
        {({ handleSubmit, valid, submitError, submitting, pristine,form }) => {
          console.log("Valid: ", valid);
          setIsValid(submitting || pristine);
          return (
            <form id="addCouponForm" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-7 col-lg-7 col-sm-12">
                  <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-12">
                      <div className="input-field">
                        <label>Coupon Code</label>
                        <Field
                          name="coupon_code"
                          component={Input}
                          id="couponCode"
                          placeholder="Enter Coupon Code"
                          validate={required}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12 mt-4">
                      <div className="input-field mt-1">
                        <label></label>
                        <Button
                          appearance="primary"
                          size="lg"
                          onClick={()=>{
                            setIsGenerating(true)
                            setTimeout(()=>{
                              form.mutators.generateCouponCode()
                              setIsGenerating(false)
                            },500)
                          }}
                          loading={isGenerating}
                        >
                          Generate Coupon
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-12">
                      <div className="input-field">
                        <label>Value in %</label>
                        <Field
                          name="value_in_percentage"
                          component={Input}
                          placeholder="Enter the value in Percentage"
                          validate={required}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12">
                      <div className="input-field">
                        <label>Maximum Price (₹)</label>
                        <Field
                          name="max_price_in_inr"
                          component={Input}
                          placeholder="Enter the maximum price"
                          icon
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-12">
                      <div className="input-field">
                        <label>Expiry Date</label>
                        <Field
                          name="expiry_date"
                          component={DatePicker}
                          placeholder="Expiry Date"
                          validate={required}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12">
                      {initialValue&&<div className="input-field" >
                          <label>Status</label>  
                           <Field 
                           component={Radio}
                           radioData={[{label:'Active',value:true},{label:'Expired',value:false}]} name="is_active"/> 
                      </div>}
                    </div>
                  </div>
                </div>
                <div className="col-md-5 col-lg-5 col-sm-12">
                <div className="image-2" >
                            <img src={photos.coupon}  />
                            </div>
                </div>
              </div>
              {submitError && <p className="error-msg">{submitError}</p>}
            </form>
          );
        }}
      </Form>
    </div>
  );
}

CouponForm.propTypes = {
  title: PropTypes.string,
  handleFormSubmit: PropTypes.func,
  initialValue: PropTypes.object,
  setIsValid: PropTypes.func,
  error: PropTypes.string,
};

export default CouponForm;
