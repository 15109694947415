async function convertImgToBase64URL(file){
    var Reader = new FileReader();
    Reader.readAsDataURL(file)
   var blobFile = new Promise((resolve,reject)=>{
             Reader.onload=()=>{
        resolve(Reader.result)
      }
   })
    // console.log("Blooob FIle: ",await blobFile)
   return await blobFile
}

export default convertImgToBase64URL